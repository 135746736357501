import React, { useState, useEffect } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';
import formatMoneyNumber, { formatNumber } from '../../../utils/formatNumber.js';

const ParcelasResumenGenero = ({ id_ciclo }) => {
  const [dataGrafica, setDataGrafica] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const URL_TOTALES_GENERO = 'dashboard/avances/productores_majomut_genero';

  useEffect(() => {
    if (id_ciclo > 0) {
      getData();
    }
  }, [id_ciclo]);

  const getData = async () => {
    setBlocking(true);
    let chartData = [];
    const response = await Api.search(URL_TOTALES_GENERO, {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    });
    const data = response.status == 200 && response.data.length > 0 ? response.data : chartData;
    if (data.length > 0) {
      const d = data[0];
      const totalMujer = parseFloat(d.ha_cafe_mujer) + parseFloat(d.ha_cult_mujer);
      const totalHombre = parseFloat(d.ha_cafe_hombre) + parseFloat(d.ha_cult_hombre);
      const totalSA = parseFloat(d.ha_cafe_sin_asignar) + parseFloat(d.ha_cult_sin_asignar);
      const mDataKeyB = totalMujer - parseFloat(d.ha_cafe_mujer);
      const hDataKeyB = totalHombre - parseFloat(d.ha_cafe_hombre);
      const saDataKeyB = totalSA - parseFloat(d.ha_cafe_sin_asignar);
      const haTotal =
        totalSA > totalHombre ? (totalSA > totalMujer ? totalSA : totalMujer) : totalHombre > totalMujer ? totalHombre : totalMujer;

      chartData.push({
        name: 'Mujeres',
        dataKeyA: formatNumber(d.ha_cafe_mujer),
        dataKeyB: mDataKeyB > 8 || mDataKeyB == 0 ? formatNumber(mDataKeyB) : 8,
        dataKeyC: d.ha_estimacion_mujer,
        dataKeyAB: totalMujer,
        hasData: d.ha_cafe_mujer == 0 && d.ha_cult_mujer == 0 && d.ha_estimacion_mujer ? false : true,
        fillA: '#E0A98F',
        fillB: '#D87748',
        width: formatNumber((totalMujer * 100) / haTotal),
      });

      chartData.push({
        name: 'Hombres',
        dataKeyA: formatNumber(d.ha_cafe_hombre),
        dataKeyB: hDataKeyB > 8 || hDataKeyB == 0 ? formatNumber(hDataKeyB) : 8,
        dataKeyC: d.ha_estimacion_hombre,
        dataKeyAB: totalHombre,
        hasData: d.ha_cafe_hombre == 0 && d.ha_cult_hombre == 0 && d.ha_estimacion_hombre ? false : true,
        fillA: '#84A96A',
        fillB: '#507736',
        width: formatNumber((totalHombre * 100) / haTotal),
      });

      chartData.push({
        name: 'Sin asignar',
        dataKeyA: formatNumber(d.ha_cafe_sin_asignar),
        dataKeyB: saDataKeyB > 8 || saDataKeyB == 0 ? formatNumber(saDataKeyB) : 8,
        dataKeyC: d.ha_estimacion_sin_asignar,
        dataKeyAB: totalSA,
        hasData: d.ha_cafe_sin_asignar == 0 && d.ha_cult_sin_asignar == 0 && d.ha_estimacion_sin_asignar ? false : true,
        fillA: '#C4C0C2',
        fillB: '#9C969A',
        width: formatNumber((totalSA * 100) / haTotal),
      });
    }
    setDataGrafica(chartData);
    setBlocking(false);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            borderColor: '#cacaca',
            borderWidth: 1,
            borderStyle: 'solid',
            padding: 5,
            minWidth: 200,
          }}>
          <h5 className="mb-1">{payload[0].payload.name}</h5>
          <p className="mb-1">
            Has de café cultivadas: <b>{formatNumber(payload[0].payload.dataKeyA)}</b>
          </p>
          <p className="mb-1">
            Has totales de superficie: <b>{formatNumber(payload[0].payload.dataKeyAB)}</b>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <div className="row card-table">
        <p className="dashboard-titulo-grafica">Cultivo y estimación de cosecha de hombres y mujeres</p>
        <div className="col-md-12">
          {dataGrafica
            .filter(d => d.hasData)
            .map((data, i) => (
              <React.Fragment key={i}>
                <div className="row mt-2">
                  <div className="col-md-8">
                    <p className="dashboard-titulo-grafica text-left mb-0">{data.name}</p>
                    <ResponsiveContainer width={data.width + '%'} height={90}>
                      <BarChart layout="vertical" data={[data]} margin={{ left: -60 }} stackOffset="expand">
                        <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
                        <XAxis hide type="number" />
                        <YAxis type="category" dataKey="name" fontSize="14" stroke="#fff" />
                        <Tooltip shared={true} content={<CustomTooltip />} />
                        {data.dataKeyB > 0 ? (
                          <>
                            <Bar
                              dataKey="dataKeyA"
                              stackId="a"
                              fill={data.fillA}
                              name="ha de café cultivadas"
                              radius={[10, 0, 0, 10]}
                              isAnimationActive={false}
                            />
                            <Bar
                              dataKey="dataKeyB"
                              stackId="a"
                              fill={data.fillB}
                              name="ha totales de superficie"
                              radius={[0, 10, 10, 0]}
                              isAnimationActive={false}
                            />
                          </>
                        ) : (
                          <Bar
                            dataKey="dataKeyA"
                            stackId="a"
                            fill={data.fillA}
                            name="ha de café cultivadas y totales"
                            radius={10}
                            isAnimationActive={false}
                          />
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                    <div className="row">
                      <div className="col-md-6">
                        <i className="dot-chart" style={{ backgroundColor: data.fillA }}></i>
                        <div className="ml-4 text-left">
                          <h4 className="font-lato-normal-400 mb-0">{formatNumber(data.dataKeyA)}</h4>
                          <h6 className="font-lato-normal-400 pt-0">has de café cultivadas</h6>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <i className="dot-chart" style={{ backgroundColor: data.fillB }}></i>
                        <div className="ml-4 text-left">
                          <h4 className="font-lato-normal-400 mb-0">
                            {data.dataKeyAB > 0 ? formatNumber(data.dataKeyAB) : formatNumber(data.dataKeyA)}
                          </h4>
                          <h6 className="font-lato-normal-400 pt-0">has totales de superficie</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="jumbotron pt-4 pb-4 mt-4" style={{ backgroundColor: '#F3EFEC' }}>
                      <h4 className="font-lato-normal-400 text-left">Estimación de producción de:</h4>
                      <div className="position-relative">
                        <i className="dot-chart" style={{ backgroundColor: data.fillB, top: 5 }}></i>
                        <div className="ml-4 text-left">
                          <h4 className="font-lato-normal-400 mb-0">{formatMoneyNumber(data.dataKeyC)} kgs</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </BlockUi>
  );
};

export default ParcelasResumenGenero;
