import React from 'react';

export const Subtitle1 = ({ name }) => {
  return (
    <>
      <p
        style={{
          marginBottom: 0,
          fontSize: '16px',
          marginRight: '5px',
          width: '40%',
        }}>
        Editando información de:
      </p>
      <p
        style={{
          fontWeight: 'bold',
          marginBottom: 0,
          fontSize: '17px',
        }}>
        {name}
      </p>
    </>
  );
};

export default Subtitle1;
