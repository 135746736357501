import React from 'react';

const CardColindante = ({ title, data, vertical = false }) => {
  let extraRows = null;
  if (
    data &&
    (data.propietario ||
      data.herbicidas_nombre ||
      data.fertilizantequim_nombre ||
      data.plaguicida_nombre ||
      data.amortiguamiento)
  ) {
    if (vertical) {
      extraRows = (
        <>
          {data.propietario && (
            <tr>
              <td>
                <span className="font-weight-bold">Propietario/a: </span>
                {data.propietario}
              </td>
            </tr>
          )}
          {data.herbicidas_nombre && (
            <tr>
              <td>
                <span className="font-weight-bold">Herbicidas: </span>
                {data.herbicidas_nombre}
              </td>
            </tr>
          )}
          {data.fertilizantequim_nombre && (
            <tr>
              <td>
                <span className="font-weight-bold">Fertilizantes químicos:</span> {data.fertilizantequim_nombre}
              </td>
            </tr>
          )}
          {data.plaguicida_nombre && (
            <tr>
              <td>
                <span className="font-weight-bold">Plaguicidas: </span>
                {data.plaguicida_nombre}
              </td>
            </tr>
          )}
          {data.amortiguamiento && (
            <tr>
              <td>
                <span className="font-weight-bold">¿Necesita zona de amortiguamiento? </span>
                {data.amortiguamiento}
              </td>
            </tr>
          )}
        </>
      );
    } else {
      extraRows = (
        <tr>
          {data.propietario && (
            <td>
              <span className="font-weight-bold">Propietario/a: </span>
              {data.propietario}
            </td>
          )}
          {data.herbicidas_nombre && (
            <td>
              <span className="font-weight-bold">Herbicidas: </span>
              {data.herbicidas_nombre}
            </td>
          )}
          {data.fertilizantequim_nombre && (
            <td>
              <span className="font-weight-bold">Fertilizantes químicos:</span> {data.fertilizantequim_nombre}
            </td>
          )}
          {data.plaguicida_nombre && (
            <td>
              <span className="font-weight-bold">Plaguicidas: </span>
              {data.plaguicida_nombre}
            </td>
          )}
          {data.amortiguamiento && (
            <td>
              <span className="font-weight-bold">¿Necesita zona de amortiguamiento? </span>
              {data.amortiguamiento}
            </td>
          )}
        </tr>
      );
    }
  }

  return (
    <table className="table table-striped" type="text/css">
      <tbody>
        <tr>
          <td colSpan={vertical ? '1' : '3'}>
            <span className="font-weight-bold">Colindancia {title}:</span> {data?.colindante}
            {data && data.colindante_otro &&
            <>
            <br/>
              <span className="font-weight-bold">Otra colindancia {title}:</span> {data?.colindante_otro}
            </>
}
          </td>
        </tr>
        {extraRows}
      </tbody>
    </table>
  );
};

export default CardColindante;
