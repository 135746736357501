import xlsx from "json-as-xlsx";
import moment from 'moment';

const CalidadListExport = (data) => {
    let xlsData = [
      {
        sheet: 'Calidad',
        columns: [
          {label: 'FOLIO',value: 'folio'},
          {label: 'FECHA',value: 'fecha'},
          {label: 'TIPO',value: 'tipo_analisis'},
          {label: 'NOMBRE',value: 'nombre'},
          {label: 'DETALLE',value: 'detalle'},
          {label: 'REGIÓN',value: 'region'},
          {label: 'ALTURA PROMEDIO',value: 'altura_promedio' },
          {label: 'RENDIMIENTO',value: 'resumen_rendimiento'},
          {label: 'RENDIMIENTO EXPORTABLE',value: 'resumen_rendimiento_exportable'},
          {label: 'PUNTAJE EN TAZA',value: 'resumen_puntaje_taza'},
          {label: 'PROCESO',value: 'nombre_tipo_proceso'},          
          {label: 'RENDIMIENTO',value: 'porcentaje_rendimiento'},
          {label: 'CEREZO',value: 'porcentaje_cerezo'},
          {label: 'MANCHA (%)',value: 'porcentaje_mancha'},
          {label: 'HUMEDAD (%)',value: 'porcentaje_humedad'},
          {label: 'OLOR',value: 'olor_muestra'},
          {label: 'DENSIDAD (g./l.)',value: 'pergamino_densidad'},
          {label: 'ACTIVIDAD EN AGUA',value: 'pergamino_actividad_agua'},
          {label: 'COLOR',value: 'nombre_color_muestra'},
          {label: 'RENDIMIENTO EXPORTABLE',value: 'resumen_rendimiento_exportable_2'},
          {label: 'TOTAL DEFECTOS',value: 'defectos_gramos'},
          {label: 'DEFECTOS',value: 'defectos'},
          {label: 'OBSERVACIONES (ORO)',value: 'observaciones'},
          {label: 'PUNTAJE EN TAZA',value: 'puntaje_taza'},
          {label: 'FRAGANCIA / AROMA',value: 'fragancia_aroma_desc'},
          {label: 'PERFIL EN TAZA',value: 'perfil_taza'},
          {label: 'OBSERVACIONES (SENSORIAL)',value: 'descripcion'}
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Listado_Calidad_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData, settings);
  };

  export default CalidadListExport;
