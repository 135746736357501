import React from "react";
import { useAuth } from "./auth";
import { Redirect } from "react-router";
import { Route, useLocation } from "react-router-dom";

export default function AuthRoute({ path, exact, component }) {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <Route
      render={() =>
        user || pathname === "/user/login-v3" ? (
          <Route path={path} exact={exact} component={component} />
        ) : (
          <Redirect to="/user/login-v3" />
        )
      }
    />
  );
}
