import React from 'react';

const ModalGeneral = ({ title, onSave, onClose, show, loading, withFooter = true, preTitle, children }) => {
  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div
        // className="modal-dialog"
        style={{ top: 50, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            {preTitle ? preTitle : null}
            <h4 className="modal-title">{title}</h4>
            <button onClick={onClose} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          {children}
          {withFooter ? (
            <div className="modal-footer">
              {loading && (
                <div className="spinner-border text-warning" role="status">
                  <span className="sr-only">Guardando...</span>
                </div>
              )}
              {!loading && (
                <>
                  <button
                    onClick={onClose}
                    type="button"
                    className="btn btn-outline-secondary"
                    style={{ borderRadius: '20px 20px 20px 20px' }}>
                    <i className="fa fa-times"></i> Cancelar
                  </button>
                  <button
                    onClick={() => onSave()}
                    type="button"
                    className="btn btn-outline-secondary btn-sirio"
                    style={{ borderRadius: '20px 20px 20px 20px' }}>
                    <i className="fa fa-save"></i> Guardar
                  </button>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ModalGeneral;
