import React, { useState, useEffect } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';
import SicLinkTable from '../../components/tables/sic-link-table.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import SicLinkCuentasTable from '../../components/tables/sic-link-cuentas-table.jsx';
import toggleIds from '../../utils/toggleIds.js';

const VincularSicParcelasNoParcela = () => {
  const location = useLocation();
  const history = useHistory();
  const [dataForms, setDataForms] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setBlocking(true);

    Api.get('admin/sic/vincular_formularios_parcelas', location.state.cuentas[0].id_cuenta)
      .then(response => {
        const filteredData = response.data.filter(item => item.form_type === 'sic_pp');
        setDataForms(filteredData);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
        // eslint-disable-next-line no-console
        console.log('e', e);
      });
  }, []);

  const navigateToNextStep = () => {
    const selected = dataForms.filter(it => selectedIds.includes(it.id));
    history.push({
      pathname: '/admin/vincular-sic-parcelas/list-No-parcela-confirm',
      state: { cuentas: location.state.cuentas, forms: selected },
    });
  };

  const navigateToPreviousStep = () => history.goBack();

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">Cuenta Seleccionada</h3>
          </div>
          <div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkCuentasTable title="Cuentas Nuevas" data={location.state.cuentas} />
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <h2 className="col-xl-12 font-lato-normal-400">
                PASO 3: Elige la ficha de parcela desde la cuál generarás una parcela
              </h2>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkTable
                  title="Formularios"
                  blocking={blocking}
                  data={dataForms}
                  toggleIds={toggleIds}
                  setter={setSelectedIds}
                  parcelaName
                  linkable
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                <button
                  onClick={navigateToPreviousStep}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  <i className="fa fa-arrow-left"></i> Regresar a paso anterior
                </button>
              </div>
              <div className="col-4">
                <button
                  onClick={navigateToNextStep}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}
                  disabled={selectedIds.length < 1}>
                  Siguiente paso <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicParcelasNoParcela;
