import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTheme } from "@mui/material/styles";

export const ReactHookFormInput = ({
  type,
  id,
  name,
  control,
  error = false,
  label = "",
  placeholder = "--- SELECCIONE ---",
  ...inputProps
}) => {
  const height = 44;
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const defaultOption = { value: 0, label: placeholder, isDisabled: true };

  return (
    <div style={{marginTop : 3}}>
    <FormControl fullWidth>
      <Controller
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <TextField
            type={type}
            size="medium"
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant="outlined"
            multiline={inputProps.multiline || false}
            style={{ height }}
            InputLabelProps={{
              style: {
                height
              },
            }}
            inputProps={{
              style: {
                height,
                padding: '0px 14px',
                margin: '0px 0px 0px 0px',
              },
          }}
          FormHelperTextProps={{ style: { fontSize: 9,paddingLeft : 0 }}}
          />
        )}
        control={control}
        name={name}
      />
      {error && <div className="invalid-feedback">{error.message}</div>}
    </FormControl>
    </div>
  );
};
