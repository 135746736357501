import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import BlockUi from 'react-block-ui';
import env from './../../environment.js';
import './comites-detalle.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

const pageStyle = `
@media print {
  .scroll-container {
    overflow: visible;
    height: fit-content;
  }

  .table {
  border-collapse: separate;
  border-spacing: 0px;
  }

  .tr, td {
    border: 2px solid black;
    border-collapse: separate;
    border-spacing: 0px;
}
`;

const ComitesDetalle = () => {
  const { uuid } = useParams();
  const history = useHistory();
  const [evaluacion, setEvaluacion] = useState({});
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);
      Api.get('comite_evaluaciones', uuid)
        .then(response => {
          response.data = response.data.map(function(item) {
            return {
              ...item,
              _fecha_ingreso: item.fecha_ingreso != null ? item.fecha_ingreso.substr(0, 4) : null,
            };
          });

          setEvaluacion(response.data[0]);

          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
          setBlocking(false);
        });
    }
  }, [uuid]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const [nameToPrint, setNameToPrint] = useState('Comité de evaluación');

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameToPrint,
    onAfterPrint: useCallback(() => history.goBack(), [history]),
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
    ignoreGlobalStyles: false,
  });

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item active">Evaluación</li>
          </ol>

          <Link to={{ pathname: `/admin/cuentas/perfil/${evaluacion.cuenta_uuid}` }}>
            <button type="button" className="btn btn-outline-secondary mt-4" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-arrow-left"></i> Volver al perfil
            </button>
          </Link>
          <button
            onClick={handlePrint}
            className="btn btn-outline-secondary mt-4"
            style={{ borderRadius: '20px 20px 20px 20px', marginLeft: '10px' }}
            type="button">
            <i className="fa fa-print" aria-hidden="true"></i> Imprimir
          </button>
        </Breadcrumb>

        <div className="row pb-4" ref={componentRef}>
          <div className="col-xl-12">
            <CardContainer height={'100%'}>
              <div className="row">
                <div className="col-xl-12">
                  <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                    <div className="reporte-evaluacion">
                      <table className="table table-report table-no-border mb-0">
                        <tbody>
                          <tr>
                            <td>
                              {evaluacion.cooperativa_logo && (
                                <img
                                  style={{
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  src={`${env.assetURL}${evaluacion.cooperativa_logo}`}
                                  alt="logo_cooperativa"
                                />
                              )}
                            </td>
                            <td>
                              {evaluacion.certificacion_logo && (
                                <img
                                  style={{
                                    width: 'auto',
                                    height: '100px',
                                    float: 'right',
                                  }}
                                  src={`${env.assetURL}${evaluacion.certificacion_logo}`}
                                  alt="logo_ceritifcacion"
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <h5 className="text-center py-4">
                        <b>FORMATO COMITÉ DE APROBACIÓN</b>
                      </h5>

                      <table className="table table-bordered table-report">
                        <tbody>
                          <tr>
                            <td rowSpan="2" className="text-middle">
                              <b>Nombre del Productor:</b> {evaluacion.cuenta}
                            </td>
                            <td rowSpan="2" className="text-middle">
                              <b>Código:</b> {evaluacion.codigo_tx ? evaluacion.codigo_tx : evaluacion.clave_tx}
                            </td>
                            <td rowSpan="2" className="text-middle">
                              <b>Comunidad:</b> {evaluacion.localidad}
                            </td>
                            <td>
                              <b>Categoría:</b> {evaluacion.cuenta_categoria}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Ciclo:</b> {evaluacion.ciclo}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <b>Nivel de riesgo para este productor</b>
                            </td>
                            <td colSpan="2">{evaluacion.nivel_riesgo}</td>
                          </tr>
                        </tbody>
                      </table>

                      <p>
                        <b>
                          Basándose en el contenido de la ficha(s) técnica(s) de inspección interna, el comité interno ha tomado la
                          siguiente decisión:
                        </b>
                      </p>

                      <table className="table table-bordered" style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
                        {// eslint-disable-next-line eqeqeq
                        evaluacion.condicion == 1 && (
                          <tbody>
                            <tr>
                              <td className="w-25">
                                <b>a) Aprobado sin condiciones</b>
                              </td>
                              <td colSpan="2">
                                <b>Observación:</b> {evaluacion.aprob_sc_observaciones}
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {// eslint-disable-next-line eqeqeq
                        evaluacion.condicion == 2 && (
                          <tbody>
                            <tr>
                              <td className="w-25" rowSpan={evaluacion.detalle.length + 3}>
                                <b>b) Aprobado con condiciones (indicar las condiciones):</b>
                              </td>
                              <td rowSpan="2">
                                <b>Condiciones establecidas</b>
                              </td>
                              <td rowSpan="2">
                                <b>Plazo de cumplimiento</b>
                              </td>
                              <td colSpan="3">
                                <b>Verificación del cumplimiento:</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Cumplido</b>
                              </td>
                              <td>
                                <b>No Cumplido</b>
                              </td>
                              <td>
                                <b>Fecha</b>
                              </td>
                            </tr>
                            {evaluacion.detalle.map((condition, i) => {
                              return (
                                <tr key={i}>
                                  <td>{condition.condicion}</td>
                                  <td>{condition.plazo}</td>
                                  <td>
                                    {// eslint-disable-next-line eqeqeq
                                    condition.verif_cumplido == 1 ? 'X' : ''}
                                  </td>
                                  <td>
                                    {// eslint-disable-next-line eqeqeq
                                    condition.verif_cumplido == 2 ? 'X' : ''}
                                  </td>
                                  <td>{condition.verif_fecha}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                        {// eslint-disable-next-line eqeqeq
                        evaluacion.condicion == 3 && (
                          <tbody>
                            <tr>
                              <td className="w-20">
                                <b>c) Sancionado:</b>
                              </td>
                              <td className="w-20">
                                <b>Categoría en que estaba:</b> {evaluacion.sanc_cat_anterior_str}
                              </td>
                              <td className="w-20">
                                <b>Categoría en que se ubica por la sanción:</b> {evaluacion.sanc_cat_nueva_str}
                              </td>
                              <td className="w-20">
                                <b>Fecha de inicio de la sanción:</b> {evaluacion.sanc_fecha_inicio}
                              </td>
                              <td className="w-20">
                                <b>Fecha de término de la sanción:</b> {evaluacion.sanc_fecha_termino}
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan="2">
                                <b>Causas de la sanción:</b>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4">{evaluacion.aprob_sc_observaciones}</td>
                            </tr>
                          </tbody>
                        )}
                      </table>

                      <p>
                        <b>Autorización por el comité interno de aprobación</b>
                      </p>

                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td className="text-center align-bottom">
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{evaluacion.lugar}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                              <br></br>
                              <span>
                                <ins>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{evaluacion.fecha_aut}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</ins>
                              </span>
                              <br></br>
                              <b>Lugar y fecha</b>
                            </td>
                            <td className="text-center align-bottom">
                              {evaluacion.firma_imagen && (
                                <img
                                  style={{
                                    width: '250px',
                                    height: 'auto',
                                  }}
                                  src={`${env.assetURL}${evaluacion.firma_imagen}`}
                                  alt="firma"
                                />
                              )}
                              <br></br>
                              <span>
                                <ins>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{evaluacion.user_auth}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</ins>
                              </span>
                              <br></br>
                              <b>Nombre y firma</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </BlockUi>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComitesDetalle;
