import xlsx from 'json-as-xlsx';
import moment from 'moment';

const CuentasListExport = (data, coopName = '') => {
  let xlsData = [
    {
      sheet: 'Productores',
      columns: [
        { label: 'CLAVE', value: 'clave_tx' },
        { label: 'CÓDIGO', value: 'codigo_tx' },
        { label: 'NOMBRE PRODUCTOR', value: 'nombre' },
        { label: 'REGIÓN', value: 'cuenta_region' },
        { label: 'MUNICIPIO', value: 'municipio' },
        { label: 'COMUNIDAD', value: 'localidad' },
        { label: 'CATEGORÍA', value: 'cuenta_categoria' },
        { label: 'No F. SIC', value: 'total_tx_sic' },
        { label: 'No F. PO', value: 'total_tx_po' },
        { label: 'No PARCELAS', value: 'total_parcelas' },
        { label: 'CICLO', value: 'ciclo' },
      ],
      content: data,
    },
  ];

  let settings = {
    fileName: `${moment().format('YYYY')}_${moment().format('MM')}_${moment().format(
      'DD'
    )}_${coopName}_listado_productores_${moment().format('HHmmss')}`,
  };
  xlsx(xlsData, settings);
};

export default CuentasListExport;
