import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Api from '../../../api/global.service';
import mapChartData from '../../../utils/mapChartData.js';
import renderCustomizedLabel from '../../../utils/renderCustomizedLabel.js';
import BlockUi from 'react-block-ui';

const ParcelasResumenPlantas = ({ id_ciclo }) => {
  const [dataGrafica, setDataGrafica] = useState([]);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    if (id_ciclo > 0) {
      getData();
    }
  }, [id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/parcelas/plantas', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    }).then(response => {
      let chartData = mapChartData(response.data, 'tipo', 'total');
      // console.log(chartData);
      setDataGrafica(chartData);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <div className="row card-category-dashboard" style={{ height: 350 }}>
        <p className="dashboard-titulo-grafica">Plantas en parcelas:</p>
        <div className="col-xl-12 col-md-12">
          <ResponsiveContainer width="100%" height={250}>
            <PieChart width={500} height={false}>
              <Pie
                data={dataGrafica}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                isAnimationActive={false}>
                {dataGrafica.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend layout="horizontal" verticalAlign="bottom" align="center" />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </BlockUi>
  );
};

export default ParcelasResumenPlantas;
