import React from 'react';
import { PageSettings } from './../../config/page-settings.js';
import { useAuth } from "./../../components/content/auth.jsx";
import { useHistory } from "react-router-dom";

class SidebarProfile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			profileActive: 0
		};
		this.handleProfileExpand = this.handleProfileExpand.bind(this);
	}

	handleProfileExpand(e) {
		e.preventDefault();
		this.setState(state => ({
			profileActive: !this.state.profileActive,
		}));
	}
  
	render() {
		return (
			<PageSettings.Consumer>
				{({pageSidebarMinify}) => (
					<ul className="nav"></ul>
				)}
			</PageSettings.Consumer>
		)
	}
}

export default (props) => { 
	const auth = useAuth();
	const history = useHistory();
	return <SidebarProfile auth={auth} history={history} {...props}/>;
};