import React from 'react';
import Swal from 'sweetalert2';
import env from './environment.js';
export let Api = React.createContext();

Api.url = `${env.apiURL}/`;
Api.canFetch = true;
const sesion = localStorage.getItem(`${env.appName}-sesion`);
if (sesion) {
  if (sesion !== 'undefined') {
    Api.user_profile = JSON.parse(sesion);
  }
}

Api.fetch = async (service, params, method = 'GET') => {
  if (!Api.canFetch) {
    return false;
  }
  // aqui se construye la respuesta, donde se toma el metodo dado en el fetch
  // (POST,GET, etc), el body/cuerpo (si se envia informacion al api) y los
  // headers

  const response = await fetch(Api.url + service, {
    // mode: 'cors',
    method: method,
    body: params && JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem(`${env.appName}-token`),
    },
  });
  if (!response.ok) {
    if (response.status === 401) {
      Api.canFetch = false;

      if (service !== 'auth/login') {
        localStorage.clear(`${env.appName}-token`);
        Api.canFetch = false;
        Swal.fire({
          title: 'Error',
          text: 'Tu sesión ha expirado, por favor accede de nuevo.',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Ok',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href = 'login';
          }
        });
      }
    }

    if (response.status === 500) {
      Swal.fire({
        title: 'Error de conexión a internet.',
        text: 'Por favor intente de nuevo su última acción.',
        icon: 'error',
        showCancelButton: false,
        cancelButtonColor: 'green',
        confirmButtonText: 'Ok',
      });

      Api.canFetch = false;
      return false;
    }

    if (response.status === 422) {
      return response.json();
    } else {
      throw response;
    }
  } else {
    return response.json();
  }
};

Api.delete = async (table, id) => {
  return await Api.fetch(`${table}/${id}`, null, 'DELETE');
};

Api.auth = {
  token: localStorage.getItem(`${env.appName}-token`),
  avoid: ['login'],
  setToken: token => {
    localStorage.setItem(`${env.appName}-token`, token);
    Api.auth.token = token;
  },
  check: () => {
    // if (!Api.auth.token) {
    //     if (!Api.auth.avoid.includes(window.location.pathname.split("/")[1])) {
    //         window.location.href = "/login";
    //     }
    // } else {
    // }
  },
  login: (frmData, callBack) => {
    Api.xhr('auth/login', frmData, response => {
      callBack(response);
    });
  },
  logout: () => {
    Api.xhr('auth/logout', null, r => {
      localStorage.clear(`${env.appName}-token`);
      window.location.href = '/login';
    });
  },
  profile: callBack => {
    if (!Api.auth.avoid.includes(window.location.pathname.split('/')[1])) {
      Api.xhr('auth/profile', null, r => {
        callBack(r);
      });
    }
  },
};

Api.auth.check();
