
import React from 'react';


class ExampleCustomInput extends React.Component {

  render() {
      const {value, onClick, placeholder = "Seleccione una fecha"} = this.props;
      return (
        <div className ="input-group"> 
          <div className="input-group-prepend">
            <i className="fa fa-calendar" style={{padding: 14, color: '#ffffff'}}></i>
          </div>         
          <input type="text" className="form-control" value={value} onClick={onClick} placeholder={placeholder}/>
          <div className="input-group-append">
            <i className="fa fa-calendar" onClick={onClick} style={{padding: 14}}></i>
          </div>
        </div>
      );
  }

}

export default ExampleCustomInput;