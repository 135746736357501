import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = ({ goTo, label }) => {
  const history = useHistory();

  return (
    <button
      onClick={() => (goTo ? goTo : history.goBack())}
      type="button"
      className="btn btn-outline-secondary btn-sirio"
      style={{ borderRadius: '20px' }}>
      <i className="fa fa-arrow-left mr-2"></i> {label ? label : 'Volver al listado'}
    </button>
  );
};

export default BackButton;
