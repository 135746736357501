import React, { useState } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';
import SicLinkTable from '../../components/tables/sic-link-table.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import SicLinkCuentasTable from '../../components/tables/sic-link-cuentas-table.jsx';
import Swal from 'sweetalert2';
import generalSuccessModal from '../../components/modal/modal-success.jsx';

const VincularSicParcelasNoParcelaConfirm = () => {
  const location = useLocation();
  const history = useHistory();
  const [blocking, setBlocking] = useState(false);

  const onSubmit = () => {
    setBlocking(true);
    const form = location.state.forms[0];
    const data = {
      id_sic: form.id,
      id_cuenta: location.state.cuentas[0].id_cuenta,
      nombre_parcela: form.parcela_nombre_otro,
      num_plantas: form.num_plantas,
      superficie: form.tx_superficie,
    };

    Api.create('admin/sic/vinculo_forms_create_parcela', data)
      .then(response => {
        const { success, message, data } = response.data;
        const successMessage = `Parcela creada correctamente y vinculada a formulario. Revisa si no hay Croquis y PO por vincular ahora.`;
        generalSuccessModal(success, successMessage, () => history.go(-3), message, data);
      })
      .catch(e => {
        setBlocking(false);
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
  };

  const onCancel = () => history.go(-3);

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">Cuenta Seleccionada</h3>
          </div>
          <div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkCuentasTable title="Cuentas Nuevas" data={location.state.cuentas} blocking={blocking} />
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <h3 className="col-xl-12 font-lato-normal-400">
                Formulario para generar parcela (se tomarán estos datos y se generará la parcela)
              </h3>
            </div>
            <div>
              <div className="row">
                <div className="col-xl-12">
                  <SicLinkTable title="Formularios" data={location.state.forms} blocking={blocking} />
                </div>
              </div>
            </div>
            <div>
              <h3>Se generará y vinculará una parcela con los siguientes datos:</h3>
              <div
                className="col-xl-4"
                style={{
                  border: '2px solid #789D16',
                  padding: '10px',
                  marginBottom: '20px',
                  borderRadius: '5px',
                }}>
                <p>
                  <b>Nombre:</b> {location.state.forms[0].parcela_nombre_otro}
                </p>
                <p>
                  <b>Plantas:</b> {location.state.forms[0].num_plantas}
                </p>
                <p>
                  <b>Superficie:</b> {location.state.forms[0].tx_superficie}
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                <button
                  onClick={onCancel}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
              </div>
              <div className="col-4">
                <button
                  onClick={onSubmit}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  Confirmar creación de parcela y vinculación con SIC
                </button>
              </div>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicParcelasNoParcelaConfirm;
