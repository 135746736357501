import React, { useState, useEffect } from 'react';

const SelectLocalidadRiesgo = ({ selected, setFilter }) => {
  const [data] = useState([
    { value: 1, name: 'Bajo' },
    { value: 2, name: 'Medio' },
    { value: 3, name: 'Alto' },
  ]);
  const [value, setValue] = useState(-1);

  useEffect(() => {
    handlerChange(selected !== -1 ? selected : 0, data);
  }, []);

  const handlerChange = (index, data) => {
    let obj = data[index];
    setValue(index);
    setFilter({ index: index, target: obj });
  };

  return (
    <div style={{ width: '180px' }}>
      <select
        value={value}
        onChange={e => {
          handlerChange(parseInt(e.target.value), data);
        }}
        style={{
          width: 'calc(100% - 54px)',
          borderRadius: '5px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          padding: '2px',
          borderColor: '#DADADA',
        }}>
        {data.map((option, index) => (
          <option key={index} value={index}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectLocalidadRiesgo;
