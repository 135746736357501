import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Api from '../../api/global.service';
import Swal from 'sweetalert2';
import generalSuccessModal from '../modal/modal-success';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import ExampleCustomInput from '../modal/input';
import Moment from 'moment';
import Select from 'react-select';
import { es } from 'date-fns/locale';

const ComiteForm = ({ idDetalle, observCondicion, plazo, verifCumplido, verifFecha, onClose, showModal }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const options = [
    { value: 1, label: 'Sí' },
    { value: 2, label: 'No' },
  ];

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      verif_fecha: verifFecha ? new Date(Date.parse(verifFecha + 'T12:00:00')) : null,
      verif_cumplido: verifCumplido,
    },
  });

  useEffect(() => {
    reset({
      verif_fecha: verifFecha ? new Date(Date.parse(verifFecha + 'T12:00:00')) : null,
      verif_cumplido: verifCumplido,
    });
    // eslint-disable-next-line eqeqeq
    setValue(
      'verif_cumplido',
      options.find(item => item.value == verifCumplido)
    );
  }, [showModal]);

  const onSubmit = values => {
    setLoading(true);
    const payload = {
      verif_fecha: values.verif_fecha ? Moment(values.verif_fecha).format('yyyy-MM-DD') : null,
      verif_cumplido: values.verif_cumplido?.value,
      id: idDetalle,
    };

    Api.create(`comite_evaluaciones_update_details`, payload)
      .then(response => {
        const { success, message, data } = response.data;
        const finish = () => {
          history.go(0);
          setLoading(false);
        };
        generalSuccessModal(success, `Evaluación Actualizada con éxito`, finish, message, data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        const err = error.response.data;
        const errorMessage = err.errors['new_value'] !== undefined ? err.errors['new_value'] : err.errors.message;
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage,
        });
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className="modal-body">
        <div className="row my-4">
          <h4
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            &quot;{observCondicion}&quot; con plazo hasta: {plazo}
          </h4>
        </div>
        <div className="row my-4">
          <h4
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            Fecha en que se cumplió
          </h4>
          <div className="col-12">
            <Controller
              name="verif_fecha"
              control={control}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Elige una fecha"
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                  wrapperClassName="w-100"
                  customInput={<ExampleCustomInput />}
                  dateFormat={'dd/MMM/yyyy'}
                  minDate={new Date()}
                  locale={es}
                />
              )}
            />
            {errors?.verif_fecha && <div className="invalid-feedback">{errors?.verif_fecha}</div>}
          </div>
        </div>
        <div className="row my-4">
          <div className="col-12">
            <h4
              style={{
                textAlign: 'center',
                width: '100%',
              }}>
              Cumplido
            </h4>
            <Controller
              name="verif_cumplido"
              control={control}
              defaultValue={verifCumplido}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  styles={{
                    valueContainer: base => ({
                      ...base,
                      paddingLeft: 38,
                    }),
                    singleValue: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    placeholder: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    fontSize: 18,
                  }}
                />
              )}
            />
            {errors?.selectColumn && <div className="invalid-feedback">{errors?.selectColumn}</div>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loading && (
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Guardando...</span>
          </div>
        )}
        {!loading && (
          <>
            <button onClick={onClose} type="button" className="btn btn-outline-secondary" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i> Cancelar
            </button>
            <button type="submit" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-save"></i> Guardar
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default ComiteForm;
