import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { useHistory } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import toggleIds from '../../utils/toggleIds';
import SicLinkCuentasTable from '../../components/tables/sic-link-cuentas-table';
import SearchColumn from '../../components/search/search-column';

const VincularSicParcelas = () => {
  // const auth = useAuth();
  const history = useHistory();
  const [dataCuentas, setDataCuentas] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [selectedCuentasIds, setSelectedCuentasIds] = useState([]);

  useEffect(() => {
    setBlocking(true);
    Api.getAll('admin/sic/cuentas_por_vincular_parcelas')
      .then(response => {
        setDataCuentas(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
        // eslint-disable-next-line no-console
        console.log('e', e);
      });
  }, []);

  const navigateToNextStep = () => {
    if (selectedCuentasIds.length === 1) {
      const selected = dataCuentas.filter(it => selectedCuentasIds.includes(it.id_cuenta));
      history.push({
        pathname: '/admin/vincular-sic-parcelas/list-p2',
        state: { cuentas: selected },
      });
    }
  };

  const [originalData, setOriginalData] = useState([]);

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'NOMBRE' },
      { value: 2, target: 'nombre_coop', label: 'COOPERATIVA' },
      { value: 3, target: 'cuenta_region', label: 'REGIÓN' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'localidad', label: 'LOCALIDAD' },
      { value: 7, target: 'fecha_ingreso', label: 'FECHA DE INGRESO' },
    ],
    []
  );

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">
              Elige un productor (se muestran productores que tienen formularios con parcelas desvinculadas)
            </h3>
          </div>
          <div>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataCuentas(evt);
                  }}
                />
              </div>
              <div className="col-xl-12">
                <SicLinkCuentasTable
                  title="Cuentas Nuevas"
                  blocking={blocking}
                  data={dataCuentas}
                  toggleIds={toggleIds}
                  setter={setSelectedCuentasIds}
                  linkable
                />
              </div>
            </div>
          </div>
          <div>
            <div className="col-4">
              <button
                onClick={navigateToNextStep}
                type="button"
                className="btn btn-outline-secondary"
                style={{
                  borderRadius: '20px 20px 20px 20px',
                  borderColor: selectedCuentasIds.length > 1 ? 'red' : 'black',
                  color: selectedCuentasIds.length > 1 ? 'red' : 'black',
                }}
                disabled={selectedCuentasIds.length !== 1}>
                {selectedCuentasIds.length > 1 ? '  No puedes elegir más de un productor' : '  Siguiente paso  '}
                <i className="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicParcelas;
