import React, { useState, useEffect, createRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import CardPriceCategory from '../../components/card/card-price-category.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import Pagination from '../../components/pagination/pagination';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import CheckboxList from '../../components/forms/checkbox-list.jsx';

import { subscribe, unsubscribe } from '../../components/events';
import LinkDownload from '../../components/link/link-download.jsx';
// import toggleIds from '../../utils/toggleIds.js';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import xlsx from 'json-as-xlsx';
import insertIf from '../../utils/insertIf.js';
import ModalRemanente from '../../components/modal/modal-remanente.jsx';
import ModalPrecioRemanente from '../../components/modal/modal-precio-remanente.jsx';

const EntregasRemanenteList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const dialog = createRef();
  const dialogRemanente = createRef();
  const [menuVisible, setMenuVisible] = useState(false);
  const [report, setReport] = useState('all');
  const [blockingReport, setBlockingReport] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [producto, setProducto] = useState('café');
  const [precioCategoria, setPrecioCategoria] = useState([]);
  const [renewRecibos, setRenewRecibos] = useState(true);

  useEffect(() => {
    if (coopConfig?.config?.cooperativa?.producto?.nombre) {
      setProducto(coopConfig.config.cooperativa.producto.nombre);
    }
  }, [coopConfig.config]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'REMANENTE',
        columns: [
          {
            Header: '',
            accessor: 'x',
            minWidth: 20,
            Cell: ({ row }) => {
              return (
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="id" onClick={e => setSelectedData(row.original)} />
                </div>
              );
            },
          },
          {
            Header: 'FOLIO REMANENTE',
            accessor: 'folio',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CATEGORÍA',
            accessor: 'cuenta_categoria',
            sortable: true,
            minWidth: 90,
            Cell: ({ value, row }) => {
              if (value != null) {
                return <LabelCategory label={value} color={row.original.cuenta_categoria_color}></LabelCategory>;
              } else {
                return value;
              }
            },
          },
          {
            Header: 'RECIBO',
            accessor: 'recibo_image',
            sortable: true,
            Cell: ({ value, row }) => {
              let v = value;
              if (v != null && v != '')
                return <LinkDownload url={`recibos/remanentes/${row.original.folio}`} label="Descargar" icon={env.images.icon.pdf} />;
              else return <i className="text-muted">Generando...</i>;
            },
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: 'CLAVE / CÓDIGO',
            accessor: 'productor_codigo_tx',
            sortable: true,
            minWidth: 50,
            show: !!+coopConfig.config?.usa_codigo_tx,
          },
          {
            Header: 'NOMBRE PRODUCTOR',
            accessor: 'productor_nombre',
            sortable: true,
            minWidth: 50,
            Cell: ({ value, row }) => {
              if (row.original.productor_uuid != null) {
                return (
                  <Link
                    to={{
                      pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}`,
                    }}
                    className="m-r-2"
                    style={{ color: '#789D16' }}>
                    <span>{value}</span>
                  </Link>
                );
              } else {
                return value;
              }
            },
          },
          {
            Header: 'LOCALIDAD',
            accessor: 'localidad',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'REGIÓN',
            accessor: 'region',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio',
            sortable: true,
            minWidth: 50,
          },
        ],
      },
      {
        Header: 'INFORMACIÓN DE PAGO',
        columns: [
          {
            Header: 'PESO NETO (KGS)',
            accessor: 'peso_neto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'PRECIO A PRODUCTOR ($/KG)',
            accessor: 'precio_por_kg',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'IMPORTE TOTAL ($)',
            accessor: 'cantidad_pago',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },
        ],
      },
    ],
    [producto]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 2, target: 'folio', label: 'FOLIO' },
      { value: 3, target: 'productor_codigo_tx', label: 'CODIGO' },
      { value: 3, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 4, target: 'localidad', label: 'LOCALIDAD' },
      { value: 5, target: 'region', label: 'REGIÓN' },
      { value: 5, target: 'centro_acopio', label: 'CENTRO DE ACOPIO' },
      { value: 6, target: 'cuenta_categoria', label: 'CATEGORÍA' },
      { value: 7, target: 'peso_neto', label: 'PESO NETO (KGS)' },
      { value: 8, target: 'precio_por_kg', label: 'PRECIO A PRODUCTOR (PERG)' },
      { value: 9, target: 'cantidad_pago', label: 'IMPORTE TOTAL ($)' },
    ],
    []
  );

  useEffect(() => {
    subscribe('Precio::onSave', e => handleSave(e));
    subscribe('Remanente::onSave', e => handleSaveRemanente(e));

    return () => {
      unsubscribe('Precio::onSave');
      unsubscribe('Remanente::onSave');
    };
  }, []);

  useEffect(() => {
    ciclo.id && getPrecioCategoria();
    ciclo.id && getRemanentes();
  }, [ciclo]);

  const getRemanentes = async params => {
    const response = await Api.Acopio.search('remanente/recibos', {
      filter: [
        {
          column: 'id_cooperativa',
          operator: '=',
          value: auth.user.id_cooperativa,
        },
        {
          column: 'id_ciclo',
          operator: '=',
          value: params ? params.id_ciclo : ciclo.id,
        },
      ],
    });
    if (response.data.success == 1) {
      setData(response.data.data);
      setOriginalData(response.data.data);
      response.data.data.length == 0 && setRenewRecibos(true);
      setBlocking(false);
    }
  };

  const getPrecioCategoria = async params => {
    if (auth.user.id_cooperativa) {
      const response = await Api.Acopio.search('remanente/precio_por_cooperativa', {
        filter: [
          {
            column: 'id_cooperativa',
            operator: '=',
            value: auth.user.id_cooperativa,
          },
          {
            column: 'id_ciclo',
            operator: '=',
            value: params ? params.id_ciclo : ciclo.id,
          },
        ],
      });
      let preciosLabel = response.data
        .filter(res => res.estatus == 'Vigente')
        .map(precio => {
          let label = env.colors.primary_500;
          let labelBg = env.colors.primary_50;
          precio.id_cat_cuenta_categoria == 1 && (label = env.colors.neutral_4);
          precio.id_cat_cuenta_categoria == 1 && (labelBg = env.colors.neutral_3);
          precio.id_cat_cuenta_categoria == 2 && (label = env.colors.secondary_400);
          precio.id_cat_cuenta_categoria == 2 && (labelBg = env.colors.secondary_100);
          precio.id_cat_cuenta_categoria == 3 && (label = env.colors.secondary_600);
          precio.id_cat_cuenta_categoria == 5 && (labelBg = env.colors.neutral_1);
          return {
            ...precio,
            label: label,
            labelBg: labelBg,
          };
        })
        .sort((a, b) => {
          if (a.id_cat_cuenta_categoria > b.id_cat_cuenta_categoria) {
            return -1;
          }
        });
      setPrecioCategoria(preciosLabel);
    }
  };

  const onShowModal = (values = {}) => {
    const params = { id_ciclo: ciclo.id };
    dialog.current.show({ ...params, ...values });
  };

  const onShowModalEdit = (values = {}) => {
    dialogRemanente.current.show({ ...values });
  };

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i == 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  // functions for SearchColumn component
  const setFilter = value => {
    setCiclo(value.target);
  };

  const setDataFilter = dataFilter => {
    dataFilter.length > 0 && setData(dataFilter);
  };

  const handleSave = async event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el precio remanente.`,
    });
    const dataCoop = event.detail.dataCoop;
    // refresh category list prices
    await getPrecioCategoria(dataCoop);
    // refresh pagos list
    handleReceipts(dataCoop);
  };

  const handleSaveRemanente = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se actualizó con éxito el pago remanente.`,
    });
    // refresh pagos list
    getRemanentes();
  };

  const handleDownloadReport = event => {
    event.preventDefault();
    // if (report !== 'all') {
    //   const filteredDataByDate = data.filter(item => item.fecha === date);
    //   exportarXlsx(filteredDataByDate, `remanentes_acopios_${ciclo.id}_deFecha_${date}`);
    // } else {
    exportarXlsx(data, `remanentes_acopios_${ciclo.id}`);
    // }
  };

  const handleReceipts = async params => {
    const response = await Api.Acopio.create('remanente/generar_recibos', params);
    if (response.status == 201) {
      // data create table here? call same endpoint refresh?
      Swal.fire({
        icon: 'success',
        title: '',
        text: response.data.data.length > 10 ? response.data.data : `Sus recibos se estan generando.`,
      });
      // data list
      getRemanentes(params);
      // block some buttons
      setRenewRecibos(true);
    }
  };

  const exportarXlsx = (data, filename) => {
    const xlsData1 = [
      {
        sheet: 'RemanentesXproductor',
        columns: [
          { label: 'FOLIO REMANENTE', value: 'folio' },
          { label: 'CATEGORÍA', value: 'cuenta_categoria' },
          ...insertIf(!!+coopConfig.config?.usa_codigo_tx, {
            label: 'CLAVE / CODIGO',
            value: 'productor_codigo_tx',
          }),
          { label: 'NOMBRE PRODUCTOR', value: 'productor_nombre' },
          { label: 'LOCALIDAD', value: 'localidad' },
          { label: 'REGIÓN', value: 'region' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio' },
          { label: 'PESO NETO (KGS)', value: 'peso_neto' },
          {
            label: 'PRECIO A PRODUCTOR ($/KG)',
            value: row => +row.precio_por_kg,
            format: '$#,##0.00',
          },
          {
            label: 'IMPORTE TOTAL ($)',
            value: row => +row.cantidad_pago,
            format: '$#,##0.00',
          },
        ],
        content: data,
      },
    ];

    const settings = {
      fileName: filename,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Remanente</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}
          acopio
        />
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row mb-4">
          {/* Category prices boxes */}
          <div className="col-10">
            {/* Current prices */}
            {precioCategoria.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <h4>Precio por categoría</h4>
                    <p>Precio por kg. para generar el pago remanente</p>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={() => onShowModal({ precioCategoria: precioCategoria })}
                      type="button"
                      className="btn btn-sirio btn-outline-secondary"
                      style={{
                        borderRadius: '20px 20px 20px 20px',
                        marginLeft: 10,
                      }}>
                      <i className="fa fa-pen mr-2"></i> Editar precio
                    </button>
                  </div>
                </div>
                <div className="row">
                  {precioCategoria.map(pc => (
                    <>
                      <div className="col-3" key={pc.precio_id}>
                        <div className="mr-4">
                          <CardPriceCategory
                            height={92}
                            label={pc.categoria.replace('ransición ', '')}
                            value={pc.precio}
                            unit={'$/kg'}
                            colors={{
                              bgCard: env.colors.neutral_2,
                              label: pc.label,
                              labelBg: pc.labelBg,
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                <div className="row">
                  <div className="col-2">
                    <button
                      onClick={() =>
                        handleReceipts({
                          id_ciclo: ciclo.id,
                          id_cooperativa: auth.user.id_cooperativa,
                        })
                      }
                      type="button"
                      className="btn btn-sirio"
                      disabled={renewRecibos && data.length > 0}
                      style={{ borderRadius: '20px' }}>
                      <i className="fa fa-save mr-2"></i> Generar recibos
                    </button>
                  </div>
                  <div className="col-10 pl-4">
                    {data.length > 0 && (
                      <>
                        <CheckboxList
                          name="reciboxCheckbox"
                          value={renewRecibos}
                          handleCheck={() => setRenewRecibos(!renewRecibos)}
                          checked={!renewRecibos}
                        />
                        <p className="p-4">
                          <strong>Volver a generar</strong> (usar cuando se ha realizado actualizaciones en las entregas)
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Register category prices */}
                <div className="row">
                  <div className="col-6">
                    <h4>Define un precio por categoría</h4>
                    <p>Éste es precio por kg. que se generará de remanente.</p>
                  </div>
                  <div className="col-6">
                    {auth.hasPermission('ACOPIOS_PRECIO_X_COOPERATIVA_NUEVO') && (
                      <button
                        onClick={() => onShowModal()}
                        type="button"
                        className="btn btn-sirio"
                        style={{ borderRadius: '20px 20px 20px 20px' }}>
                        <i className="fa fa-plus mr-2"></i> Registrar precio
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {/* Download excel link */}
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                {precioCategoria.length > 0 && (
                  <Link to="#" onClick={() => setMenuVisible(!menuVisible)} className="m-r-2" style={{ color: '#789D16' }}>
                    <img src={env.images.icon.xls} className="pr-1" /> <span>Descargar Excel</span>
                  </Link>
                )}
                {menuVisible === true && (
                  <form className="dropdown-menu dropdown-menu dropdown-menu-right d-block p-10" style={{ top: 32 }}>
                    <div className="form-check pb-2">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="report"
                        value="all"
                        checked={report === 'all'}
                        onClick={() => setReport('all')}
                      />
                      <label className="form-check-label" htmlFor="dropdownCheck">
                        Todos los registros
                      </label>
                    </div>
                    {blockingReport && (
                      <div className="d-flex justify-content-start float-right">
                        <div
                          className="spinner-border text-warning mr-2"
                          role="status"
                          aria-hidden="true"
                          style={{ height: 24, width: 24 }}></div>
                        <span className="mt-1">Descargando ...</span>
                      </div>
                    )}
                    {!blockingReport && (
                      <button type="submit" className="btn btn-sirio float-right" onClick={e => handleDownloadReport(e)}>
                        <i className="fa fa-download mr-2"></i> Descargar
                      </button>
                    )}
                  </form>
                )}
              </div>
            )}
          </div>
        </div>

        {/* table with pagination */}
        {data.length > 0 && (
          <>
            <div className="row">
              <div className="col-md-12">
                <SearchColumn data={originalData} columns={searchColumns} setDataFilter={evt => setDataFilter(evt)}></SearchColumn>
              </div>
            </div>
            <Pagination
              blocking={blocking}
              selected="id"
              columns={columns}
              data={data}
              pagination={true}
              fnBackgroundColor={getBackgroundColor}
              fnColor={getColor}></Pagination>
            {/* footer buttons */}
            <div className="row mt-3">
              <div className="col-md-12">
                {auth.hasPermission('ACOPIOS_ENTREGAS_X_PRODUCTOR_NUEVO') && (
                  <>
                    <button
                      onClick={() => onShowModalEdit()}
                      type="button"
                      className="btn btn-sirio btn-outline-secondary"
                      style={{ borderRadius: '20px', marginLeft: 10 }}
                      disabled={!selectedData}>
                      <i className="fa fa-pen"></i> Editar pago remanente
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <ModalRemanente ref={dialogRemanente} title="Editar pago remanente" data={selectedData}></ModalRemanente>
        <ModalPrecioRemanente ref={dialog} title="Registrar remanente"></ModalPrecioRemanente>
      </CardContainer>
    </div>
  );
};

export default EntregasRemanenteList;
