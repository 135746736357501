import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import ModalPrecio from '../../components/modal/modal-precio.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

import { subscribe, unsubscribe } from '../../components/events';
import { useCoopConfig } from '../../components/content/coop-config.jsx';

const PrecioXCooperativaList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const dialog = React.createRef();

  const removePrice = (event, id) => {
    event.preventDefault();

    setBlocking(true);
    Api.Acopio.delete('registro_precio', id)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '',
          text: `Se eliminó con éxito el precio ${id}.`,
        });

        updateData(auth.user.id_cooperativa);
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'REGISTRO DE PRECIO',
        columns: [
          {
            Header: 'FECHA INICIO',
            accessor: 'fecha_inicio',
            sortable: true,
            minWidth: 80,
            Cell: cell => {
              return <LabelDate value={cell.value}></LabelDate>;
            },
          },
          {
            Header: 'FECHA FIN',
            accessor: 'fecha_fin',
            sortable: true,
            minWidth: 80,
            Cell: cell => {
              return <LabelDate value={cell.value}></LabelDate>;
            },
          },
          {
            Header: 'ESTADO',
            accessor: 'estatus',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelColor label={cell.value} color={cell.row.original.estatus_color}></LabelColor>;
            },
          },
          {
            Header: 'PRECIO',
            accessor: 'precio',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return <LabelNumber value={cell.value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'REGISTRO',
            accessor: 'registro',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              return (
                <p className="text-left" style={{ margin: 0 }}>
                  Registrado por {cell.value} el {cell.row.original.fecha_registro.substr(0, 10)}
                </p>
              );
            },
          },
          {
            Header: '',
            accessor: 'pendiente_9',
            sortable: true,
            minWidth: 50,
            Cell: cell => {
              if (cell.row.original.fecha_fin === '-' && auth.hasPermission('ACOPIOS_PRECIO_X_COOPERATIVA_ELIMINAR')) {
                return (
                  <Link onClick={e => removePrice(e, cell.row.original.precio_id)} className="text-danger m-r-2">
                    <span>
                      <i className="fa fa-trash"></i> Eliminar
                    </span>
                  </Link>
                );
              } else {
                return '';
              }
            },
          },
        ],
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'fecha_inicio', label: 'FECHA INICIO' },
      { value: 2, target: 'fecha_fin', label: 'FECHA FIN' },
      { value: 3, target: 'estatus', label: 'ESTADO' },
      { value: 4, target: 'precio', label: 'PRECIO' },
      { value: 5, target: 'registra', label: 'REGISTRO' },
    ],
    []
  );

  useEffect(() => {
    updateData(auth.user.id_cooperativa);

    subscribe('Precio::onSave', e => handleSave(e));

    return () => {
      unsubscribe('Precio::onSave');
    };
  }, []);

  const updateData = id_cooperativa => {
    setBlocking(true);
    Api.Acopio.search('precio_por_cooperativa', {
      filter: [{ column: 'id_cooperativa', operator: '=', value: id_cooperativa }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(data);
  };

  const getBackgroundColor = (i, j) => {
    if (i === 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i === 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el nuevo precio ${event.detail.id}.`,
    });

    updateData(auth.user.id_cooperativa);
  };

  return (
    <div>
      <ModalPrecio ref={dialog} title="Registrar nuevo precio"></ModalPrecio>

      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Precio</li>
        </ol>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}></SearchColumn>
          </div>
          <div className="col-2">
            {auth.hasPermission('ACOPIOS_PRECIO_X_COOPERATIVA_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/precio_coopertiva/${auth.user.id_cooperativa}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <NoPagination
          blocking={blocking}
          selected="id"
          columns={columns}
          data={data}
          fnBackgroundColor={getBackgroundColor}
          fnColor={getColor}></NoPagination>

        <div className="row">
          <div className="col-3">
            {auth.hasPermission('ACOPIOS_PRECIO_X_COOPERATIVA_NUEVO') && (
              <button
                onClick={() => dialog.current.show()}
                type="button"
                className="btn btn-sirio"
                style={{ borderRadius: '20px 20px 20px 20px' }}>
                <i className="fa fa-plus"></i> Registrar nuevo precio
              </button>
            )}
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default PrecioXCooperativaList;
