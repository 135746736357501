
import React from 'react';
import IconCircle from '../icon/icon-circle';
import LabelSimple from '../label/label-simple';

class MarkerCircle extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {		
		const getBgColor = () => {
			let color = this.props.color || '#D87748';
			let bgColor = '';

			/*
				ALTO   #4A6700
				MEDIO  #FFB94C
				BAJO   #D87748
			*/

			switch (color) {
				case '#4A6700':
					bgColor = '#FAFFDF';
					break;
				case '#FFB94C':
					bgColor = '#FCF2E6';
				break;
				case '#D87748':
					bgColor = '#FFEDEF';
				break;
			}



			return bgColor;
		}

		const getColor = () => {
			let colorText = this.props.color || '#D1293D';
			return colorText;


		}

		return (
			<div lat={this.props?.lat} lng={this.props?.lng}>				
				
				<IconCircle /*color={this.props.color}*/></IconCircle>
				<div style={{marginTop: -23, marginLeft: 30}}>

				<div className="label-category" style={{backgroundColor: getBgColor()}}>
					<h6 className="font-lato-normal-500" style={{color: getColor()}}>
						{this.props.label}
					</h6>
				</div>

				</div>
				{/* <img src={require(`../../assets/images/icons/T${this.props.tipo}.svg`)} style={{marginLeft: -16, marginTop: -24}}/> */}
			</div>
		);
	}
	
}

export default MarkerCircle;