import React, { useState } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';

import SicLinkTable from '../../components/tables/sic-link-table.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import SicLinkCuentasTable from '../../components/tables/sic-link-cuentas-table.jsx';
import Swal from 'sweetalert2';
import filterIds from '../../utils/filterIds.js';

const VincularSicListP3 = () => {
  const location = useLocation();
  const history = useHistory();
  const [blocking, setBlocking] = useState(false);

  const onSubmit = () => {
    setBlocking(true);
    const idsPP = filterIds(location.state.forms, 'sic_pp');
    const idsPO = filterIds(location.state.forms, 'tx_po');
    const idsCroquis = filterIds(location.state.forms, 'tx_croquis');

    const data = {
      id_cuenta: location.state.cuentas[0].id_cuenta,
      idsPP,
      idsPO,
      idsCroquis,
    };
    Api.create('admin/sic/vinculo_forms_cuenta_batch', data)
      .then(response => {
        const { success, message, data } = response.data;
        const plural = data > 1 ? 's' : '';

        if (success === 1) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: `${data} formulario${plural} vinculado${plural} correctamente`,
            confirmButtonText: 'Continuar',
          }).then(result => {
            if (result.isConfirmed) {
              history.go(-2);
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: message,
            showDenyButton: true,
            denyButtonText: `Regresar a Listado`,
          }).then(result => {
            if (result.isDenied) {
              // eslint-disable-next-line no-console
              console.log('response.data', response.data);
            }
          });
        }
      })
      .catch(e => {
        setBlocking(false);
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
  };

  const onCancel = () => history.go(-2);

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">Formularios Seleccionados</h3>
          </div>
          <div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkTable title="Formularios" data={location.state.forms} blocking={blocking} />
              </div>
            </div>
          </div>
          <div className="row"></div>
          <div className="row">
            <h2 className="col-xl-12 font-lato-normal-400">Cuenta Seleccionada</h2>
          </div>
          <div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkCuentasTable title="Cuentas Nuevas" data={location.state.cuentas} blocking={blocking} />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                <button
                  onClick={onCancel}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
              </div>
              <div className="col-4">
                <button
                  onClick={onSubmit}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  Confirmar vinculación de formularios con cuenta
                </button>
              </div>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicListP3;
