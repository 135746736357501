import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Api from '../../api/global.service';
import Swal from 'sweetalert2';
import generalSuccessModal from '../modal/modal-success';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

const SelectForm = ({
  initialValue,
  columnLabel,
  column,
  idCuenta,
  onClose,
  showModal,
  idForm,
  idCiclo,
  idParcela,
  catalog,
  setData,
  keyValue = 'id',
  keyLabel = 'nombre',
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      selectColumn: initialValue,
    },
  });

  useEffect(() => {
    reset({
      selectColumn: initialValue,
    });
    Api.getAll(catalog).then(response => {
      const opts = response.data.map(item => {
        return { value: item[keyValue], label: item[keyLabel] };
      });
      setOptions(opts);
      // Set initial value
      // eslint-disable-next-line eqeqeq
      setValue(
        'selectColumn',
        opts.find(item => item.value == initialValue)
      );
    });
  }, [showModal, catalog]);

  const onSubmit = values => {
    setLoading(true);
    const payload = {
      id_cuenta: idCuenta,
      new_value: values.selectColumn.value,
      column: column,
      id_sic_informacion_general: idForm,
      id_parcela: idParcela,
      id_ciclo: idCiclo,
    };

    Api.create(`admin/sic/padron_edit/${column}`, payload)
      .then(response => {
        const { success, message, data } = response.data;
        const finish = () => {
          if (setData) {
            setData({ label: values.selectColumn.label, id: values.selectColumn.value });
          } else {
            history.go(0);
          }
          onClose();
          setLoading(false);
        };
        generalSuccessModal(success, `${columnLabel} actualizado con éxito`, finish, message, data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        const err = error.response.data;
        const errorMessage = err.errors['new_value'] !== undefined ? err.errors['new_value'] : err.errors.message;
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage,
        });
      });
  };

  const [options, setOptions] = useState([]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className="modal-body">
        <div className="row my-4">
          <h4
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            {columnLabel}
          </h4>
          <div className="col-12">
            <Controller
              name="selectColumn"
              control={control}
              defaultValue={initialValue}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  styles={{
                    valueContainer: base => ({
                      ...base,
                      paddingLeft: 38,
                    }),
                    singleValue: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    placeholder: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    fontSize: 18,
                  }}
                />
              )}
            />
            {errors?.selectColumn && <div className="invalid-feedback">{errors?.selectColumn}</div>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loading && (
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Guardando...</span>
          </div>
        )}
        {!loading && (
          <>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-outline-secondary"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i> Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-outline-secondary btn-sirio"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-save"></i> Guardar
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default SelectForm;
