import React from 'react';

export const Subtitle2 = ({ name, parcela }) => {
  return (
    <>
      <div
        style={{
          marginRight: '5px',
          width: '50%',
        }}>
        <p
          style={{
            marginBottom: 0,
            fontSize: '16px',
          }}>
          Editando parcela:
        </p>
        <p style={{ marginBottom: 0, fontSize: '16px', fontWeight: 'bold' }}>{parcela}</p>
      </div>
      <div
        style={{
          // marginRight: '5px',
          width: '50%',
        }}>
        <p
          style={{
            marginBottom: 0,
            fontSize: '16px',
          }}>
          Productor/a:
        </p>
        <p style={{ marginBottom: 0, fontSize: '16px', fontWeight: 'bold' }}>{name}</p>
      </div>
    </>
  );
};

export default Subtitle2;
