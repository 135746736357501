import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import ModalEfectivo from '../../components/modal/modal-efectivo.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import Swal from 'sweetalert2';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import changeCropLabelByCoop from '../../utils/changeCropLabelByCoop.js';

import { subscribe, unsubscribe } from '../../components/events';
import EntregaEfectivoPorCentroAcopioTable from '../../nuup/componentes/Tables/entrega-efectivo-por-centro-acopio.js';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import HalfDonut from '../../components/chart/half-donut.jsx';
import CardColor from '../../components/card/card-color.jsx';
import { useCoopConfig } from '../../components/content/coop-config.jsx';

const EntregasEfectivoList = () => {
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const [dataMovements, setDataMovements] = useState([]);
  const [blockingMovements, setBlockingMovements] = useState(true);
  const [edit, setEdit] = useState(null);
  const [porCentroAcopio, setPorCentroAcopio] = useState(true);
  const [totalPagado, setTotalPagado] = useState(0);
  const [totalDesembolsado, setTotalDesembolsado] = useState(100);
  const [percentagePayedSent, setPercentagePayedSent] = useState(0);
  const [ciclo, setCiclo] = useState({});
  const dialog = createRef();
  const onClickEdit = (event, data) => {
    event.preventDefault();
    setEdit(data);
  };

  const onShowModal = () => dialog.current.add();

  useEffect(() => {
    if (edit !== null) {
      dialog.current.edit(edit);
    }
  }, [edit]);

  const baseColumns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN GENERAL',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            sortable: true,
            minWidth: 50,
            width: 80,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'nombre',
            sortable: true,
            minWidth: 150,
          },
          {
            Header: 'DESEMBOLSO EFECTIVO',
            accessor: 'desembolsos_sum_cantidad',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'TOTAL PAGADO',
            accessor: 'acopio_pagos_sum_total',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
          {
            Header: 'SALDO',
            accessor: 'saldo',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
            },
          },
        ],
      },
    ],
    []
  );

  const [dynamicColumns, setDynamicColumns] = useState(baseColumns);

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'CENTRO DE ACOPIO' },
      { value: 2, target: 'desembolsos_sum_cantidad', label: 'DESEMBOLSO EFECTIVO' },
      { value: 3, target: 'acopio_pagos_sum_total', label: 'TOTAL PAGADO A PRODUCTORES' },
      { value: 4, target: 'saldo', label: 'SALDO' },
    ],
    []
  );

  const updateData = id_ciclo => {
    setBlocking(true);
    setBlockingMovements(true);
    Api.Acopio.search(`efectivo_por_centro_acopio/${id_ciclo}`, {
      filter: [{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }],
    })
      .then(response => {
        const totalSent = response.data.reduce((current, sum) => current + +sum.desembolsos_sum_cantidad, 0);
        const totalPayed = response.data.reduce((current, sum) => current + +sum.acopio_pagos_sum_total, 0);
        const avance_anticipos = parseInt(+totalSent != 0 ? (totalPayed * 100) / totalSent : 0);
        setTotalDesembolsado(totalSent);
        setTotalPagado(totalPayed);
        setPercentagePayedSent(avance_anticipos);

        let maxColumns = response.data.map(item => item.desembolsos.length).reduce((current, sum) => (current > sum ? current : sum), 0);

        var columns = [];
        for (var i = 0; i < maxColumns; i++) {
          columns.push({
            Header: 'DESEMBOLSO ' + (i + 1),
            columns: [
              {
                Header: 'FECHA',
                accessor: 'desembolsos[' + i + '].fecha_envio',
                sortable: true,
                minWidth: 80,
                Cell: ({ value }) => {
                  return <LabelDate value={value}></LabelDate>;
                },
              },
              {
                Header: 'MONTO',
                accessor: 'desembolsos[' + i + '].monto',
                sortable: true,
                minWidth: 50,
                Cell: ({ value }) => {
                  return <LabelNumber value={value} currencyLabel={coopConfig.currency()?.symbol} digits={2}></LabelNumber>;
                },
              },
              {
                Header: 'MOTIVO DESEMBOLSO DE EFECTIVO',
                accessor: 'desembolsos[' + i + ']',
                id: i + '_3', //USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 100,
                Cell: ({ value }) => {
                  let v = value;
                  if (v != null)
                    return (
                      <LabelColor label={changeCropLabelByCoop(v.motivo, auth.user.id_cooperativa)} color={v.motivo_color}></LabelColor>
                    );
                  else return '';
                },
              },
              {
                Header: 'NOMBRE DE ACOPIADOR',
                accessor: 'desembolsos[' + i + '].usuario_acopiador.nombre',
                sortable: true,
                minWidth: 200,
              },
              {
                Header: 'REGISTRO',
                accessor: 'desembolsos[' + i + ']',
                id: i + '_5', //USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 350,
                Cell: cell => {
                  let v = cell.value;
                  if (v != null) return `Registrado por ${v.usuario_registro.nombre} el ${v.created_at.substr(0, 10)}`;
                  else return '';
                },
              },
              // {
              //   Header: 'RECIBO',
              //   accessor: 'desembolsos[' + i + '].recibo_pdf',
              //   id: i + '_6', ///USAR PARA PODER DUPLICAR EL ACCESSOR
              //   sortable: true,
              //   minWidth: 50,
              //   Cell: ({ value }) => {
              //     let v = value;
              //     if (v != null && v != '')
              //       return (
              //         <a href={`${env.assetURL}/${v}`} target="_blank" rel="noreferrer">
              //           <img src={env.images.icon.pdf} className="pr-1" /> <span>Descargar</span>
              //         </a>
              //       );
              //     else return '';
              //   },
              // },
              {
                Header: '',
                accessor: 'desembolsos[' + i + ']',
                id: i + '_7', ///USAR PARA PODER DUPLICAR EL ACCESSOR
                sortable: true,
                minWidth: 50,
                Cell: ({ value }) => {
                  let v = value;
                  if (v != null && auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_ELIMINAR'))
                    return (
                      <a onClick={e => onClickEdit(e, v)} className="text-success m-r-2">
                        <span className="text-success">
                          <i className="fa fa-pencil-alt"></i> Editar
                        </span>
                      </a>
                    );
                  else return '';
                },
              },
            ],
          });
        }

        setDynamicColumns([...baseColumns, ...columns]);

        setData(response.data);
        setOriginalData(response.data);

        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
    Api.Acopio.get(`efectivo_por_movimiento/${id_ciclo}`)
      .then(response => {
        setDataMovements(response.data);
        setBlockingMovements(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingMovements(false);
      });
  };

  useEffect(() => {
    updateData(ciclo.id);

    subscribe('Efectivo::onSave', e => handleSave(e));

    return () => {
      unsubscribe('Efectivo::onSave');
    };
  }, [ciclo]);

  const setDataFilter = data => {
    setData(data);
  };

  const setFilter = value => {
    setCiclo(value.target);
    updateData(value.target.id);
  };

  const getBackgroundColor = (i, j) => {
    if (i === 1) {
      return 'rgb(247, 239, 231)';
    } else {
      switch (j) {
        case 1:
          return '#91918A';
        case 3:
          return '#91918A';
        case 5:
          return '#91918A';
        case 7:
          return '#91918A';
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = i => {
    return i === 1 ? 'rgb(95, 95, 89)' : 'white';
  };

  const handleSave = event => {
    Swal.fire({
      icon: 'success',
      title: '',
      text: `Se guardó con éxito el desembolso de efectivo ${event.detail.id}.`,
    });
    window.location.reload();
    //updateData();
  };

  return (
    <div>
      <ModalEfectivo ref={dialog} title="desembolso de efectivo"></ModalEfectivo>

      <Breadcrumb>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}
          acopio
        />
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Efectivo</li>
        </ol>
        <div className="btn-group" role="group" style={{ borderRadius: 1 }}>
          <button
            type="button"
            onClick={() => setPorCentroAcopio(true)}
            className={'btn btn-light'}
            style={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: porCentroAcopio ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={false}>
            por Centro de Acopio
          </button>

          <button
            type="button"
            onClick={() => setPorCentroAcopio(false)}
            className={'btn btn-light'}
            style={{
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor: !porCentroAcopio ? '#FFEED9' : 'transparent',
              borderWidth: 1,
              borderColor: '#d4d4d4',
            }}
            disabled={false}>
            por Movimiento
          </button>
        </div>
      </Breadcrumb>

      <CardContainer height={'100%'}>
        {porCentroAcopio ? (
          <>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataFilter(evt);
                  }}></SearchColumn>
              </div>
              <div className="col-2">
                {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_EXPORTAR') && (
                  <div className="d-flex justify-content-end">
                    <LinkDownload
                      url={`xls/efectivo_cooperativa/${ciclo.id}/${auth.user.id_cooperativa}`}
                      label="Descargar Excel"
                      icon={env.images.icon.xls}></LinkDownload>
                  </div>
                )}
              </div>
            </div>

            <NoPagination
              blocking={blocking}
              selected="id"
              columns={dynamicColumns}
              data={data}
              fnBackgroundColor={getBackgroundColor}
              fnColor={getColor}></NoPagination>
          </>
        ) : (
          <EntregaEfectivoPorCentroAcopioTable
            originalData={dataMovements}
            blocking={blockingMovements}
            onClickEdit={onClickEdit}
            getColor={getColor}
            getBackgroundColor={getBackgroundColor}
            ciclo={ciclo}
          />
        )}
        <div className="row">
          <div className="col-3">
            {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_NUEVO') && (
              <button onClick={onShowModal} type="button" className="btn btn-sirio" style={{ borderRadius: '20px 20px 20px 20px' }}>
                <i className="fa fa-plus"></i> Registrar nuevo
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-8"></div>

          <div className="col-4">
            <div className="col-xl-12 col-md-12">
              <h1 className="font-lato-normal-600 halfdonut-percent">{percentagePayedSent}%</h1>
              <HalfDonut value={percentagePayedSent > 100 ? 100 : percentagePayedSent} color={'#BC8268'} width={250}></HalfDonut>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-md-6">
                  <CardColor
                    height={120}
                    label={'pagado a productores'}
                    value={totalPagado.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    mode={'onlyData'}
                    size={'18px'}
                    style={{ textAlign: 'center' }}></CardColor>
                </div>
                <div className="col-xl-6 col-md-6">
                  <CardColor
                    height={120}
                    label={'efectivo otorgado para pagos'}
                    value={totalDesembolsado.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    mode={'onlyData'}
                    size={'18px'}
                    style={{ textAlign: 'center' }}></CardColor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default EntregasEfectivoList;
