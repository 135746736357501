import React from 'react';

class LabelEstatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="label-category">
        <h6 className="font-lato-normal-500" style={{ color: this.props.color || '#9B9B9B' }}>
          <i className="fa fa-square"></i> {this.props.label || 'Sin asignar'}
        </h6>
      </div>
    );
  }
}

export default LabelEstatus;
