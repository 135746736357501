import { differenceInDays, isValid } from 'date-fns';

/**
 * IMPORTANT BEFORE UPDATING CODE!!!!
 * ANY CHANGE IN THIS FILE SHOULD BE DONE IN sirio_app AND sirio_admin equally to avoid errors.
 * VERSION 1.0
 */

const AVG_DAYS_PER_MONTH = 30;
const CLEAR_ZEROES = 10000;

// Forces new dates at midnight to have accurate day differences
const dateParser = (string = null) => {
  let parsedDate;
  if (string) {
    parsedDate = new Date(string);
    // add 24 hours assuming we are in negative time offset
    parsedDate.setHours(24, 0, 0, 0);
  } else {
    parsedDate = new Date();
    parsedDate.setHours(0, 0, 0, 0);
  }
  return parsedDate;
};

/**
 * Debt Calculator
 */
class DebtCalculator {
  /**
   *
   * @param {{
   *  credito: {
   *    fecha_ejecucion: string,
   *    cantidad_anticipo: number,
   *  },
   *  interestRate: number,
   *  pagos: [{
   *    cantidad_pago: number,
   *    fecha_ejecucion: string,
   *    updated_at: string
   *  }],
   *  stringDate: string
   * }} params
   */
  constructor({ credito, interestRate, pagos, stringDate }) {
    this.startDate = dateParser(credito.fecha_ejecucion);
    this.currentDate = this.startDate;
    this.pendingCapital = +credito.cantidad_anticipo;
    this.interestRate = +interestRate;
    this.totalInterest = 0;
    this.totalPayment = 0;
    this.pagos = pagos;
    this.deuda = [];
    this.debtTodayDate = stringDate ? dateParser(stringDate) : dateParser();
    this.debtTodayDateString = stringDate;
  }

  _getInterest({ currentDate, dateString, payment = 0 }) {
    let days = 0;
    let interest = 0;
    if (isValid(currentDate)) {
      days = differenceInDays(currentDate, this.currentDate);
      this.currentDate = currentDate;
      interest = (days * this.interestRate * this.pendingCapital) / (AVG_DAYS_PER_MONTH * CLEAR_ZEROES);
      this.totalInterest += interest;
      this.totalPayment += payment;
      this.pendingCapital = this.pendingCapital + interest - payment;
    }
    return {
      payment: payment,
      date: currentDate.toString(),
      dateString: dateString,
      daysPassed: days,
      totalDaysPassed: differenceInDays(currentDate, this.startDate),
      interest: interest,
      pendingCapital: this.pendingCapital,
      totalInterest: this.totalInterest,
      totalPayment: this.totalPayment,
    };
  }

  _cleanAndSortPagos() {
    this.pagos = this.pagos
      .map(p => {
        return {
          ...p,
          dateString: p.fecha_ejecucion,
          fecha_ejecucion: p.fecha_ejecucion ? p.fecha_ejecucion : p.updated_at,
        };
      })
      .sort((a, b) => dateParser(a.fecha_ejecucion) - dateParser(b.fecha_ejecucion));
  }

  getDebt() {
    let debtDetail = [];
    if (isValid(this.currentDate)) {
      this._cleanAndSortPagos();
      debtDetail = this.pagos.map(p => {
        return {
          ...this._getInterest({
            payment: +p.cantidad_pago,
            currentDate: dateParser(p.fecha_ejecucion),
            dateString: p.dateString,
          }),
          pago_id: p.id,
        };
      });
    }
    // TODO: check that this.debtTodayDate is greater than all previous payments to avoid errors
    return {
      debtDetail: debtDetail,
      debtToday: this._getInterest({
        currentDate: this.debtTodayDate,
        dateString: this.debtTodayDateString,
      }),
      interestRate: this.interestRate,
      startDate: this.startDate.toString(),
    };
  }
}

export default DebtCalculator;
