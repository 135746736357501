import React, { useState, useImperativeHandle, forwardRef } from 'react';
import FormSelectMaterial from '../../../nuup/componentes/Forms/FormSelectMaterial.jsx';

// eslint-disable-next-line react/display-name
const AvancesFilters = forwardRef((props, ref) => {
  const [fecha, setFecha] = useState('');
  const [inspector, setInspector] = useState(0);
  const [ubicacion, setUbicacion] = useState(0);

  //To call outside
  useImperativeHandle(ref, () => ({
    setDefaultFilters: setDefaultFiltersHandler,
  }));

  const setDefaultFiltersHandler = () => {
    setFecha('');
    setInspector(0);
    setUbicacion(0);
  };

  const handleFilters = (key, val, setFilter) => {
    setFilter(val);
    let data = props.dataAvancesTotal;
    // update data filtered
    if (val === 0 || val === '') {
      props.setDataAvances(props.dataAvancesTotal);
      setDefaultFiltersHandler(); // without subfilters
    } else {
      const filters = {
        id_localidad: ubicacion,
        id_inspector: inspector,
        inspeccion_fecha: fecha,
      };
      filters[key] = val;
      for (const [key, value] of Object.entries(filters)) {
        if (!(value === 0 || value === '' || value === null)) {
          data = data.filter(d => d[key] == value);
        }
      }
      props.setDataAvances(data);
    }
  };

  const getCatByData = list => {
    return props.dataAvances
      .map(d => {
        return {
          value: d['id_' + list],
          label: d['nombre_' + list],
        };
      })
      .filter((item, index, self) => index === self.findIndex(t => t.value === item.value))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  };

  return (
    <div className="row mt-3 mb-4 pr-2">
      <div className="col-md-3">
        <input
          type="date"
          className="form-control"
          placeholder="DD/MM/YYYY"
          defaultValue={fecha}
          value={fecha}
          onChange={e => handleFilters('inspeccion_fecha', e.target.value, setFecha)}
        />
      </div>
      <div className="col-md-4">
        <FormSelectMaterial
          id="inspector"
          error={false}
          label="Inspector"
          value={inspector}
          onChange={val => handleFilters('id_inspector', val, setInspector)}
          height={30}
          options={getCatByData('inspector')}
          showAll={true}
          showAllLabel="Todos"
        />
      </div>
      <div className="col-md-5">
        <FormSelectMaterial
          id="ubicacion"
          error={false}
          label="Ubicación"
          value={ubicacion}
          onChange={val => handleFilters('id_localidad', val, setUbicacion)}
          height={30}
          options={getCatByData('localidad')}
          showAll={true}
          showAllLabel="Todas"
        />
      </div>
    </div>
  );
});

export default AvancesFilters;
