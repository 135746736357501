import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          {payload.value}
        </text>
      </g>
    );
  }
}

export default class GraficaFisico extends PureComponent {

  getDataDefectos(data){
    const defectos = data.defectos_primarios.concat(data.defectos_secundarios);
    let dataDefectos = [];
    for (let d = 0; d < defectos.length; d++) {
      dataDefectos.push(    {
        defecto: defectos[d].tipo_mancha.nombre,
        valor: defectos[d].gramos
      });
    }
    return dataDefectos;
  }

  render() {
    const { data } = this.props;

    return (
        <LineChart
          width={(window.innerWidth / 2.75)}
          height={300}        
          data={this.getDataDefectos(data)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="defecto" height={100} tick={<CustomizedAxisTick />} />
          <YAxis type="number" /* domain={['dataMin - 10', 'dataMax + 10']} */ />
          <Tooltip />
          <Line dataKey="valor" stroke="#789D16" fill="#789D16" activeDot={{ r: 8 }} />
        </LineChart>
    );
  }
}
