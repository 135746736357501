import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Api from '../../api/global.service';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../nuup/componentes/Forms/FormInput.jsx';
import { Link, useParams } from 'react-router-dom';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';

const sharedNameForm = 'Cooperativas';
const sharedTable = 'cooperativas';
const sharedURL = '/admin/cooperativas/list';

const CooperativasCRUD = () => {
  const { uuid } = useParams();
  const [name] = useState('Cooperativas');
  const [nameForm] = useState(sharedNameForm);
  const [table] = useState(sharedTable);
  const [url] = useState(sharedURL);
  const [blocking, setBlocking] = useState(false);

  const schema = yup.object().shape({
    nombre: yup.string().required('Nombre es requerido'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      nombre: '',
      descripcion: '',
      logo: '',
      cuenta_kobo: '',
      certificacion: '',
    },
  });

  useEffect(() => {
    if (uuid != null) {
      setBlocking(true);
      Api.get(table, uuid)
        .then(response => {
          reset(response.data[0]);
          setBlocking(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('e', e);
          setBlocking(false);
        });
    }
  }, []);

  const onSubmit = values => {
    var item = {
      nombre: values.nombre,
      descripcion: values.descripcion,
      logo: values.logo,
      cuenta_kobo: values.cuenta_kobo,
      certificacion: values.certificacion,
    };
    setBlocking(true);
    if (uuid == null) {
      //CREATE
      Api.create(table, item)
        .then(response => {
          let data = response.data[0];
          setBlocking(false);
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se registró con éxito la ${nameForm.toLowerCase()} -> ${data.uuid}`,
          });
          window.location.href = url;
        })
        .catch(e => {
          setBlocking(false);
          Swal.fire({
            icon: 'error',
            title: '',
            text: e,
          });
        });
    } else {
      //EDIT
      Api.update(table, uuid, item)
        .then(response => {
          let data = response.data[0];
          setBlocking(false);
          Swal.fire({
            icon: 'success',
            title: '',
            text: `Se actualizó con éxito la ${nameForm.toLowerCase()} -> ${data.uuid}`,
          });
          window.location.href = url;
        })
        .catch(e => {
          setBlocking(false);
          Swal.fire({
            icon: 'error',
            title: '',
            text: e,
          });
        });
    }
  };

  return (
    <>
      <div>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to={url}>{name}</Link>
          </li>
          <li className="breadcrumb-item active">{uuid == null ? 'Nuevo' : 'Editar'}</li>
        </ol>
        <h1 className="page-header">
          {name} / {uuid == null ? 'Nuevo' : 'Editar'}
        </h1>

        <div className="row">
          <div className="col-md-12">
            <Panel>
              <PanelBody>
                <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12">
                      <FormInput
                        id="uuid"
                        type="hidden"
                        name="uuid"
                        label="UUID"
                        error={errors.uuid}
                        register={register}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <FormInput
                        id="nombre"
                        type="text"
                        name="nombre"
                        label="Nombre"
                        error={errors.nombre}
                        register={register}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormInput
                        id="descripcion"
                        type="text"
                        name="descripcion"
                        label="Descripción"
                        error={errors.descripcion}
                        register={register}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormInput
                        id="logo"
                        type="text"
                        name="logo"
                        label="Logo"
                        error={errors.logo}
                        register={register}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormInput
                        id="cuenta_kobo"
                        type="text"
                        name="cuenta_kobo"
                        label="Cuenta Kobo"
                        error={errors.cuenta_kobo}
                        register={register}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormInput
                        id="certificacion"
                        type="text"
                        name="certificacion"
                        label="Certificación"
                        error={errors.certificacion}
                        register={register}
                      />
                    </div>
                    <div className="col-md-3 offset-md-9">
                      <button className="btn btn-primary btn-block" type="submit">
                        Guardar
                      </button>
                    </div>
                  </form>
                </BlockUi>
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    </>
  );
};

export function deleteByUUID(uuid) {
  //DELETE
  Swal.fire({
    title: '¿Esta seguro?',
    text: 'El registro se eliminará',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, eliminar!',
  }).then(result => {
    if (result.isConfirmed) {
      Api.delete(sharedTable, uuid)
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'Se eliminó con exito el cooperativa -> ' + uuid,
          });
          window.location.href = sharedURL;
        })
        .catch(e => {
          Swal.fire({
            icon: 'error',
            title: '',
            text: e,
          });
        });
    }
  });
}

export default CooperativasCRUD;
