import React from 'react';
import IconMarker from '../icon/icon-marker';
// import LabelCategory from '../label/label-category.jsx';

class MarkerInfoWindowParcela extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      visible: false,
    };

    this.myLabel = React.createRef();
  }

  componentDidMount() {
    if (this.myLabel.current != null) {
      this.setState({
        offset: -this.myLabel.current.children[0].clientWidth / 2,
      });
    }
  }

  render() {
    const getSuperficie = () => {
      let superficie = '';
      if (this.props.superficie > 0) {
        superficie = ' - ' + this.props.superficie + ' ha';
      }
      return superficie;
    };

    return (
      <div lat={this.props?.lat} lng={this.props?.lng}>
        {this.props.selectedElement && this.props.selectedElement.id == this.props.id ? (
          <div
            visible={this.props.showInfoWindow}
            style={{
              width: 200,
              height: 60,
              background: 'white',
              fontSize: 12,
              paddingTop: 10,
              position: 'absolute',
              marginTop: '-125px',
              marginLeft: '-100px',
              zIndex: 1000,
            }}>
            <center>
              {this.props.link !== null ? (
                <a style={{ color: 'black' }} href={this.props.link} rel="noreferrer">
                  {this.props.parcela}
                </a>
              ) : (
                <>{this.props.parcela}</>
              )}
              {getSuperficie()}
              <br />
              {this.props.linkProductor !== null ? (
                <a style={{ color: 'black' }} href={this.props.linkProductor} rel="noreferrer">
                  {this.props.productor}
                </a>
              ) : (
                <>{this.props.productor}</>
              )}
            </center>
          </div>
        ) : null}

        <IconMarker
          color={this.props.color}
          onClick={() => {
            this.setState(value => ({ visible: !value.visible }));
          }}></IconMarker>
      </div>
    );
  }
}

export default MarkerInfoWindowParcela;
