import React from "react";

const FormInput = ({ register, error = false, label, id, name, onChange, ...inputProps }) => {
  return (
    <>
      <div className={(inputProps.type !== 'hidden') ? 'input-group' : ''}>

        <div className={(inputProps.type !== 'hidden') ? 'input-group-prepend' : ''}>
          {inputProps.type !== 'hidden' &&
            <i
              className="fa fa-search"
              style={{ padding: 0, margin:"3px 5px 0px 3px", color: "#ffffff" }}
            />
          }
        </div>

        {inputProps.type !== "hidden" && <label style = {{marginRight: '10px'}} htmlFor={id}>{label}</label>}
        
        {onChange ? 
        <input
        id={id}
        className={`form-control ${error ? "_is-invalid" : ""}`}
        {...inputProps}
        {...register(id)}
        onChange={onChange}
      />
          :
      <input
        id={id}
        className={`form-control ${error ? "_is-invalid" : ""}`}
        {...inputProps}
        {...register(id)}
      />
        }
      </div>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </>
  );
};
export default FormInput;
