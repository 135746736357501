import React from 'react';

class CardContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var children = this.props.children && '';
    return (
      <div className="card-container" style={{ ...this.props.style, height: this.props.height }}>
        {this.props.children}
      </div>
    );
  }
}

export default CardContainer;
