
import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { publish } from '../.././../components/events';
import SignaturePad from "react-signature-canvas";
import "./entregas-x-productor.css";

const ModalFirma = forwardRef((props, ref) => {
	
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [dibujarFirma, setDibujarFirma] = useState(true);
	const sigCanvas = useRef({});
	let canvas = document.getElementById("lienzo");
	let ctx = null;
	const clearPad = () => {
		if (sigCanvas.current !== null) {  
			sigCanvas.current.clear();
		} 
	};


	const clearCanvas = () => {
		try {
			ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		} catch (error) {
			
		}
	};

	const save = () => {

		if(dibujarFirma){
			if(!sigCanvas.current.isEmpty()){
				publish('Firma::onSave', sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
				closeHandler();
			}
		}else{
			publish('Firma::onSave', canvas.toDataURL("image/png"));
			closeHandler();
		}
	}



	//To call outside
	useImperativeHandle(ref, () => ({
		show: showHandler
	}));

	const showHandler = () => {
		clearPad();
		setShow(true);
	}

	const closeHandler = () => {
		clearPad();
		clearCanvas();
		setShow(false);
	}

	const getFontSize = (tamano) => {
		let fontSize = 30;

		if(tamano > 30){
			fontSize = 22;
		}else if(tamano <= 30 && tamano >= 25){
			fontSize = 24;
		}else if(tamano <= 24 && tamano >= 22){
			fontSize = 28;
		}else if(tamano <= 22 && tamano >= 20){
			fontSize = 30;
		}else if(tamano <= 20 && tamano >= 18){
			fontSize = 32;
		}

		return fontSize;
	}

	const writeText = (text,fontSize = 100) => {
		clearPad();
		if (canvas && canvas.getContext) {
		ctx = canvas.getContext("2d");
			if (ctx) {
				clearCanvas();
				ctx.beginPath();
				var centerX = canvas.width/2;
				ctx.textAlign="center";
				ctx.font= fontSize + "px La Belle Aurore";
				ctx.fillStyle = "black";
				//ctx.font = fontSize + 'px cursive';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'top';
				ctx.fillStyle = 'black';
				ctx.fillText(text,centerX,(fontSize > 48) ? 40 : 60);
				ctx.stroke();
			}
		}
		setDibujarFirma(false);
	  }
	
	return (
		<div className="modal" style={{ display: (show) ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
			<div className="modal-dialog" style={{width: 550, height: 325 }}>
				<div className="modal-content" style={{ height: '100%' }}>
					<div className="modal-header" style={{ flex: 'inherit' }}>
						<h4 className="modal-title">{props.title}</h4>
						<button onClick={() => {closeHandler()}} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
							<i className="fa fa-times"></i>
						</button>
					</div>
					<div className="modal-body">
						<div className='row'>
						<div className='col-md-6 col-xl-6'>
								<canvas style={{display : (dibujarFirma) ? 'none' : 'block'}} className='signatureCanvas' id='lienzo'></canvas>
								{dibujarFirma && (
									<SignaturePad penColor="black" ref={sigCanvas}canvasProps={{className: "signatureCanvas",height: 125,width: 250}}/>
								)}
							</div>
							<div className='col-md-5 col-xl-5 mt-1'>
								<label className="btn btn-outline-secondary btn-block" style={{marginLeft : 10}}>
									<span onClick={() => setDibujarFirma(true)}>Generar firma</span>
								</label>



								<label className="btn btn-outline-secondary btn-block" style={{marginLeft : 10}}>
									<span onClick={() => writeText(props.productor_nombre.toLowerCase(),getFontSize(props.productor_nombre.length))}>Usar nombre completo</span>
								</label>

								<label className="btn btn-outline-secondary btn-block" style={{marginLeft : 10}}>
									<span onClick={() => writeText(props.productor_nombre.toLowerCase().split(" ").map((n)=>n[0]).join(""),72)}>Usar iniciales</span>								
								</label>

							</div>

						</div>
					</div>
					<div className="modal-footer">

						{!blocking && (
							<>
								<button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-times"></i> Cancelar
								</button>
								<button onClick={() => {clearPad();clearCanvas()}} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-edit"></i> Limpiar
								</button>
								<button onClick={() => save()} type="button" className="btn btn-sirio" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-save"></i> Guardar
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);

});

export default ModalFirma;