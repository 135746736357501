import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import BlockUi from 'react-block-ui';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment';

const SelectCiclo = ({ className, setFilter, acopio = 0, width = '188px' }) => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [value, setValue] = useState(-1);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    setBlocking(true);

    Api.search('ciclos', {
      filter: [{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }],
      acopio: acopio,
    })
      .then(response => {
        setData(response.data);
        handlerChange(response.data.length - 1, response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  }, []);

  const handlerChange = (index, data) => {
    let obj = data[index];
    setValue(index);
    if (obj.hasOwnProperty('id')) {
      localStorage.setItem(`${env.appName}-ciclo_id`, obj.id);
      localStorage.setItem(`${env.appName}-ciclo_nombre`, obj.nombre);
      localStorage.setItem(`${env.appName}-ciclo_uuid`, obj.uuid);
    }
    setFilter({ index: index, target: obj });
  };

  return (
    <div style={{ width: width, height: '43px' }} className={className}>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        <select
          value={value}
          onChange={e => {
            handlerChange(parseInt(e.target.value), data);
          }}
          className="form-control select-ciclo">
          {data.map((option, index) => (
            <option key={index} value={index}>
              CICLO {option.nombre}
            </option>
          ))}
        </select>
      </BlockUi>
    </div>
  );
};

export default SelectCiclo;
