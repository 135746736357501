import React from 'react';

class LabelSimple extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		
		return (
			<div className="label-category" style={{backgroundColor: this.props.bgColor}}>
				<h6 className="font-lato-normal-500" style={{color: this.props.color}}>
					{this.props.label}
				</h6>
			</div>
		);
	}
	
}

export default LabelSimple;