/* eslint-disable react/display-name */
import React, { useState, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import Api from '../../api/global.service.js';
import { useAuth } from '../content/auth.jsx';
import * as yup from 'yup';
import { publish } from '../events.js';

const ModalRemanente = forwardRef((props, ref) => {
  const auth = useAuth();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [errors, setErrors] = useState(null);
  const [precioPorKg, setPrecioPorKg] = useState('Precio / kg');
  const [cantidadPago, setCantidadPago] = useState('Monto total a pagar ($)');
  const yupInput = yup
    .number()
    .required('Debe escribir una cantidad')
    .min(0, 'Ingrese un número mayor que cero 0')
    .transform(value => (isNaN(value) ? undefined : value));
  const schema = yup.object().shape({
    precio_por_kg: yupInput,
    cantidad_pago: yupInput,
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
  }));

  const showHandler = valuesData => {
    setShow(true);
    setErrors(null);
    setCantidad('precioPorKg', props.data?.precio_por_kg);
    setCantidad('cantidadPago', props.data?.cantidad_pago);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    let dataVal = {
      precio_por_kg: precioPorKg ? parseFloat(precioPorKg * 100) : null,
      cantidad_pago: cantidadPago ? parseFloat(cantidadPago * 100) : null,
    };
    schema
      .validate(dataVal, { abortEarly: false })
      .then(async function(valid) {
        // data post
        const dataCoop = { id_cooperativa: auth.user.id_cooperativa };
        const data = { ...dataCoop, ...dataVal };
        // Post request
        setBlocking(true);
        const response = await Api.Acopio.update('remanente/generar_recibos', props.data.id, data);
        setBlocking(false);
        setShow(false);
        publish('Remanente::onSave', response.data.data);
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  const setCantidad = (inputName, inputVal) => {
    if (inputName == 'precioPorKg') setPrecioPorKg(inputVal);
    else if (inputName == 'cantidadPago') setCantidadPago(inputVal);
  };

  const InputPrice = useCallback(({ label, inputName, inputVal, error, disabled }) => {
    return (
      <>
        <div className="input-group mb-2">
          <div className="input-group-prepend">
            <i className="fa fa-search" style={{ padding: 14, color: '#ffffff' }}></i>
          </div>
          <input
            type="number"
            name={inputName}
            className={'form-control bg-white' + (disabled ? ' text-muted' : '')}
            placeholder={label}
            value={inputVal}
            onChange={event => setCantidad(inputName, event.target.value)}
            disabled={disabled ? disabled : false}
          />
          <div className="input-group-append">
            <i className="fa fa-chevron-down" style={{ padding: 14, color: '#ffffff' }}></i>
            <span className={'p-5 pl-0' + (disabled ? ' text-muted' : '')}>{inputVal > 0 && label}</span>
          </div>
        </div>
        {error && <div className="invalid-feedback input-group border-0 mb-2">{error}</div>}
      </>
    );
  }, []);

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 40, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <h5 className="font-lato-normal-500">
                  ¿Quieres actualizar este pago del productor <strong>{props.data?.productor_nombre}</strong>?
                </h5>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12">
                {props.data && (
                  <>
                    <InputPrice
                      label={'Peso neto (kg)'}
                      inputName="pesoNeto"
                      inputVal={props.data.peso_neto}
                      error={null}
                      disabled={true}
                    />
                    <InputPrice label={'Precio / kg)'} inputName="precioPorKg" inputVal={precioPorKg} error={errors?.precio_por_kg} />
                    <InputPrice
                      label={'Monto total a pagar $'}
                      inputName="cantidadPago"
                      inputVal={cantidadPago}
                      error={errors?.cantidad_pago}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-outline-secondary btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalRemanente;
