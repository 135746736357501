import React from 'react';

export function CardPriceCategory({ height, value, label, unit, colors }) {
  return (
    <div className="row card-dashboard" style={{ height: height, background: colors.bgCard }}>
      <div className="col-xl-12 col-md-12">
        <div className="label-category mb-2" style={{ background: colors?.labelBg }}>
          <h6 className="font-lato-normal-600" style={{ color: colors?.label }}>
            <i className="fa fa-circle fa-xs"></i> {label}
          </h6>
        </div>
        <h4 className="font-lato-normal-400">
          {value} {unit}
        </h4>
      </div>
    </div>
  );
}

export default CardPriceCategory;
