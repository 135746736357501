import GeoJSON from 'geojson';
import tokml from 'tokml';
import download from 'downloadjs';

const transformGeoData = _dataProductores => {
  let _polygon = [];
  for (let index = 0; index < _dataProductores.length; index++) {
    _dataProductores[index].parcela_nombre = unescape(encodeURIComponent(_dataProductores[index].parcela_nombre));
    _dataProductores[index].localidad = unescape(encodeURIComponent(_dataProductores[index].localidad));
    _dataProductores[index].municipio = unescape(encodeURIComponent(_dataProductores[index].municipio));
    _dataProductores[index].cuenta_categoria = unescape(encodeURIComponent(_dataProductores[index].cuenta_categoria));
    _dataProductores[index].productor_nombre = unescape(encodeURIComponent(_dataProductores[index].productor_nombre));
    _polygon = JSON.parse(_dataProductores[index].poligono);
    _dataProductores[index].punto = [];
    if (_dataProductores[index].centroide_lat !== null && _dataProductores[index].centroide_lng) {
      _dataProductores[index].punto = [_dataProductores[index].centroide_lat, _dataProductores[index].centroide_lng];
    }
    if (_polygon !== null) {
      _dataProductores[index].poligono = _polygon.coordinates;
    }
  }
  return _dataProductores;
};

export const descargaKML = dataProductores => {
  GeoJSON.parse(
    transformGeoData(dataProductores),
    {
      Point: 'punto',
      Polygon: 'poligono',
      removeInvalidGeometries: true,
      exclude: [
        'centroide',
        'cooperativa_uuid',
        'cuenta_categoria_color',
        'id_ciclo',
        'id_cooperativa',
        'id_cuenta_categoria',
        'id_localidad',
        'parcela_uuid',
        'productor_id',
        'productor_uuid',
        'nivel_riesgo_color',
        'id_parcela',
        'poligono',
        'deleted_at',
      ],
    },
    function(geojsonObject) {
      // console.log(geojsonObject);
      let kmlNameDescription = tokml(geojsonObject, {
        name: 'parcela_nombre',
        documentName: 'Listado de parcela',
        documentDescription: 'Listado de parcelas de la cooperativa',
      });
      download(kmlNameDescription, 'padron.kml');
    }
  );
};

export default descargaKML;
