import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/content/auth.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import LabelColor from '../../components/label/label-color.jsx';
import Api from '../../api/global.service';
// import { deleteByUUID } from './cooperativas-crud.js';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

const LocalidadesList = () => {
  const auth = useAuth();

  const [name] = useState('Localidades');
  const [table] = useState('localidades');

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'CLAVE',
        accessor: 'clave_concatenada',
        sortable: true,
      },
      {
        Header: 'NOMBRE',
        accessor: 'nombre',
        sortable: true,
      },
      {
        Header: 'RIESGO',
        accessor: 'cat_localidad_riesgo',
        sortable: true,
        minWidth: 90,
        Cell: ({ row, value }) => (
          <LabelColor label={value} color={row.original.cat_localidad_riesgo_color}></LabelColor>
        ),
      },
      {
        Header: 'MUNICIPIO',
        accessor: 'municipio',
        sortable: true,
      },
      {
        Header: 'ESTADO',
        accessor: 'estado',
        sortable: true,
      },
      {
        Header: 'FECHA DE CREACIÓN',
        accessor: 'created_at',
        sortable: true,
      },
      {
        Header: 'FECHA DE ACTUALIZACIÓN',
        accessor: 'updated_at',
        sortable: true,
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'clave_concatenada', label: 'CLAVE' },
      { value: 2, target: 'nombre', label: 'NOMBRE' },
      { value: 3, target: 'cat_localidad_riesgo', label: 'RIESGO' },
      { value: 4, target: 'municipio', label: 'MUNICIPIO' },
      { value: 5, target: 'estado', label: 'ESTADO' },
      { value: 6, target: 'created_at', label: 'FECHA DE CREACIÓN' },
      { value: 7, target: 'updated_at', label: 'FECHA DE ACTUALIZACIÓN' },
    ],
    []
  );

  useEffect(() => {
    setBlocking(true);
    Api.search(table, {
      // filter: [
      // 	{column: "id", operator: ">", value: 2},
      // 	{column: "id", operator: "<", value: 5}
      // ],
      sort: [{ column: 'nombre', sort: 'asc' }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlocking(false);
      });
  }, []);

  const setDataFilter = data => {
    setData(data);
  };

  return (
    <div>
      {auth.hasAccess('LOCALIDADES') && (
        <>
          <Breadcrumb>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item active">{name}</li>
            </ol>
          </Breadcrumb>

          <CardContainer height={'100%'}>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataFilter(evt);
                  }}></SearchColumn>
              </div>
            </div>

            {auth.hasPermission('LOCALIDADES_VER') && (
              <NoPagination blocking={blocking} columns={columns} data={data}></NoPagination>
            )}
          </CardContainer>
        </>
      )}
    </div>
  );
};

export default LocalidadesList;
