import React, { useState, useEffect, createRef, useRef } from 'react';
import CardContainer from '../../../components/card/card-container.jsx';
import Api from '../../../api/global.service';
import { useAuth } from '../../../components/content/auth.jsx';
import { subscribe, unsubscribe } from '../../../components/events';
import "./entregas-x-productor.css";
import Swal from 'sweetalert2';
import Moment from 'moment';

import ModalFirma from './modal-firma.jsx';
import { publish } from '../../../components/events.js';
import formatNumber from '../../../utils/formatNumber.js';

const EntregasXProductorFirma = ( { pagos, data, cuenta } ) => {
  const auth = useAuth();
  const [blocking, setBlocking] = useState(true);
  const dialog = createRef();
  const [imageURL, setImageURL] = useState(null);


  const handleFirma = event => {
      setImageURL(event.detail)
  };


  useEffect(() => {
    subscribe('Firma::onSave', e => handleFirma(e));
    return () => {
      unsubscribe('Firma::onSave');
    };
  }, []);


  const enviarDatos = () => {
    const formData = new FormData();
    formData.append('firma_image', imageURL);
    for (var key in data) {
      let v = data[key];
      if (v instanceof Date) {
        v = Moment(v).format('yyyy-MM-DD');
      } else if (v instanceof Array) {
        v = JSON.stringify(v);
      } else if (key === 'id_metodo_pago') {
        v = v.value;
      }
      formData.append(key, v);
    }


    Api.createMultiPart('admin/acopios/entregas/pago_pendiente', formData)
    .then(response => {
      let dataResponse = response.data[0];
      setBlocking(false);
      Swal.fire({
        icon: 'success',
        title: '',
        text: `Se registró con éxito la entrega`,
      });

      setTimeout(() => {
        window.location.href = `/admin/acopio/entregas_por_productor/list`;
    }, 1000);
    })
    .catch(e => {
      console.log(e);
      setBlocking(false);
      Swal.fire({
        icon: 'error',
        title: '',
        text: e.response.data.message,
      });
    });

  }

  const showForm = () => {
    publish('Entrega::onReturn');
  }

  return (
    <div>
      <CardContainer height={'100%'}>
        <ModalFirma ref={dialog} title="Generar firma" productor_nombre={cuenta?.productor_nombre} />
        <div className="row">

        <div className="col-xl-8 col-lg-12">
              <p><span className="resumen-entrega-titulo">{"Resumen de pago pendiente"}</span> <span style={{marginLeft : 10, color : '#789D16'}} onClick={() => showForm()}>{'< Volver a datos de pago'}</span></p>

            <div className='pt-3'>
              <div className='row'>
                <div className='col-xl-12 resumen-entrega-subtitulo'>
                  {'Pago'}
                </div>
              </div>


              <div className='row'>
                <div className='col-xl-8 col-lg-6 resumen-entrega-elemento'>
                  {'Monto a pagar'}
                </div>
                <div className='col-xl-4 col-lg-6 resumen-entrega-valor'>
                  {'$ ' + formatNumber(data.monto_pagado)}
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-8 col-lg-6 resumen-entrega-elemento'>
                  {'Monto pagado'}
                </div>
                <div className='col-xl-4 col-lg-6 resumen-entrega-valor'>
                  {'$ ' + formatNumber(((pagos?.pagos_pagado_total || 0) + formatNumber(data.monto_pagado)))}
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-8 col-lg-6 resumen-entrega-elemento'>
                  {'Método de pago'}
                </div>
                <div className='col-xl-4 col-lg-6 resumen-entrega-valor'>
                  {(data.id_metodo_pago.label || '')}
                </div>
              </div>

            </div>


            <div className='pt-3'>
              <div className='row'>
                <div className='col-xl-12 resumen-entrega-subtitulo pt-4'>
                  {'Firma de conformidad'}
                </div>
                <div className='col-xl-12 resumen-entrega-firma'>
                  <>
                    {imageURL ? (
                      <img
                        src={imageURL}
                        alt="Firma generada"
                        style={{
                          display: "block",
                          margin: "0 auto",
                          width: "300px",
                          height: "150px"
                        }}
                      />
                    ) : null}
                  </>
                </div>
                <p className='resumen-entrega-firmante'>{cuenta?.productor_nombre}</p>
                <br />
              </div>
            </div>
            <button onClick={() => {dialog.current.show();setImageURL(null)}} style={{ marginTop: 0 }} className="btn btn-outline-secondary">Generar firma</button>
          </div>
        </div>

        <div className='row'>
          <div className='col-xl-12 resumen-entrega-subtitulo pt-4'>
            <button disabled={imageURL ? false : true} onClick={() => {enviarDatos()}} style={{ marginTop: 0 }} className="btn btn-outline-secondary btn-sirio">Guardar</button>
          </div>
        </div>

      </CardContainer>
    </div>
  );
};

export default EntregasXProductorFirma;
