import React, {useState} from 'react';
import TextField from '@mui/material/TextField';

const FormInputMaterial = (props) => {
  const [shrink, setShrink] = useState(false);
  const height = 44;
  return (
    <>
        <TextField
          id={props.id}
          className={`form-control ${props.error ? "_is-invalid" : ""}`}
          label={props.label || props.placeholder}
          variant={props.variant || "outlined"}
          error={props.error.message}
          onFocus={() => setShrink(true)}
          onChangeCapture={(e) => {setShrink(!!e.target.value);props.onChangeCapture(e)}}
          style={{ height }}
          InputLabelProps={{
            shrink: !!props.value || shrink,
            style: {
              height
            },
          }}
      
          /* styles the input component */
          inputProps={{
              style: {
                height,
                padding: '0 10px',
              },
          }}

        />
      {props.error && <div className="invalid-feedback">{props.error.message}</div>}
    </>
  );
};
export default FormInputMaterial;
