import React, { useState, useEffect } from 'react';
import moment from 'moment/moment.js';
import xlsx from 'json-as-xlsx';
import LabelNumber from '../../../components/label/label-number.jsx';
import LabelDate from '../../../components/label/label-date.jsx';
import LabelColor from '../../../components/label/label-color.jsx';
import { useAuth } from '../../../components/content/auth.jsx';
import env from '../../../environment.js';
import NoPagination from '../../../components/pagination/noPagination.jsx';
import SearchColumn from '../../../components/search/search-column.jsx';

const EntregaEfectivoPorCentroAcopioTable = ({ originalData, blocking, onClickEdit, getBackgroundColor, getColor, ciclo }) => {
  const auth = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataFilter(originalData);

    return () => {
      setDataFilter([]);
    };
  }, [originalData]);

  const baseColumns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN GENERAL',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            sortable: true,
            minWidth: 50,
          },
          {
            Header: 'CENTRO DE ACOPIO',
            accessor: 'centro_acopio.nombre',
            sortable: true,
            minWidth: 150,
          },
          {
            Header: 'FECHA',
            accessor: 'fecha_envio',
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return <LabelDate value={value}></LabelDate>;
            },
          },
          {
            Header: 'DESEMBOLSO EFECTIVO',
            accessor: 'monto',
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
            },
          },

          {
            Header: 'MOTIVO DESEMBOLSO DE EFECTIVO',
            accessor: 'motivo',
            sortable: true,
            minWidth: 100,
            Cell: ({ value, row }) => {
              let v = value;
              if (v != null) return <LabelColor label={value} color={row.original.motivo_color} />;
              else return '';
            },
          },
          {
            Header: 'NOMBRE DE ACOPIADOR',
            accessor: 'usuario_acopiador.nombre',
            sortable: true,
            minWidth: 200,
          },
          {
            Header: 'REGISTRO',
            accessor: 'usuario_registro',
            sortable: true,
            minWidth: 350,
            Cell: ({ value }) => {
              if (value != null) return `Registrado por ${value.nombre} el ${value.created_at.substr(0, 10)}`;
              else return '';
            },
          },
          // {
          //   Header: 'RECIBO',
          //   accessor: 'recibo_pdf',
          //   sortable: true,
          //   minWidth: 50,
          //   Cell: ({ value }) => {
          //     let v = value;
          //     if (v != null && v != '')
          //       return (
          //         <a href={`${env.assetURL}/${v}`} target="_blank" rel="noreferrer">
          //           <img src={env.images.icon.pdf} className="pr-1" /> <span>Descargar</span>
          //         </a>
          //       );
          //     else return '';
          //   },
          // },
          {
            Header: '',
            accessor: 'edition',
            sortable: true,
            minWidth: 50,
            Cell: ({ row }) => {
              let v = row.original;
              if (v != null && auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_ELIMINAR'))
                return (
                  <a onClick={e => onClickEdit(e, v)} className="text-success m-r-2">
                    <span className="text-success">
                      <i className="fa fa-pencil-alt"></i> Editar
                    </span>
                  </a>
                );
              else return '';
            },
          },
        ],
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'id', label: 'id' },
      { value: 2, target: 'monto', label: 'DESEMBOLSO EFECTIVO' },
      { value: 3, target: 'motivo', label: 'MOTIVO' },
      // { value: 4, target: 'usuario_acopiador.nombre', label: 'NOMBRE DE ACOPIADOR' },
      // { value: 5, target: 'usuario_registro.nombre', label: 'NOMBRE DE REGISTRADOR' },
    ],
    []
  );

  const setDataFilter = data => {
    setData(data);
  };

  const exportarXlsx = () => {
    let xlsData1 = [
      {
        sheet: 'Movimientos',
        columns: [
          { label: 'ID', value: 'id' },
          { label: 'CENTRO DE ACOPIO', value: 'centro_acopio.nombre' },
          { label: 'FECHA', value: 'fecha_envio' },
          { label: 'DESEMBOLSO EFECTIVO', value: 'monto', format: '$#,##0.00' },
          { label: 'MOTIVO', value: 'motivo' },
          { label: 'NOMBRE DE ACOPIADOR', value: 'usuario_acopiador.nombre' },
          { label: 'NOMBRE DE REGISTRADOR', value: 'usuario_registro.nombre' },
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `AcopiosPorProductor_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData1, settings);
  };

  return (
    <>
      <div className="row">
        <div className="col-10">
          <SearchColumn
            data={originalData}
            columns={searchColumns}
            setDataFilter={evt => {
              setDataFilter(evt);
            }}></SearchColumn>
        </div>
        <div className="col-2">
          {auth.hasPermission('ACOPIOS_ENTREGAS_EFECTIVO_EXPORTAR') && (
            <button onClick={() => exportarXlsx()} className={`btn`} type="button">
              <img src={env.images.icon.xls} className="pr-1" />
              <p style={{ color: '#789D16' }}>Descargar Excel</p>
            </button>
          )}
        </div>
      </div>

      <NoPagination blocking={blocking} selected="id" columns={baseColumns} data={data} fnBackgroundColor={getBackgroundColor} fnColor={getColor}></NoPagination>
    </>
  );
};

export default EntregaEfectivoPorCentroAcopioTable;
