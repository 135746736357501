import React, { useState, useEffect } from "react";
import env from "../../../environment";
import Api from '../../../api/global.service';
import BlockUi from "react-block-ui";

const ParcelasResumenTotalesHas = (props) => {
  const [data, setData] = useState([]);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    if (props.id_ciclo > 0) { getData(); }
  }, [props.id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/parcelas/totales', {
      filter: [{ column: 'id_ciclo', operator: '=', value: props.id_ciclo }]
    }).then(response => {
      setData(response.data);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  }

  return (
    <BlockUi
      tag="div"
      message=""
      blocking={blocking}
      renderChildren={true}
    >
      <div className="col-xl-12 col-md-12">
        <div
          className="row card-category-dashboard"
          style={{ height: 167  }}
        >
          <div className="col-xl-12 col-md-12">
            <span className="dashboard-titulo-parcelas-has">
              {"Total has de café"}
            </span>
            <span className="dashboard-total-parcelas-has">{data[0]?.total_has_cafe}</span>
          </div>
        </div>
        <div
          className="row card-category-dashboard"
          style={{ height: 167  }}
        >
          <div className="col-xl-12 col-md-12">
            <span className="dashboard-titulo-parcelas-has">
              {"Has promedio de parcelas"}
            </span>
            <span className="dashboard-total-parcelas-has">{data[0]?.promedio_has_cafe}</span>
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default ParcelasResumenTotalesHas;
