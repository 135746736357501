import React from 'react';

class LabelColor extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		
		return (
			<div className="label-category" style={{width: "100%", backgroundColor: this.props.color + '22'}}>
				<h6 className="font-lato-normal-500" style={{color: this.props.color}}>
					{this.props.label}
				</h6>
			</div>
		);
	}
	
}

export default LabelColor;