import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import LabelCategory from '../../label/label-category.jsx';
import Api from '../../../api/global.service';
import mapChartData from '../../../utils/mapChartData.js';
import renderCustomizedLabel from '../../../utils/renderCustomizedLabel.js';
import BlockUi from 'react-block-ui';

const CuentasResumenCategorias = props => {
  const [blocking, setBlocking] = useState(true);
  const [dataGrafica, setDataGrafica] = useState([]);

  useEffect(() => {
    if (props.id_ciclo > 0) {
      getData();
    }
  }, [props.id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/productores/categorias', {
      filter: [{ column: 'id_ciclo', operator: '=', value: props.id_ciclo }],
    }).then(response => {
      let chartData = mapChartData(response.data, 'cuenta_categoria', 'total', 'cuenta_categoria_color');
      setDataGrafica(chartData);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <div className="row" style={{ marginTop: 15 }}>
        <div className="col-xl-3 col-md-3 offset-1">
          <table>
            <tbody>
              {dataGrafica.map((entry, index) => (
                <tr key={`row-${index}`}>
                  <td style={{ border: 'none' }}>
                    <LabelCategory label={entry.name} color={entry.color} />
                  </td>
                  <td className="dashboard-valores" style={{ border: 'none' }}>
                    {entry.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-xl-6 col-md-6 offset-2">
          <PieChart width={240} height={240}>
            <Pie
              data={dataGrafica}
              cx={95}
              cy={95}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
              isAnimationActive={false}>
              {dataGrafica.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      </div>
    </BlockUi>
  );
};

export default CuentasResumenCategorias;
