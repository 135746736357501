import React, { useState, useEffect } from 'react';

import { BarChart, Bar, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Api from '../../../api/global.service';
import { getCategoriasOtrasActividades } from '../../../utils/mapChartData.js';
import BlockUi from 'react-block-ui';

const ParcelasResumenOtrasActividades = ({ id_ciclo }) => {
  const [dataGrafica, setDataGrafica] = useState([]);
  const [blocking, setBlocking] = useState(true);
  const categoriasGrafica = getCategoriasOtrasActividades();
  useEffect(() => {
    if (id_ciclo > 0) {
      getData();
    }
  }, [id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/parcelas/otras', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    }).then(response => {
      setDataGrafica(response.data);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div
          style={{
            maxWidth: 300,
            height: 40,
            backgroundColor: 'white',
            borderColor: '#666',
            borderWidth: 1,
            borderStyle: 'solid',
          }}>
          <p style={{ padding: 10 }}>
            {payload[0].dataKey} : {payload[0].value + ' %'}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <div className="row card-category-dashboard" style={{ height: 350 }}>
        <p className="dashboard-titulo-grafica">Otras actividades de producción:</p>
        <div className="col-xl-12 col-md-12">
          <ResponsiveContainer width="100%" height={270}>
            <BarChart data={dataGrafica} width={500} height={false}>
              <Tooltip shared={false} content={<CustomTooltip />} />
              {categoriasGrafica.map(function(cat, index) {
                return <Bar key={index} dataKey={cat.label} fill={cat.color}></Bar>;
              })}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </BlockUi>
  );
};

export default ParcelasResumenOtrasActividades;
