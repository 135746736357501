
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

const ModalPadronAltasBajasCancelar = forwardRef((props, ref) => {
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [errors, setErrors] = useState(null);

	//To call outside
	useImperativeHandle(ref, () => ({
		open: openHandler,
		close: closeHandler
	}));


	const openHandler = () => {
		setShow(true);
		setErrors(null);
	}

	const closeHandler = () => {
		setShow(false);		
	}

	const validateHandler = () => {
		props.onConfirm();
	}

	return (												
		<div className="modal" style={{display: (show) ? 'block': 'none', backgroundColor: 'rgba(0,0,0,0.2)'}}>
			<div className="modal-dialog" style={{top: 50, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)'}}>
				<div className="modal-content" style={{height: '100%'}}>
					<div className="modal-header" style={{flex: 'inherit'}}>
						<h4 className="modal-title">{props.title}</h4>
						<button onClick={() => closeHandler()} type="button" className="btn" style={{borderRadius: '20px 20px 20px 20px'}}>
							<i className="fa fa-times"></i>
						</button>
					</div>
					<div className="modal-body">
					<h5>{'Los registros de ' + props.type + ' seleccionados se elimináran. ¿Desea continuar?'}</h5>

						{ blocking && (
							<div className="spinner-border text-warning" role="status">
								<span className="sr-only">Guardando...</span>
							</div>
						)}
						{ !blocking && (
							<>
								<button onClick={() => validateHandler()} type="button" className="btn btn-block btn-sirio" style={{borderRadius: '20px 20px 20px 20px',marginTop : 20, backgroundColor : '#93000A'}}>
									<span style={{color : 'white'}}>Si, cancelar</span>
								</button>
							</>
						)}					
					</div>
				</div>
			</div>
		</div>
	);
});

export default ModalPadronAltasBajasCancelar;