import React from 'react';

export const EditLink = ({ onClickEdit, column, updated, children }) => {
  const updatedClass = Array.isArray(updated) && updated.includes(column) ? 'updated' : '';
  return (
    <div onClick={onClickEdit} className={`edit_hover_class ${updatedClass}`}>
      {children}
      <i className="fa fa-pencil-alt" style={{ color: '#507736', marginRight: '10px' }}></i>
    </div>
  );
};

export default EditLink;
