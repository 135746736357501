import React, { useState, useEffect } from 'react';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';
import { Colors } from '../../../constants';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Text, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { Panel } from '../../panel/panel';
import LabelNumber from '../../label/label-number';
import transformWord from '../../../utils/transformWord';

const EstimacionesMunicipios = ({ idCiclo, usaSic, producto }) => {
  const [isLocalidades, setIsLocalidades] = useState(false);
  const [blockingMultiBar, setBlockingMultiBar] = useState(true);
  const [dataGraficaMunicipios, setDataGraficaMunicipios] = useState([]);
  const [dataGraficaLocalidades, setDataGraficaLocalidades] = useState([]);
  const [totalEstimacion, setTotalEstimacion] = useState(null);
  const [totalEstimacionLocalidad, setTotalEstimacionLocalidad] = useState(null);

  const getAvanceMunicipios = id_ciclo => {
    setBlockingMultiBar(true);
    Api.search('dashboard/avances/kgs/municipios', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    })
      .then(response => {
        setBlockingMultiBar(false);
        let dataResponse = response.data;
        const totalEst = dataResponse.reduce((acc, current) => acc + Number(current.total_esperado), 0);
        setDataGraficaMunicipios(dataResponse);
        setTotalEstimacion(totalEst);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingMultiBar(false);
      });
  };

  const getAvanceLocalidades = id_municipio => {
    setBlockingMultiBar(true);
    Api.search('dashboard/avances/kgs/localidades', {
      filter: [
        { column: 'id_ciclo', operator: '=', value: idCiclo },
        { column: 'id_municipio', operator: '=', value: id_municipio },
      ],
    })
      .then(response => {
        setBlockingMultiBar(false);
        let dataResponse = response.data;
        const totalEst = dataResponse.reduce((acc, current) => acc + Number(current.total_esperado), 0);
        setDataGraficaLocalidades(dataResponse);
        setIsLocalidades(true);
        setTotalEstimacionLocalidad(totalEst);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingMultiBar(false);
      });
  };

  useEffect(() => {
    if (idCiclo) {
      setIsLocalidades(false);
      setBlockingMultiBar(true);
      getAvanceMunicipios(idCiclo);
    }
  }, [idCiclo]);

  const handleBarClick = e => {
    let currentPayload = e.activePayload[0].payload;
    getAvanceLocalidades(currentPayload.id_municipio);
  };

  const valueAccessor = attribute => ({ payload }) => {
    let value = payload[attribute];
    if (value > 0) {
      if (!isLocalidades) {
        if (value > 10) {
          return value;
        } else {
          return '';
        }
      } else {
        return value;
      }
    } else {
      return '';
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      const name = payload[0].payload.localidad ?? payload[0].payload.municipio;
      return (
        <div
          style={{
            backgroundColor: 'white',
            borderColor: '#cacaca',
            borderWidth: 1,
            borderStyle: 'solid',
            padding: 10,
          }}>
          <h5>{name}</h5>
          <p className="mb-1">
            Total Estimado: <b>{payload[0].payload.total_esperado}</b>
          </p>
          <p className="mb-1">
            Acopiado: <b>{payload[0].payload.total_levantado}</b>
          </p>
          <p>
            Pendiente por Acopiar: <b>{payload[0].payload.total_pendiente}</b>
          </p>
        </div>
      );
    } else {
      // console.log('payload', payload);
    }
    return null;
  };

  return (
    <div className="row card-table">
      <div className="col-xl-12 pb-4">
        <div className="row">
          <div className="col-xl-12">
            <Panel>
              {/* header title and controls chart */}
              <div className="row">
                <div className="col-xl-12">
                  <h6 className="font-lato-normal-400">
                    ENTREGAS DE {transformWord(producto, ['singular', 'uppercase'])} {usaSic ? 'Y ESTIMACIÓN DE COSECHA ' : ''} POR
                    MUNICIPIO Y LOCALIDAD
                  </h6>
                  {!isLocalidades && (
                    <a
                      className="font-lato-normal-400"
                      onClick={() => setIsLocalidades(false)}
                      style={{ color: Colors.gray_base, fontSize: 14, textDecoration: 'none' }}>
                      {`Haz click en un municipio para ver las entregas ${usaSic ? ' y estimación' : ''} de cada comunidad`}
                    </a>
                  )}

                  {isLocalidades && (
                    <a
                      onClick={() => setIsLocalidades(false)}
                      style={{ color: Colors.primary_500, fontSize: 14 }}
                      className="float-right btn btn-link">
                      {'<= Todos los municipios'}
                    </a>
                  )}
                </div>
              </div>
              <BlockUi tag="div" message="" blocking={blockingMultiBar} renderChildren={true}>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={isLocalidades ? dataGraficaLocalidades : dataGraficaMunicipios} onClick={handleBarClick}>
                    <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
                    <XAxis
                      dataKey={isLocalidades ? 'localidad' : 'municipio'}
                      angle={-45}
                      textAnchor="end"
                      height={120}
                      padding={{ top: 80, left: 15, right: 15 }}
                    />
                    <XAxis angle={0} dataKey={isLocalidades ? 'localidad' : 'municipio'} />
                    <YAxis
                      tick={false}
                      padding={{ top: 20 }}
                      label={
                        <Text x={0} y={0} dx={-180} dy={40} textAnchor="start" width={400} transform="rotate(-90)">
                          {`Kgs de ${transformWord(producto, ['lowercase'])}`}
                        </Text>
                      }
                    />
                    <Tooltip shared={true} content={<CustomTooltip />} />
                    <Legend />
                    <Bar
                      legendType={'none'}
                      name="Acopiado"
                      radius={5}
                      barSize={150}
                      dataKey="total_levantado"
                      stackId="a"
                      fill={isLocalidades ? Colors.primary_600 : '#C7AE34'}>
                      <LabelList
                        position={'insideBottom'}
                        style={{ textAnchor: 'middle', fontSize: '14', fill: Colors.white }}
                        valueAccessor={valueAccessor('total_levantado')}
                      />
                    </Bar>
                    {usaSic ? (
                      <Bar
                        legendType={'none'}
                        name="Estimado por acopiar"
                        radius={5}
                        barSize={150}
                        dataKey="total_esperado"
                        stackId="a"
                        fill={isLocalidades ? '#d1d6c6' : '#ebe8d5'}>
                        <LabelList
                          position={'top'}
                          style={{ textAnchor: 'middle', fontSize: '14', fill: Colors.black }}
                          valueAccessor={valueAccessor('total_esperado')}
                        />
                      </Bar>
                    ) : null}
                  </BarChart>
                </ResponsiveContainer>
                <div className="row card-table" style={{ height: 125, marginLeft: -30 }}>
                  {usaSic ? (
                    <div className="col-xl-9 col-md-9 text-right">
                      <h6 className="font-lato-normal-600">
                        <span style={{ fontWeight: 'normal' }}>{'ESTIMACIÓN TOTAL DE COSECHA'}</span>
                      </h6>
                    </div>
                  ) : null}
                  <div className="col-xl-3 col-md-3 text-left">
                    <h6 className="font-lato-normal-600">
                      <span style={{ fontSize: 22 }}>
                        <LabelNumber value={isLocalidades ? totalEstimacionLocalidad : totalEstimacion} digits={0} />
                      </span>{' '}
                    </h6>
                  </div>
                </div>
              </BlockUi>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimacionesMunicipios;
