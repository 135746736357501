import React from 'react';
import env from '../../environment';
import { Link } from 'react-router-dom';

export default function NoPage404() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignIitems: 'center',
        justifyContent: 'center',
        borderWidth: '1px',
        borderColor: 'red',
      }}>
      <h1 className="login-title-text  mt-5">¿Perdido/a? Esta página no existe</h1>
      <Link to={{ pathname: `/dashboard` }} className="m-r-2" style={{ color: '#789D16' }}>
        <h3 className="login-title-text mt-5">{`<`} Regresar al inicio</h3>
      </Link>
      <img
        src={env.images.icon.lost}
        style={{
          height: '475px',
          position: 'absolute',
          bottom: '0px',
          left: '250px',
        }}
      />
    </div>
  );
}
