import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import env from '../environment';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (env.appEnv === 'prod') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
};

export default usePageTracking;
