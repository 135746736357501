import React, { useState, useEffect } from 'react';

const SelectColumn = ({ columns, setFilter, selectClasses }) => {
  const [value, setValue] = useState(-1);

  useEffect(() => {
    handlerChange(0, columns);
  }, []);

  const handlerChange = (index, columns) => {
    let obj = columns[index];
    setValue(index);
    setFilter({ index: index, target: obj });
  };

  return (
    <select
      value={value}
      onChange={e => {
        handlerChange(parseInt(e.target.value), columns);
      }}
      className={"mr-4 form-control " + selectClasses}>
      {columns.map((option, index) => (
        <option key={index} value={index}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectColumn;
