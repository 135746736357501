export const formatValues = obj => {
  if (obj instanceof Date) {
    const year = obj.getFullYear();
    const month = String(obj.getMonth() + 1).padStart(2, '0');
    const day = String(obj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  } else if (obj instanceof Array) {
    return obj.map(item => formatValues(item));
  } else if (obj instanceof Object) {
    const formattedObj = {};
    for (let key in obj) {
      formattedObj[key] = formatValues(obj[key]);
    }
    return formattedObj;
  }
  return obj;
};
