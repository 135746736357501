const Fonts = {
  //FORUM
  forum_normal_400: {
    fontFamily: 'forum',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  //LATO
  lato_normal_400: {
    fontFamily: 'lato',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  lato_normal_500: {
    fontFamily: 'lato',
    fontStyle: 'normal',
    fontWeight: '500',
  },
  //OPEN SANS
  open_sans_normal_400: {
    fontFamily: 'open-sans',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  //ROBOTO
  roboto_normal_400: {
    fontFamily: 'roboto',
    fontStyle: 'normal',
    fontWeight: '400',
  }
}

export { Fonts };
