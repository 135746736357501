import React, { useState, useEffect } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';

import SicLinkTable from '../../components/tables/sic-link-table.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import SicLinkCuentasTable from '../../components/tables/sic-link-cuentas-table.jsx';
import toggleIds from '../../utils/toggleIds.js';
import SearchColumn from '../../components/search/search-column.jsx';

const VincularSicListP2 = () => {
  const location = useLocation();
  const history = useHistory();
  const [dataCuentas, setDataCuentas] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [selectedCuentasIds, setSelectedCuentasIds] = useState([]);
  const [showNew, setShowNew] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setBlocking(true);
    Api.create('admin/sic/cuentas_por_vincular', { showNew })
      .then(response => {
        setDataCuentas(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
        // eslint-disable-next-line no-console
        console.log('e', e);
      });
  }, [refreshKey]);

  const navigateToNextStep = () => {
    if (selectedCuentasIds.length === 1) {
      const selected = dataCuentas.filter(it => selectedCuentasIds.includes(it.id_cuenta));
      history.push({
        pathname: '/admin/vincular-sic/list-p3',
        state: { cuentas: selected, forms: location.state.forms },
      });
    }
  };

  const navigateToPreviousStep = () => history.goBack();

  const toggleFilter = () => {
    setShowNew(filt => !filt);
    setRefreshKey(oldKey => oldKey + 1);
  };

  const [originalData, setOriginalData] = useState([]);

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'NOMBRE' },
      { value: 2, target: 'nombre_coop', label: 'COOPERATIVA' },
      { value: 3, target: 'cuenta_region', label: 'REGIÓN' },
      { value: 5, target: 'municipio', label: 'MUNICIPIO' },
      { value: 6, target: 'localidad', label: 'LOCALIDAD' },
      { value: 7, target: 'fecha_ingreso', label: 'FECHA DE INGRESO' },
    ],
    []
  );

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">Formularios Seleccionados</h3>
          </div>
          <div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkTable title="Formularios" data={location.state.forms} />
              </div>
            </div>
          </div>
          <div className="row"></div>
          <div className="row">
            <h2 className="col-xl-12 font-lato-normal-400">
              PASO 2: Elige el productor a vincular con los formularios seleccionados
            </h2>
          </div>
          <div>
            <div className="row">
              <div className="row">
                <div className="col-12 float-right mt-3 mb-3">
                  <button
                    onClick={toggleFilter}
                    type="submit"
                    className={`float-xl-right btn ${showNew ? 'btn-light' : 'btn-sirio'} `}>
                    {showNew ? 'Mostrar todos' : 'Mostrar nuevos solamente'}
                  </button>
                </div>
              </div>

              <div className="col-12">
                <SearchColumn
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataCuentas(evt);
                  }}
                />
              </div>
              <div className="col-xl-12">
                <SicLinkCuentasTable
                  title="Cuentas Nuevas"
                  blocking={blocking}
                  data={dataCuentas}
                  toggleIds={toggleIds}
                  setter={setSelectedCuentasIds}
                  linkable
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                {/*
									// TODO: crear lógica de permiso
									 {auth.hasPermission('VINCULAR_SIC') && (
									 */}
                <button
                  onClick={navigateToPreviousStep}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  <i className="fa fa-arrow-left"></i> Regresar a paso anterior
                </button>
              </div>
              <div className="col-4">
                {/*
									// TODO: crear lógica de permiso
									 {auth.hasPermission('VINCULAR_SIC') && (
									 */}
                <button
                  onClick={navigateToNextStep}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                    borderColor: selectedCuentasIds.length > 1 ? 'red' : 'black',
                    color: selectedCuentasIds.length > 1 ? 'red' : 'black',
                  }}
                  disabled={selectedCuentasIds.length !== 1}>
                  {selectedCuentasIds.length > 1 ? '  No puedes elegir más de un productor' : '  Siguiente paso '}
                  <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicListP2;
