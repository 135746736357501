import * as React from "react"

class IconCircle extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		
    var width = this.props.width || 23;
    var height = this.props.height || 23;
		return (
      <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.412109 11.8371C0.412109 5.82943 5.28793 0.953613 11.2956 0.953613C17.3033 0.953613 22.1792 5.82943 22.1792 11.8371C22.1792 17.8449 17.3033 22.7207 11.2956 22.7207C5.28793 22.7207 0.412109 17.8449 0.412109 11.8371ZM11.2956 18.3673C14.8981 18.3673 17.8258 15.4396 17.8258 11.8371C17.8258 8.23469 14.8981 5.30703 11.2956 5.30703C7.69319 5.30703 4.76552 8.23469 4.76552 11.8371C4.76552 15.4396 7.69319 18.3673 11.2956 18.3673Z" fill="white"/>
      </svg>
		);
	}
	
}

export default IconCircle;