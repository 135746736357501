import React from 'react';
import { Panel, PanelHeader } from './panel.jsx';

const PanelStat = React.createContext();

class PanelCollapse extends React.Component {

	constructor(props) {
		super(props);
		this.toggleCollapse = () => {
			this.setState(state => ({
				collapse: !this.state.collapse
			}));
		}				
		
		this.state = {
			collapse: false,			
			toggleCollapse: this.toggleCollapse
		}
	}

	render() {
		return (
			<PanelStat.Provider value={this.state}>
				<Panel>
					<PanelHeader noButton={true} style={{color: '#FFFFFF', backgroundColor: '#789D16'}}>
						{this.props.title}
						<PanelStat.Consumer>
							{({ toggleCollapse }) => (
								<span className="ml-auto pull-right" onClick={toggleCollapse}>
									{this.state.collapse && (
										<i className="fa fa-sort-down f-s-16" style={{cursor: 'pointer'}}></i>
									)}
									{!this.state.collapse && (
										<i className="fa fa-sort-up f-s-16" style={{cursor: 'pointer'}}></i>
									)}
								</span>
							)}
						</PanelStat.Consumer>
					</PanelHeader>
					{!this.state.collapse && (
							<>
								{ this.props.children }
							</>
					)}	
				</Panel>		
			</PanelStat.Provider>	
		);
	}
};

export { PanelCollapse };
