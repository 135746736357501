import React from 'react';

class LabelCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="label-category" style={{ backgroundColor: this.props.color + '22' }}>
        <h6 className="font-lato-normal-500" style={{ color: this.props.color }}>
          <i className="fa fa-circle"></i> {this.props.label}
        </h6>
      </div>
    );
  }
}

export default LabelCategory;
