import React, { useMemo } from 'react';
import NoPagination from '../pagination/noPagination';
import { PanelCollapse } from '../panel/panel-collapse';
const SicLinkTableParcela = ({ blocking, data, toggleIds = () => {}, setter = () => {}, title, linkable = false }) => {
  const columnsDataPP = useMemo(
    () => [
      {
        Header: '',
        accessor: 'x',
        minWidth: 50,
        Cell: ({ row }) =>
          linkable ? (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={e => toggleIds(row.original.id, e.target, setter)}
              />
            </div>
          ) : null,
      },
      {
        Header: 'Nombre Parcela',
        accessor: 'nombre',
        sortable: false,
      },
      {
        Header: 'Superficie',
        accessor: 'superficie',
        sortable: false,
      },
      {
        Header: 'Plantas',
        accessor: 'plantas',
        sortable: false,
      },
      {
        Header: 'Dueñx de parcela',
        accessor: 'nombre_productor',
        sortable: false,
      },
      {
        Header: 'Cooperativa',
        accessor: 'nombre_coop',
        sortable: false,
      },
    ],
    ['linkable', 'setter', 'toggleIds']
  );

  return (
    <PanelCollapse title={title}>
      <NoPagination blocking={blocking} columns={columnsDataPP} data={data}></NoPagination>
    </PanelCollapse>
  );
};

export default SicLinkTableParcela;
