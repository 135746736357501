import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
// import FormMinSelectApi from '../../nuup/componentes/Forms/FormMinSelectApi.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from 'yup';
import { publish } from '../events';
import Moment from 'moment';
import ModalGeneral from './modal-general';
import { usaCentrosAcopios, useCoopConfig, getIdCatCreditoPrecosecha } from '../../components/content/coop-config.jsx';
import FormInputMaterial from '../../nuup/componentes/Forms/FormInputMaterial.jsx';
import FormSelectMaterial from '../../nuup/componentes/Forms/FormSelectMaterial.jsx';
import FormSelectMaterialApi from '../../nuup/componentes/Forms/FormSelectMaterialApi.jsx';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const ModalEfectivo = ({ title }, ref) => {
  const auth = useAuth();

  const coopConfig = useCoopConfig();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [fecha, setFecha] = useState(null);
  const [id, setId] = useState(0);
  const [idCentroAcopio, setIdCentroAcopio] = useState(0);
  const [cantidad, setCantidad] = useState('');
  const [tipoMotivo, setTipoMotivo] = useState(0);
  const [idAcopiador, setIdAcopiador] = useState(0);
  const [usaCentroAcopio] = useState(usaCentrosAcopios());
  const [motivos, setMotivos] = useState([{ value: 0, label: 'Seleccione un motivo' }]);

  useEffect(() => {
    const allMotivos = [];
    if (getIdCatCreditoPrecosecha() > 1) {
      allMotivos.push({ value: 1, label: 'Crédito pre-cosecha' });
    }
    if (coopConfig.config?.cooperativa?.producto?.nombre) {
      allMotivos.push({ value: 2, label: `Pagos de ${coopConfig.config?.cooperativa?.producto?.nombre}` });
    }
    setMotivos(prev => [...prev, ...allMotivos]);
  }, [coopConfig.config]);

  const [filterCA] = useState([
    {
      column: 'id_cooperativa',
      operator: '=',
      value: auth.user.id_cooperativa,
    },
  ]);

  const [filterAco] = useState([
    {
      column: 'id_cooperativa',
      operator: '=',
      value: auth.user.id_cooperativa,
    },
    { column: 'id_rol', operator: '=', value: 6 },
  ]);

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    fecha: yup
      .date()
      .max(moment().add(12, 'M'), 'Seleccione otra fecha')
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Debe seleccionar una fecha'),
    id_centro_acopio: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .when([], {
        is: () => usaCentroAcopio === 0,
        then: yup.number().nullable(),
        otherwise: yup
          .number()
          .min(1, 'Debe seleccionar un centro de acopio')
          .transform(value => (isNaN(value) ? undefined : value))
          .required('Debe seleccionar un centro de acopio'),
      }),
    cantidad: yup
      .number()
      .required('Debe escribir una cantidad')
      .positive()
      .transform(value => (isNaN(value) ? undefined : value)),
    tipo_motivo: yup
      .number()
      .min(1, 'Debe seleccionar un motivo')
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Debe seleccionar un acopiador'),
    id_acopiador: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable('Debe seleccionar un acopiador'),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    add: addHandler,
    edit: editHandler,
  }));

  const addHandler = () => {
    setShow(true);
    setIsNew(true);

    //Resteamos Valores
    setFecha(null);
    setIdCentroAcopio(0);
    setCantidad('');
    setTipoMotivo(0);
    setIdAcopiador(0);
    setErrors(null);
  };

  const editHandler = data => {
    setShow(true);
    setIsNew(false);

    //Asignamos valores 2023-01-03
    setId(data.id);
    setFecha(Date.parse(data.fecha_envio + 'T12:00:00'));
    setIdCentroAcopio(data.id_centros_acopios);
    setCantidad(data.monto);
    setTipoMotivo(data.tipo_motivo);
    setIdAcopiador(data.acopiador);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    var data = {
      fecha: fecha,
      id_centro_acopio: idCentroAcopio,
      cantidad: cantidad,
      tipo_motivo: tipoMotivo,
      id_acopiador: idAcopiador,
    };
    schema
      .validate(data, { abortEarly: false })
      .then(function() {
        //Datos validos
        const data = {
          fecha_envio: Moment(fecha).format('yyyy-MM-DD'),
          id_centros_acopios: idCentroAcopio === 0 ? null : idCentroAcopio,
          cantidad: parseFloat(cantidad * 100),
          tipo_motivo: tipoMotivo,
          acopiador: idAcopiador === 0 ? null : idAcopiador,
        };

        setBlocking(true);

        if (isNew) {
          // REGISTRAR
          Api.Acopio.create('registro_efectivo', data)
            .then(response => {
              if (response.data.success) {
                setBlocking(false);
                setShow(false);
                publish('Efectivo::onSave', response.data.data);
              } else {
                //Ocurrio un error
              }
            })
            .catch(e => {
              setBlocking(false);
            });
        } else {
          // EDITAR
          Api.Acopio.update('registro_efectivo', id, data)
            .then(response => {
              if (response.data.success) {
                setBlocking(false);
                setShow(false);
                publish('Efectivo::onSave', response.data.data);
              } else {
                //Ocurrio un error
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log('error: ', e);
              setBlocking(false);
            });
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
      });
  };

  return (
    <ModalGeneral
      show={show}
      title={isNew ? `Registrar ${title}` : `Editar ${title}`}
      onSave={validateHandler}
      onClose={() => closeHandler()}
      loading={blocking}>
      <div className="modal-body" style={{ overflowY: 'auto' }}>
        <div className="row my-4">
          <div className="col-12">
            <TextField
              type="date"
              name="fecha"
              error={errors?.fecha}
              label="Fecha"
              className="form-control"
              value={fecha}
              onChange={event => {
                setFecha(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">{errors?.fecha && <div className="invalid-feedback">{errors?.fecha}</div>}</div>
        </div>

        {usaCentroAcopio === 1 && (
          <div className="row" style={{ marginTop: 10 }}>
            <div className="input-group" style={{ borderStyle: 'none', marginLeft: -5 }}>
              <div style={{ paddingTop: 8 }} className="col-xl-12 col-lg-12">
                <FormSelectMaterialApi
                  placeholder="Seleccione un centro de acopio"
                  label="Centro de acopio"
                  id={'id_centro_acopio'}
                  error={errors?.id_centro_acopio}
                  url={'centros_acopios'}
                  keyArray="id"
                  labelArray="nombre"
                  onChange={event => {
                    setIdCentroAcopio(event.target.value);
                  }}
                  filter={filterCA}
                  sort={[{ column: 'nombre', sort: 'asc' }]}
                  value={idCentroAcopio}
                />
                {errors?.id_centro_acopio && <div className="invalid-feedback">{errors?.id_centro_acopio.message}</div>}
              </div>
            </div>
          </div>
        )}

        <div className="row my-4">
          <div className="col-12">
            <FormInputMaterial
              type="number"
              name="cantidad"
              className="form-control"
              label="Monto"
              placeholder="Escriba un monto"
              value={cantidad}
              error={{ message: errors?.cantidad }}
              onChangeCapture={event => {
                setCantidad(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12">
            <FormSelectMaterial
              placeholder="Motivo desembolso"
              label="Motivo desembolso"
              options={motivos}
              id={'tipo_motivo'}
              error={errors?.tipo_motivo}
              onChange={value => {
                setTipoMotivo(value);
              }}
              value={tipoMotivo}
            />
            {errors?.tipo_motivo && <div className="invalid-feedback">{errors?.tipo_motivo}</div>}
          </div>
        </div>

        {usaCentroAcopio === 1 && (
          <div className="row my-4">
            <div className="col-12">
              <FormSelectMaterialApi
                placeholder="Seleccione un acopiador"
                label="Acopiador"
                id={'id_acopiador'}
                error={errors?.id_acopiador}
                url="users"
                keyArray="id"
                labelArray="nombre"
                onChange={event => {
                  setIdAcopiador(event.target.value);
                }}
                filter={filterAco}
                sort={[{ column: 'nombre', sort: 'asc' }]}
                value={idAcopiador}
              />
            </div>
          </div>
        )}
      </div>
    </ModalGeneral>
  );
};

export default forwardRef(ModalEfectivo);
