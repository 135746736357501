import React from 'react';

export function Card({ height, image, value, label, imageStyle = {} }) {
  return (
    <div className="row card-dashboard" style={{ height: height }}>
      <div className="col-xl-3 col-md-3">
        <img src={image} style={{ ...imageStyle }} />
      </div>
      <div className="col-xl-9 col-md-9">
        <h2 className="font-lato-normal-600">{value}</h2>
        <h6 className="font-lato-normal-400">{label}</h6>
      </div>
    </div>
  );
}

export default Card;
