import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../nuup/componentes/Forms/FormInput';
import Api from '../../api/global.service';
import Swal from 'sweetalert2';
import generalSuccessModal from '../modal/modal-success';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const NumberForm = ({
  initialValue,
  columnLabel,
  column,
  idCuenta,
  onClose,
  showModal,
  idForm,
  idCiclo,
  min = 0,
  setData = null,
  isIngeger,
  idParcela,
}) => {
  let schema = yup.object().shape({
    numberColumn: yup
      .number()
      .typeError('Debe ser un número')
      .min(min, `El número debe ser mayor o igual a ${min}`)
      .required('No se puede enviar un campo vacío')
      .concat(
        isIngeger
          ? yup.number().integer('debe ser un número entero')
          : yup.number().test('maxDigitsAfterDecimal', 'Máximo 2 decimales', number => /^\d+(\.\d{1,2})?$/.test(number))
      ),
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      numberColumn: initialValue,
    },
  });

  useEffect(() => {
    reset({
      numberColumn: initialValue,
    });
    schema = yup.object().shape({
      numberColumn: yup
        .number()
        .typeError('Debe ser un número')
        .min(min, `El número debe ser mayor o igual a ${min}`)
        .required('No se puede enviar un campo vacío')
        .concat(
          isIngeger
            ? yup.number().integer('debe ser un número entero')
            : yup
                .number()
                .test('maxDigitsAfterDecimal', 'Máximo 2 decimales', number => /^\d+(\.\d{1,2})?$/.test(number))
        ),
    });
  }, [showModal]);

  const onSubmit = values => {
    setLoading(true);
    const payload = {
      id_cuenta: idCuenta,
      new_value: values.numberColumn,
      column: column,
      id_sic_informacion_general: idForm,
      id_ciclo: idCiclo,
      id_parcela: idParcela,
    };

    Api.create(`admin/sic/padron_edit/${column}`, payload)
      .then(response => {
        const { success, message, data } = response.data;
        const finish = () => {
          if (setData) {
            setData(values.numberColumn);
          } else {
            history.go(0);
          }
          onClose();
          setLoading(false);
        };
        generalSuccessModal(success, `${columnLabel} actualizado con éxito`, finish, message, data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        const err = error.response.data;
        const errorMessage = err.errors['new_value'] !== undefined ? err.errors['new_value'] : err.errors.message;
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage,
        });
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className="modal-body">
        <div className="row my-4">
          <h4
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            {columnLabel}
          </h4>
          <div className="col-12">
            <FormInput
              id="numberColumn"
              type="number"
              step="any"
              name="numberColumn"
              // label={columnLabel}
              error={errors.numberColumn}
              register={register}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loading && (
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Guardando...</span>
          </div>
        )}
        {!loading && (
          <>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-outline-secondary"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i> Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-outline-secondary btn-sirio"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-save"></i> Guardar
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default NumberForm;
