import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import Select from 'react-select';

const SelectFromApi = ({ value, url, keyValue, keyLabel, setFilter, placeholder, filter, sort }) => {
  const fontSize = 18;

  const defaultOption = { value: 0, label: placeholder, isDisabled: true };

  const [options, setOptions] = useState([defaultOption]);

  useEffect(() => {
    let params = {
      filter: filter ? filter : [],
      sort: sort ? sort : [],
    };

    Api.search(url, params).then(response => {
      let options = response.data.map(function(item) {
        return { value: item[keyValue || 'value'], label: item[keyLabel || 'label'] };
      });
      setOptions([defaultOption, ...options]);
    });
    // .catch(e => {});
  }, []);

  const [selected, setSelected] = useState(defaultOption);

  const handleChange = option => {
    setSelected(option);
    setFilter(option.value);
  };

  useEffect(() => {
    if (value != 0) {
      let sel = options.filter(o => o.value == value);
      setSelected(sel);
    }
  }, [value]);

  return (
    <Select
      options={options}
      value={selected}
      onChange={handleChange}
      styles={{
        valueContainer: base => ({
          ...base,
          paddingLeft: 38,
        }),
        singleValue: base => ({
          ...base,
          fontSize: fontSize,
        }),
        placeholder: base => ({
          ...base,
          fontSize: fontSize,
        }),
        fontSize: fontSize,
      }}
    />
  );
};

export default SelectFromApi;
