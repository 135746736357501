import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../components/content/auth.jsx';
import BgSvg from '../components/svg/bg-svg.jsx';
import env from '../environment.js';
import { getRutaInicial } from '../components/content/coop-config.jsx';

const LoginV3Mobile = (props) => {
  const [email, setEmail] = useState(env.appEnv === 'local' ? 'vvalencia@nuup.org' : '');
  const [password, setPassword] = useState(env.appEnv === 'local' ? 'S1ri0_01' : '');
  const [error, setError] = useState('');
  const [type, setType] = useState('password');

  useEffect(() => {
    localStorage.removeItem(`${env.appName}-sesion`);
    localStorage.removeItem(`${env.appName}-user`);
    localStorage.removeItem(`${env.appName}-token`);
    localStorage.removeItem(`${env.appName}-coop-config`);
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    switch (event.target.name) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.auth
     .login({ email, password })
     .then(response => {
        if (response) {
          if (response.permissions.filter(x => x.includes('PADRON_COMPLETO')).length!= 0) {
            window.location = getRutaInicial('/dashboard');
          } else {
            window.location = getRutaInicial('/admin/cuentas/list');
          }
        }
      })
     .catch(error => {
        error.json().then(message => {
          if (message.email!= null) {
            setError('Introduzca una dirección de correo electrónico válida.');
          } else if (message.password!= null) {
            setError('La contraseña debe contener al menos 6 caracteres.');
          } else {
            setError('Correo electrónico o contraseña incorrectos.');
          }
        });
      });
  };

  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setType(type === 'input' ? 'password' : 'input');
  };

  return (
    <div className="login mx-5 mt-5">
       <div className="news-image">
            <BgSvg
              style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%', height: 'auto', zIndex: 1 }}
              alt="fondo"></BgSvg>
            <img
              src={env.images.login.bg_inicio_front}
              style={{ position: 'absolute', bottom: 0, left: 0, width: '80%', zIndex: 2}}
              alt="productor2"
            />
          </div>
      <div className="login-header">
        <div className="brand">
          <img src={env.images.logo} />
        </div>
        <div className="icon">
          <i className="fa fa-sign-in"></i>
        </div>
      </div>
      <div className="login-content my-5">
        <form className="" id="frmLogin" onSubmit={handleSubmit}>
          <div className="input-group m-b-15">
            <span className="input-group-text" style={{zIndex: 50}}>
              <i className="fa fa-user-circle"></i>
            </span>
            <input
              type="text"
              name="email"
              value={email}
              onChange={e => handleChange(e)}
              className="form-control form-control-lg"
              style={{height: 'max-content', zIndex: 50}}
              placeholder="Correo electrónico"
              required
            />
          </div>

          <div className="input-group">
            <span className="input-group-text" style={{zIndex: 50}}>
              <i className="fa fa-lock"></i>
            </span>
            <input
              type={type}
              name="password"
              value={password}
              onChange={e => handleChange(e)}
              className="form-control form-control-lg"
              style={{height: 'max-content', zIndex: 50}}
              placeholder="Contraseña"
              required
            />
            <button type="button" className="btn btn-sm rounded-0" onClick={showHide}>
              {type === 'input' ? <i className="fa fa-eye-slash "></i> : <i className="fa fa-eye"></i>}
            </button>
          </div>

          <div className="form-group m-b-15">
            <span style={{ color: 'red' }}>{error}</span>
          </div>

          <div className="login-buttons d-flex z-3 justify-content-center mt-5">
            <button type="submit" className="btn btn-lg btn-sirio" style={{ width: '50%', zIndex: 50 }}>
              Ingresar
            </button>
          </div>
        </form>
    </div>
  </div>
  );
};

export default (props) => {
  const auth = useAuth();
  const history = useHistory();
  return <LoginV3Mobile auth={auth} history={history} {...props} />;
};