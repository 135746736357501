import * as yup from "yup";

const patternNumero2Decimales = /^\d+(\.\d{0,2})?$/;
const msgs = {required : "Información obligatoria"};

const validacionNumero2Decimales = (min = 0,_messages = null) => {
  let messages = (_messages === null) ? msgs : _messages;
  let msgMin = "Debe escribir un valor" + ((min === 0) ? " igual o mayor  " : " mayor ") + "a "  + parseInt(min);
  let validation = yup.number().transform((value) => (isNaN(value) ? undefined : value)).min(min, msgMin)
  .test("is-decimal", "Solo se permiten dos decimales", (val) => {
    if (val != undefined) {
      return patternNumero2Decimales.test(val);
    }
    return true;
  }).positive(msgMin).required(messages['required']);
  return validation;
  }

  export const validacionNumero2DecimalesNotRequired = (min = 0,_messages = null) => {
    let msgMin = "Debe escribir un valor" + ((min === 0) ? " igual o mayor  " : " mayor ") + "a "  + parseInt(min);
    let validation = yup.number().transform((value) => (isNaN(value) ? undefined : value)).min(min, msgMin)
    .test("is-decimal", "Solo se permiten dos decimales", (val) => {
      if (val != undefined) {
        return patternNumero2Decimales.test(val);
      }
      return true;
    }).positive(msgMin).nullable();
    return validation;
    }
  

  export default validacionNumero2Decimales;