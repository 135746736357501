import React from "react";
import { withRouter, Switch } from "react-router-dom";
import routes from "./../../config/page-route.jsx";
import { PageSettings } from "./../../config/page-settings.js";
import env from "./../../environment";
import { filtrarRutas } from "../content/coop-config.jsx";
import AuthRoute from "./auth-route.js";

function setTitle(path, routeArray) {
  let pageTitle;
  for (let i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = `${env.appName} | ${routeArray[i].title}`;
    }
  }
  document.title = pageTitle ? pageTitle : `${env.appName}`;
}

class Content extends React.Component {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes);
  }

  // componentWillMount() {
  //   this.props.history.listen(() => {
  //     setTitle(this.props.history.location.pathname, routes);
  //   });
  // }

  render() {
    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            <Switch>
              {filtrarRutas(routes).map((route, index) => (
                <AuthRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default withRouter(Content);
