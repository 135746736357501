import React, { useState } from 'react';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';
import SicLinkTable from '../../components/tables/sic-link-table.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import SicLinkCuentasTable from '../../components/tables/sic-link-cuentas-table.jsx';
import SicLinkTableParcela from '../../components/tables/sic-link-table-parcela.jsx';
import filterIds from '../../utils/filterIds.js';
import Swal from 'sweetalert2';
import generalSuccessModal from '../../components/modal/modal-success.jsx';

const VincularSicParcelasP4 = () => {
  const location = useLocation();
  const history = useHistory();
  const [blocking, setBlocking] = useState(false);

  const onSubmit = () => {
    setBlocking(true);
    const idsPP = filterIds(location.state.forms, 'sic_pp');
    const idsPO = filterIds(location.state.forms, 'tx_po');
    const idsCroquis = filterIds(location.state.forms, 'tx_croquis');
    const data = {
      id_parcela: location.state.parcelas[0].id,
      idsPP,
      idsPO,
      idsCroquis,
    };
    Api.create('admin/sic/vinculo_forms_parcela_batch', data)
      .then(response => {
        const { success, message, data } = response.data;
        const plural = data > 1 ? 's' : '';
        const successMessage = `${data} formulario${plural} vinculado${plural} correctamente`;
        generalSuccessModal(success, successMessage, () => history.go(-3), message, data);
      })
      .catch(e => {
        setBlocking(false);
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        Swal.fire({
          icon: 'error',
          title: '',
          text: e.response.data.message,
        });
      });
  };

  const onCancel = () => history.go(-3);

  return (
    <div>
      {/*
         // TODO: crear lógica de permiso
        {auth.hasPermission('VINCULAR_SIC') && (
      */}
      <>
        <CardContainer height={'100%'}>
          <div className="row"></div>
          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">Cuenta Seleccionada</h3>
          </div>
          <div>
            <div className="row">
              <div className="col-xl-12">
                <SicLinkCuentasTable title="Cuentas Nuevas" data={location.state.cuentas} blocking={blocking} />
              </div>
            </div>
          </div>
          <div className="row"></div>

          <div className="row">
            <h3 className="col-xl-12 font-lato-normal-400">Parcela Seleccionada</h3>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <SicLinkTableParcela title="Parcelas" data={location.state.parcelas} blocking={blocking} />
            </div>
          </div>

          <div>
            <div className="row">
              <h3 className="col-xl-12 font-lato-normal-400">Formularios a vincular con parcela</h3>
            </div>
            <div>
              <div className="row">
                <div className="col-xl-12">
                  <SicLinkTable title="Formularios" data={location.state.forms} blocking={blocking} />
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                <button
                  onClick={onCancel}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
              </div>
              <div className="col-4">
                <button
                  onClick={onSubmit}
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    borderRadius: '20px 20px 20px 20px',
                  }}>
                  Confirmar vinculación de formularios con parcela
                </button>
              </div>
            </div>
          </div>
        </CardContainer>
      </>
    </div>
  );
};

export default VincularSicParcelasP4;
