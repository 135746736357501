import React, { useState, useEffect } from 'react';
import BlockUi from 'react-block-ui';
import CardContainer from '../../../components/card/card-container';
import LabelCategory from '../../../components/label/label-category';
import CardBasic from '../../../components/card/card-basic';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import env from '../../../environment';
import Api from "../../../api/global.service";

const CardProductor = ({ blocking, idCuenta, fotoURL = null }) => {
  const [cuenta, setCuenta] = useState([]);
  const [fotoOpen, setFotoOpen] = useState(false);


  useEffect(() => {
    Api.search("cuentas", {
      filter: [
        {
          column: "id_ciclo",
          operator: "=",
          value: localStorage.getItem(`${env.appName}-ciclo_id`),
        },
        {
          column: "id",
          operator: "=",
          value: idCuenta,
        },
      ],
    }).then((response) => {
      setCuenta(response.data);
      let _items = response.data.map(function(item) {
        return {
          value: item["id"],
          label: item["nombre"],
          uuid_cuenta: item["uuid"],
        };
      });
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  }, [idCuenta]);

  return (
    <>
      <div className="row pb-4 mt-3">
        <div className="col-lg-12  mt-2">
          <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
            <CardContainer style={{ padding: '15px 20px', backgroundColor : '#FAFAFA' }}>
              <div className="row">
                <div className="col-lg-3 col-sm-5 text-center">
                  {fotoURL ? (
                    <button
                      type="button"
                      className="btn"
                      style={{ borderRadius: '20px 20px 20px 20px' }}
                      onClick={() => setFotoOpen(!fotoOpen)}>
                      <img src={`${env.apiURL}/${fotoURL}`} className="profile_productor"></img>
                    </button>
                  ) : (
                    <img src={env.images.profile.productor} />
                  )}
                </div>
                <div className="col-lg-9 col-sm-7">
                  <div className="row" style={{ paddingTop: 16 }}>
                    <div className="col-sl-8">
                      <h6 className="font-lato-normal-400">Productor:</h6>
                      <h3 className="font-lato-normal-500 pb-3">{cuenta[0]?.nombre || '-'}</h3>
                      <LabelCategory
                        label={cuenta[0]?.cuenta_categoria}
                        color={cuenta[0]?.cuenta_categoria_color}></LabelCategory>
                    </div>
                    <div className="col-sl-4">
                      {(cuenta[0]?.clave_tx, cuenta[0]?.codigo_tx) ? (
                        <>
                          <h6 className="font-lato-normal-400">{cuenta[0]?.clave_tx}</h6>
                          <h3 className="font-lato-normal-400 pb-4">{cuenta[0]?.codigo_tx}</h3>
                        </>
                      ) : (
                        <>
                          <h6 className="font-lato-normal-400">-</h6>
                          <h3 className="font-lato-normal-400 pb-4">{cuenta[0]?.clave_tx || cuenta[0]?.codigo_tx || '-'}</h3>
                        </>
                      )}
                      {cuenta[0]?._fecha_ingreso != null && (
                        <h6 className="font-lato-normal-400">Desde {cuenta[0]?._fecha_ingreso}</h6>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <div className="row">
                    {cuenta[0]?.certifications?.map(cert => {
                      return (
                        <div key={cert.id} className="col-4 col-sm-12 col-md-12 col-lg-12">
                          <img
                            src={`${env.assetURL}${cert.url}`}
                            alt={cert.name}
                            style={{
                              width: '45px',
                              marginBottom: '15px',
                            }}></img>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </CardContainer>
          </BlockUi>
        </div>
      </div>
      {fotoURL ? (
        <Modal isOpen={fotoOpen} toggle={() => setFotoOpen(!fotoOpen)} centered={true} size={'lg'}>
          <ModalBody>
            <img src={`${env.apiURL}/${fotoURL}`} style={{ width: '100%' }}></img>
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-white" onClick={() => setFotoOpen(!fotoOpen)}>
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};

export default CardProductor;
