/**
 * Update local data and propagate change to parent component by adding new value to total
 * @param {any} newValue - The new value to update local data with
 * @param {string} childColumn
 * @param {function} setData
 * @param {any} oldValue
 * @param {number} rowIndex
 * @param {function} setParentData
 * @param {string} parentColumn
 * @param {number} parentRow
 * @param {function} parse parse function
 * @param {function} setParentDataOriginal
 * @param {number} id_cuenta pare function
 */
const updateLocalDataAndParent = (
  newValue,
  childColumn,
  setData,
  oldValue,
  rowIndex,
  setParentData,
  parentColumn,
  parentRow,
  parse,
  setParentDataOriginal,
  id_cuenta
) => {
  setData(prevData => {
    return prevData.map((item, ind) => {
      if (ind === rowIndex) return { ...item, [childColumn]: newValue, updated: [childColumn] };
      return item;
    });
  });

  setParentData(prevData => {
    return prevData.map((item, ind) => {
      if (ind === parentRow) {
        return {
          ...item,
          [parentColumn]: parse(item[parentColumn]) + parse(newValue) - parse(oldValue),
          updated: [parentColumn],
        };
      }
      return item;
    });
  });
  setParentDataOriginal &&
    setParentDataOriginal(prevData => {
      return prevData.map(item => {
        if (item.id_cuenta === id_cuenta) {
          return {
            ...item,
            [parentColumn]: parse(item[parentColumn]) + parse(newValue) - parse(oldValue),
            updated: [parentColumn],
          };
        }
        return item;
      });
    });
};

export default updateLocalDataAndParent;
