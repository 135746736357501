import Swal from 'sweetalert2';

/**
 * check for 403, 404 errors and show modal
 * @param {number} status http status
 * @param {string} returnUrl url return string
 */
const generalSuccessModal = (success, successMessage, ifConfirmed, message, responseData) => {
  if (success === 1) {
    Swal.fire({
      icon: 'success',
      title: '',
      text: successMessage,
      confirmButtonText: 'Continuar',
    }).then(result => {
      if (result.isConfirmed) {
        ifConfirmed();
      }
    });
  } else {
    Swal.fire({
      icon: 'error',
      title: '',
      text: message,
      showDenyButton: true,
      denyButtonText: `Regresar a Listado`,
    }).then(result => {
      if (result.isDenied) {
        // eslint-disable-next-line no-console
        console.log('response.data', responseData);
      }
    });
  }
};

export default generalSuccessModal;
