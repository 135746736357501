import React from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import BlockUi from 'react-block-ui';
import flattenColumnsRecursive from '../../utils/flattenColumns.js';

const NoPagination = ({ blocking, columns, data, fnBackgroundColor, fnColor, sticky = true }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: flattenColumnsRecursive(columns).map(column => {
          if (column.show === false) {
            return column.accessor || column.id;
          }
        }),
      },
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useSortBy
  );

  const getBackgroundColor =
    fnBackgroundColor != null
      ? fnBackgroundColor
      : () => {
          return 'rgba(240, 224, 207, 1.0)';
        };

  const getColor =
    fnColor != null
      ? fnColor
      : i => {
          return 'rgb(95, 95, 89)';
        };

  return (
    <>
      <div style={{ paddingBottom: 0 }}>
        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <div className={`table-responsive ${sticky ? 'table-sticky' : ''}`} style={{ marginBottom: 20 }}>
            <table className="table" {...getTableProps()} style={{ borderBottom: '1px solid #e4e7ea' }}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, j) => (
                      <th
                        key={j}
                        className="width-150"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          backgroundColor: getBackgroundColor(i, j),
                          color: getColor(i),
                          border: '0px solid red',
                        }}>
                        <div>{column.filter ? column.render('Filter') : null}</div>
                        <div
                          className="d-flex"
                          style={{
                            minWidth: column.minWidth ? column.minWidth : column.width ? 'auto' : '150px',
                            width: column.width ? column.width : 'auto',
                          }}>
                          <span>{column.render('Header')}</span>
                          <span className="ml-auto">
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down fa-fw f-s-14 text-green"></i>
                                ) : (
                                  <i className="fa fa-sort-up fa-fw f-s-14 text-green"></i>
                                )
                              ) : (
                                <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()} style={{ color: '#171C26' }}>
                      {row.cells.map((cell, j) => {
                        return (
                          <td key={j} className="edit_hover_tr" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {rows.length === 0 && !blocking && <div className="w-100 text-center">Sin registros encontrados</div>}
          </div>
        </BlockUi>
      </div>
    </>
  );
};

export default NoPagination;
