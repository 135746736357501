import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import CardContainer from "../../components/card/card-container.jsx";
import Breadcrumb from "../../components/breadcrumb/breadcrumb.jsx";
import LabelCategory from "../../components/label/label-category.jsx";
import LabelNumber from "../../components/label/label-number.jsx";
import Api from "../../api/global.service";
import { useAuth } from "../../components/content/auth.jsx";
import env from "../../environment.js";
import NoPagination from "../../components/pagination/noPagination.jsx";
import SelectCiclo from "../../components/select/select-ciclo.jsx";
import LinkDownload from "../../components/link/link-download.jsx";
import { useHistory } from "react-router-dom";
import { useCoopConfig } from "../../components/content/coop-config.jsx";
import CardProductor from "./components/card-productor.jsx";
import CardProductorHuertas from "./components/card-productor-huertas.jsx";
import Select, { components } from "react-select";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInputMaterialTest from "../../nuup/componentes/Forms/FormInputMaterialTest.jsx";
import FormSelectMaterialApi from "../../nuup/componentes/Forms/FormSelectMaterialApi.jsx";
import CardBasic from "../../components/card/card-basic";
import { nominalTypeHack } from "prop-types";
import { getCalidadLS } from "./components/helpers.js";
import CardLocalidad from "./components/card-localidad.jsx";
import CardLote from "./components/card-lote.jsx";
import CheckboxList from '../../components/forms/checkbox-list.jsx';


const CalidadSeleccion = () => {
  const INF_OBLIGATORIA = "Información obligatoria";
  const queryParameters = new URLSearchParams(window.location.search);
  const history = useHistory();
  const redirect = history.location.state?.redirect;
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(false);
  const [btnLotes, setBtnLotes] = useState(true);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  const [cuentasOriginal, setCuentasOriginal] = useState([]);
  const [dataLotes, setDataLotes] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [idLote, setIdLote] = useState("");
  const [idCuenta, setIdCuenta] = useState("");
  const [tipoAnalisis, setTipoAnalisis] = useState(1); // 1 productor 2 lote, etc
  const [analisisPorEntrega, setAnalisisPorEntrega] = useState(2); // 1 SI se selecciona entrega 2 NO se selecciona entrega
  const [idAcopio, setIdAcopio] = useState(0);
  const [infoLote, setInfoLote] = useState(false);
  const [idMunicipio, setIdMunicipio] = useState(0);
  const [idLocalidad, setIdLocalidad] = useState([]);
  const [errores, setErrores] = useState([]);

  const [formData] = useState({
    id_calidad_analisis: getCalidadLS("id_calidad_analisis"),
    id_calidad_analisis_fisico: getCalidadLS("id_calidad_analisis_fisico"),
    id_calidad_analisis_sensorial: getCalidadLS(
      "id_calidad_analisis_sensorial"
    ),
    id_cuenta: getCalidadLS("id_cuenta"),
    id_acopio: getCalidadLS("id_acopio"),
    id_lote: getCalidadLS("id_lote"),
    tipo_analisis: getCalidadLS("tipo_analisis"),
    analisis_por_entrega: getCalidadLS("analisis_por_entrega"),
    nombre_proveedor: getCalidadLS("nombre_proveedor"),
    region_proveedor: getCalidadLS("region_proveedor"),
    notas_adicionales: getCalidadLS("notas_adicionales"),
    id_municipio: getCalidadLS("id_municipio"),
    id_localidad: getCalidadLS("id_localidad"),
  });

  const schema = yup.object().shape({
    tipo_analisis: yup.number().min(1),
    id_cuenta: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 1)
          return schema.min(1, INF_OBLIGATORIA).required(INF_OBLIGATORIA);
        return schema;
      }),
    id_lote: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 2)
          return schema.min(1, INF_OBLIGATORIA).required(INF_OBLIGATORIA);
        return schema;
      }),
    analisis_por_entrega: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    id_acopio: yup
      .string()
      .nullable()
      .when("analisis_por_entrega", (analisis_por_entrega, schema) => {
        if (analisis_por_entrega === 1 && idCuenta > 0)
          return schema.required(INF_OBLIGATORIA);
        return schema;
      }),
    id_municipio: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 3)
          return schema.min(1, INF_OBLIGATORIA).required(INF_OBLIGATORIA);
        return schema;
      }),
    nombre_proveedor: yup
      .string()
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 4)
          return schema.required(INF_OBLIGATORIA);
        return schema;
      }),
    region_proveedor: yup
      .string()
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 4)
          return schema.required(INF_OBLIGATORIA);
        return schema;
      }),
    notas_adicionales: yup
      .string()
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 4)
          return schema.required(INF_OBLIGATORIA);
        return schema;
      }),
    id_localidad: yup
      .string()
      .nullable()
      .when("tipo_analisis", (tipo_analisis, schema) => {
        if (tipo_analisis === 3)
          return schema.required(INF_OBLIGATORIA);
        return schema;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: async (data, context, options) => {
      return yupResolver(schema)(data, context, options);
    },
    mode: "onBlur",
    defaultValues: formData,
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      flexDirection: "row-reverse",
      width: "100%",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#999",
        fontSize: 14,
      };
    },
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{ position: "initial" }}
          ></i>
        </components.DropdownIndicator>
      )
    );
  };

  const handleChange = (selected) => {
    setValue("id_cuenta", selected.value);
    setIdCuenta(selected.value);
    setValue("id_lote", "");
    setIdLote("");
  };

  const handleChangeLote = (selected) => {
    setValue("id_lote", selected.value);
    setIdLote(selected.value);
    setValue("id_cuenta", "");
    setIdCuenta("");
  };

  useEffect(() => {
    getProductores();
    getLotes();
  }, []);

  useEffect(() => {
    if (analisisPorEntrega === 1) {
      let values = getValues();
      if (idLote > 0) {
        // Buscar entregas por lote
        console.log("Buscar entregas por lote");
      }

      if (idCuenta > 0) {
        // Buscar entregas por cuenta
        updateData();
      }
    }
  }, [analisisPorEntrega, idCuenta, idLote]);


  useEffect(() => {
    setValue('id_acopio','');
    if (idsSeleccionados.length > 0) {
      setValue('id_acopio',idsSeleccionados.join());
    }
  }, [idsSeleccionados]);


  const getProductores = () => {
    setBlocking(true);
    Api.search("cuentas", {
      filter: [
        {
          column: "id_ciclo",
          operator: "=",
          value: localStorage.getItem(`${env.appName}-ciclo_id`),
        },
      ],
    }).then((response) => {
      setCuentasOriginal(response.data);
      let _items = response.data.map(function(item) {
        return {
          value: item["id"],
          label: item["nombre"],
          uuid_cuenta: item["uuid"],
        };
      });
      setCuentas(_items);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const getLotes = () => {
    Api.get(
      "admin/acopios/entregas/lotes",
      localStorage.getItem(`${env.appName}-ciclo_id`),
      {}
    )
      .then((response) => {
        let lotes = response.data;
        let _lotes = response.data;
        setDataLotes(lotes);
        setLotes(
          lotes.map(function(lote) {
            return { label: lote.nombre, value: lote.id };
          })
        );
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "INFORMACIÓN DE ENTREGA",
        columns: [
          {
            Header: "",
            accessor: "x",
            minWidth: 50,
            Cell: ({row}) => {
              return (
                <>
                  <div className="form-check">
                      <CheckboxList
                        name="entregas"
                        value={row.original.id_acopio}
                        handleCheck={handleCheck}
                        checked={idsSeleccionados.includes(row.original.id_acopio) === true}
                      />
                  </div>
                </>
              );
            }
          },
          {
            Header: "FECHA ENTREGA",
            accessor: "fecha_acopio",
            sortable: true,
            minWidth: 80,
            Cell: ({ value }) => {
              return (
                <p className="text-left">
                  {value != null ? value.substr(0, 10) : ""}
                </p>
              );
            },
          },
          {
            Header: "FOLIO ENTREGA",
            accessor: "folio_acopio",
            sortable: true,
            minWidth: 50,
          },

          {
            Header: "Nº RECIPIENTES",
            accessor: "no_bultos",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value}></LabelNumber>;
            },
          },
          {
            Header: "PESO BRUTO (KGS)",
            accessor: "peso_bruto",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: "PESO TARA (KGS)",
            accessor: "peso_tara",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: "PESO NETO (KGS)",
            accessor: "peso_neto",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return <LabelNumber value={value} digits={2}></LabelNumber>;
            },
          },
          {
            Header: "PRECIO A PRODUCTOR ($/KG)",
            accessor: "precio_por_kg",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <LabelNumber
                  value={value}
                  currency={true}
                  digits={2}
                ></LabelNumber>
              );
            },
          },
          {
            Header: "IMPORTE TOTAL ($)",
            accessor: "importe_total",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <LabelNumber
                  value={value}
                  currency={true}
                  digits={2}
                ></LabelNumber>
              );
            },
          },
          {
            Header: "DESCUENTOS DEUDAS",
            accessor: "total_anticipos_recuperados",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <LabelNumber
                  value={value}
                  currency={true}
                  digits={2}
                ></LabelNumber>
              );
            },
          },
          {
            Header: "PAGADO A PRODUCTOR ($)",
            accessor: "pagado_a_productor",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <LabelNumber
                  value={value}
                  currency={true}
                  digits={2}
                ></LabelNumber>
              );
            },
          },
          {
            Header: "SALDO DE LA ENTREGA ($)",
            accessor: "total_saldo",
            sortable: true,
            minWidth: 50,
            Cell: ({ value }) => {
              return (
                <LabelNumber
                  value={value}
                  currency={true}
                  digits={2}
                ></LabelNumber>
              );
            },
          },
          {
            Header: "ESTATUS COSTALERA",
            accessor: "nombre_cat_costaleras_estatus",
            sortable: true,
            minWidth: 50,
            show: coopConfig.mostrarCostalera(),
            Cell: ({ value }) => {
              if (value != null) {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {value}
                  </p>
                );
              } else {
                return (
                  <p className="text-left" style={{ margin: 0 }}>
                    {"-"}
                  </p>
                );
              }
            },
          },
          {
            Header: "RECIBOS",
            accessor: "recibo_pdf",
            sortable: true,
            Cell: ({ value, row }) => {
              let v = value;
              if (v != null && v != "")
                return (
                  <LinkDownload
                    isZip
                    url={`recibos/acopios/${row.original.acopio_uuid}`}
                    label="Descargar"
                    icon={env.images.icon.pdf}
                  ></LinkDownload>
                );
              else return "...en construcción, intentar más tarde";
            },
          },
        ],
      },
    ],
    [idsSeleccionados]
  );

  const columnsLotes = React.useMemo(
    () => [
      {
        Header: "NOMBRE",
        accessor: "nombre",
        sortable: true,
        minWidth: 80,
      },
      {
        Header: "Nº O CÓDIGO",
        accessor: "folio",
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.folio != null) {
            return value;
          } else {
            return "-";
          }
        },
      },
      {
        Header: "VOLUMEN A ENTREGAR",
        accessor: "volumen",
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.volumen != null) {
            return (
              <LabelNumber
                value={value}
                currency={false}
                digits={2}
                position="text-left"
              ></LabelNumber>
            );
          } else {
            return "-";
          }
        },
      },
      {
        Header: "VOLUMEN ACOPIADO",
        accessor: "lote_kgs_entregados",
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.lote_kgs_entregados != null) {
            return (
              <LabelNumber
                value={value}
                currency={false}
                digits={2}
                position="text-left"
              ></LabelNumber>
            );
          } else {
            return "-";
          }
        },
      },
      {
        Header: "AVANCE ACOPIO",
        accessor: "avance_acopio",
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          let avance_por =
            (
              (Number(row.original.lote_kgs_entregados) /
                Number(row.original.volumen)) *
              100
            ).toFixed(2) + " %";
          if (row.original.volumen != null) {
            return (
              <LabelNumber
                value={avance_por}
                currency={false}
                suffix=" %"
                digits={2}
                position="text-left"
              ></LabelNumber>
            );
          } else {
            return "-";
          }
        },
      },
      {
        Header: "FECHA DE ENTREGA O VENTA",
        accessor: "fecha_entrega_venta",
        sortable: true,
        minWidth: 50,
        Cell: ({ value, row }) => {
          if (row.original.fecha_entrega_venta != null) {
            return value;
          } else {
            return "-";
          }
        },
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: "", label: "TODO" },
      { value: 1, target: "fecha_acopio", label: "FECHA ENTREGA" },
      { value: 2, target: "estatus", label: "ESTATUS" },
      { value: 3, target: "folio_entrega", label: "FOLIO ENTREGA" },
      { value: 4, target: "fecha_entrega", label: "FECHA ENTREGA" },

      { value: 5, target: "productor_clave_tx", label: "CLAVE" },
      { value: 6, target: "productor_codigo_tx", label: "CÓDIGO" },
      { value: 7, target: "folio_acopio", label: "FOLIO RECIBIDO" },
      { value: 8, target: "productor_nombre", label: "NOMBRE PRODUCTOR" },
      { value: 9, target: "localidad", label: "LOCALIDAD" },
      { value: 10, target: "region", label: "REGIÓN" },
      { value: 11, target: "centro_acopio", label: "CENTRO DE ACOPIO" },
      { value: 12, target: "cuenta_categoria", label: "CATEGORÍA" },

      { value: 13, target: "no_bultos", label: "Nº RECIPIENTES" },
      { value: 14, target: "peso_bruto", label: "PESO BRUTO (KGS)" },
      { value: 15, target: "peso_tara", label: "PESO TARA (KGS)" },
      { value: 16, target: "peso_neto", label: "PESO NETO (KGS)" },
      {
        value: 17,
        target: "precio_por_kg",
        label: "PRECIO A PRODUCTOR (PERG)",
      },
      { value: 18, target: "importe_total", label: "IMPORTE TOTAL ($)" },
      {
        value: 19,
        target: "total_anticipos_recuperados",
        label: "DESCUENTOS DEUDAS",
      },
      {
        value: 20,
        target: "pagado_a_productor",
        label: "PAGADO A PRODUCTOR ($)",
      },
      { value: 21, target: "total_saldo", label: "SALDO DE LA ENTREGA ($)" },
    ],
    []
  );

  const getBackgroundColor = (i, j) => {
    if (i == 1) {
      return "rgb(247, 239, 231)";
    } else {
      switch (j) {
        case 1:
          return "#91918A";
        case 3:
          return "#91918A";
        case 5:
          return "#91918A";
        case 7:
          return "#91918A";
        default:
          return env.colors.neutral_600;
      }
    }
  };

  const getColor = (i) => {
    return i == 1 ? "rgb(95, 95, 89)" : "white";
  };

  const setFilter = (value) => {
    setCiclo(value.target);
  };

  const updateData = () => {
    setBlocking(true);
    Api.Acopio.search("entregas_por_productor", {
      filter: [
        {
          column: "id_ciclo",
          operator: "=",
          value: localStorage.getItem(`${env.appName}-ciclo_id`),
        },
        { column: "id_cuenta", operator: "=", value: idCuenta },
      ],
    })
      .then((response) => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log("error", e);
        setBlocking(false);
      });
  };

  const validateHandler = () => {
    var data = getValues();
    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        clearErrors();
        limpiaConfigAnalisis();
        history.push({
          pathname: "/admin/calidad/analisis/fisico/registro",
        });
      })
      .catch(function(err) {
        
        let errores = err.inner.reduce(function(map, obj) {
          setError(obj.path, { message: obj.message });
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrores(errores);
        setBlocking(false);
      });
  };

  /*
  useEffect(() => {
    watch(values => {
      validateHandler();
    });
  }, [watch]);
*/

  useEffect(() => {
    clearErrors();
    setErrores([]);
  }, [tipoAnalisis]);

  useEffect(() => {
    let tipoAnalisis = Number(getCalidadLS("tipo_analisis"));
    setTipoAnalisis(tipoAnalisis);
    switch (tipoAnalisis) {
      case 1:
        let id_cuenta = Number(getCalidadLS("id_cuenta"));
        setValue("id_cuenta", id_cuenta);
        setIdCuenta(id_cuenta);
        let id_acopio = getCalidadLS("id_acopio");
        if(id_acopio.length > 0){
          setValue("id_acopio", id_acopio);
          setIdAcopio(id_acopio);
          setAnalisisPorEntrega(1);
          setIdsSeleccionados(id_acopio.split(","));
        }
        break;
      case 2:
        let id_lote = Number(getCalidadLS("id_lote"));
        setValue("id_lote", id_lote);
        setIdLote(id_lote);
        break;
      case 3:
        let id_municipio = Number(getCalidadLS("id_municipio"));
        let id_localidad = getCalidadLS("id_localidad");
        setValue("id_municipio", id_municipio);
        setValue("id_localidad", id_localidad);
        setIdMunicipio(id_municipio);
        setIdLocalidad(id_localidad);
        break;
    }
  }, []);

  const limpiaConfigAnalisis = () => {
    let configAnalisis = {
      id_calidad_analisis: getCalidadLS("id_calidad_analisis"),
      id_calidad_analisis_fisico: getCalidadLS("id_calidad_analisis_fisico"),
      id_calidad_analisis_sensorial: getCalidadLS(
        "id_calidad_analisis_sensorial"
      ),
      id_cuenta: getCalidadLS("id_cuenta"),
      id_acopio: getCalidadLS("id_acopio"),
      id_lote: getCalidadLS("id_lote"),
      tipo_analisis: getCalidadLS("tipo_analisis"),
      analisis_por_entrega: getCalidadLS("analisis_por_entrega"),
      nombre_proveedor: getCalidadLS("nombre_proveedor"),
      region_proveedor: getCalidadLS("region_proveedor"),
      notas_adicionales: getCalidadLS("notas_adicionales"),
      id_municipio: getCalidadLS("id_municipio"),
      id_localidad: getCalidadLS("id_localidad"),
    };
    configAnalisis.tipo_analisis = tipoAnalisis;
    switch (tipoAnalisis) {
      case 1:
        configAnalisis.id_cuenta = idCuenta;
        configAnalisis.analisis_por_entrega = analisisPorEntrega;
        configAnalisis.id_acopio = idsSeleccionados.join();
        break;
      case 2:
        configAnalisis.id_lote = idLote;
        break;
      case 3:
        configAnalisis.id_municipio = idMunicipio;
        configAnalisis.id_localidad = (idLocalidad.charAt(0) === ',') ? idLocalidad.substring(1) : idLocalidad;
        break;
      case 4:
        configAnalisis.nombre_proveedor = getValues().nombre_proveedor;
        configAnalisis.region_proveedor = getValues().region_proveedor;
        configAnalisis.notas_adicionales = getValues().notas_adicionales;
        break;
    }
    localStorage.setItem(
      `${env.appName}-calidad`,
      JSON.stringify(configAnalisis)
    );
  };


  const handlerSelectChange = (value) => {
    setIdLocalidad(value.join());
    setValue("id_localidad", value.join());
  };


  const handleCheck = event => {
    let idsSel = idsSeleccionados;
    if (event.target.checked === true) {
      setIdsSeleccionados([...idsSel, event.target.value]);
    } else {
      idsSel = idsSel.filter(item => item !== event.target.value);
      setIdsSeleccionados(idsSel);
    }
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/calidad">Calidad</Link>
          </li>
          <li className="breadcrumb-item active">Selección</li>
        </ol>
      </Breadcrumb>

      <CardContainer height={"100%"} style={{ marginTop: 10 }}>
        <div className="row">
          <div className="col-md-12">
            <h5>{"Registro de análisis de muestra"}</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-2 col-lg-3">
            <input
              type="radio"
              name="tipo_analisis"
              value={1}
              checked={Number(tipoAnalisis) === 1}
              onChange={() => {
                setValue("tipo_analisis", 1);
                setTipoAnalisis(1);
              }}
            />{" "}
            Por productor
            <br />
            <input
              type="radio"
              name="tipo_analisis"
              value={2}
              checked={Number(tipoAnalisis) === 2}
              onChange={() => {
                setValue("tipo_analisis", 2);
                setTipoAnalisis(2);
                setIdCuenta("");
              }}
            />{" "}
            Por lote
          </div>
          <div className="col-xl-2 col-lg-3">
            <input
              type="radio"
              name="tipo_analisis"
              value={1}
              checked={Number(tipoAnalisis) === 3}
              onChange={() => {
                setValue("tipo_analisis", 3);
                setTipoAnalisis(3);
                setIdCuenta("");
              }}
            />{" "}
            Por región
            <br />
            <input
              type="radio"
              name="tipo_analisis"
              value={2}
              checked={Number(tipoAnalisis) === 4}
              onChange={() => {
                setValue("tipo_analisis", 4);
                setTipoAnalisis(4);
                setIdCuenta("");
              }}
            />{" "}
            Otros proveedores
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-6 col-lg-6 col-md-12">
            {tipoAnalisis === 1 && (
              <div className={errors.id_cuenta ? "danger" : null}>
                <Select
                  components={{ DropdownIndicator }}
                  styles={customStyles}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Seleccione productor"
                  noOptionsMessage={() => "No hay elementos por mostrar"}
                  options={cuentas}
                  value={
                    cuentas.filter(
                      (lote) => Number(lote.value) === Number(idCuenta)
                    )?.[0]
                  }
                  onChange={handleChange}
                />
              </div>
            )}
            {tipoAnalisis === 2 && (
              <div className={errors.id_lote ? "danger" : null}>
                <Select
                  components={{ DropdownIndicator }}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={true}
                  placeholder="Seleccione lote"
                  noOptionsMessage={() => "No hay elementos por mostrar"}
                  options={lotes}
                  value={
                    lotes.filter(
                      (lote) => Number(lote.value) === Number(idLote)
                    )?.[0]
                  }
                  onChange={handleChangeLote}
                />
              </div>
            )}
          </div>
        </div>

        {cuentasOriginal.filter((cuenta) => Number(cuenta.id) === Number(idCuenta)).length > 0 && (
          <div className="row mt-2">
            <div className="col-md-6">
              <CardProductor
                blocking={false}
                idCuenta={idCuenta}
                fotoURL={null}
                parcelas={1}
                hectareas={5}
                plantas={10}
              />
            </div>
            <CardProductorHuertas idCuenta={idCuenta} />
          </div>
        )}

        {idCuenta > 0 && (
          <>
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <p className="titulo-pregunta">
                  {"¿La muestra es de una entrega determinada?"}
                </p>
                <input
                  type="radio"
                  name="analisis_por_entrega"
                  value={2}
                  checked={Number(analisisPorEntrega) === 2}
                  onChange={() => {
                    setValue("analisis_por_entrega", 2);
                    setAnalisisPorEntrega(2);
                  }}
                />{" "}
                No
                <br />
                <input
                  type="radio"
                  name="analisis_por_entrega"
                  value={1}
                  checked={Number(analisisPorEntrega) === 1}
                  onChange={() => {
                    setValue("analisis_por_entrega", 1);
                    setAnalisisPorEntrega(1);
                  }}
                />{" "}
                Si
              </div>
            </div>

            {analisisPorEntrega === 1 && (
              <div className="row">
                <div className="col-md-12">
                  <NoPagination
                    blocking={blocking}
                    selected="id"
                    columns={columns}
                    data={data}
                    fnBackgroundColor={getBackgroundColor}
                    fnColor={getColor}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {tipoAnalisis === 2 && (
          <div className="row mt-3">
            <div className="col-md-12">
              <NoPagination
                blocking={false}
                selected="id"
                columns={columnsLotes}
                data={dataLotes}
              />
              {idLote > 0 && (<CardLote idLote={idLote} />)}
            </div>
          </div>
        )}

        {tipoAnalisis === 3 && (
          <>
            <div className="row">
              <div className="col-xl-3 col-lg-6">
                <div className={errors.id_municipio ? "danger" : null}>
                  <FormSelectMaterialApi
                    id="id_municipio"
                    name="id_municipio"
                    label="Elige un municipio"
                    url={"cooperativas_municipios"}
                    keyArray="id"
                    labelArray="nombre"
                    placeholder="Elige un municipio"
                    register={register}
                    onChange={(e) => {
                      setIdMunicipio(e.target.value);
                      setValue("id_municipio", e.target.value);
                      handlerSelectChange([]);
                    }}
                    value={idMunicipio}
                    apiSearch={false}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">

                <FormSelectMaterialApi
                  placeholder="Elige una o varias localidades"
                  label="Elige una o varias localidades"
                  id="id_localidad"
                  name="id_localidad"
                  error={errors.id_localidad ? true : false}
                  url={"localidades_mununicipio/" + idMunicipio}
                  keyArray="id"
                  labelArray="nombre"
                  apiSearch={false}
                  register={register}
                  value={getValues().id_localidad.split(',')}
                  onChange={(e) => handlerSelectChange(e.target.value)}
                  multiple={true}
                />

              </div>
            </div>
            {idLocalidad.length > 0 && <CardLocalidad idLocalidad={idLocalidad} />}
          </>
        )}

        {tipoAnalisis === 4 && (
          <>
            <div className="row mt-3">
              <div className="col-md-3">
                <FormInputMaterialTest
                  type="text"
                  id="nombre_proveedor"
                  name="nombre_proveedor"
                  label=""
                  placeholder="Nombre del proveedor"
                  error={errors.nombre_proveedor}
                  register={register}
                  value={getValues().nombre_proveedor}
                />
              </div>
              <div className="col-md-3">
                <FormInputMaterialTest
                  type="text"
                  id="region_proveedor"
                  name="region_proveedor"
                  label=""
                  placeholder="Región"
                  error={errors.region_proveedor}
                  register={register}
                  value={getValues().region_proveedor}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-10">
                <FormInputMaterialTest
                  type="text"
                  id="notas_adicionales"
                  name="notas_adicionales"
                  label=""
                  placeholder="Descripción / notas adicionales"
                  error={errors.notas_adicionales}
                  register={register}
                  value={getValues().notas_adicionales}
                  multiline
                />
              </div>
            </div>
          </>
        )}

        <div className="row mt-5">
          <div className="col-md-12">
            <button
              style={{
                borderRadius: "20px 20px 20px 20px",
                color: "#333",
                fontWeight: "normal",
                fontSize: 16,
              }}
              className="btn btn-sirio"
              onClick={() => validateHandler()}
            >
               {'Continuar'} 
            </button>
          </div>
        </div>
      </CardContainer>
    </div>
  );
};

export default CalidadSeleccion;
