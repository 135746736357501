
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../../../api/global.service.js';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from "yup";
import { publish } from '../../../../components/events.js';
import FormSelectMaterial from "../../../../nuup/componentes/Forms/FormSelectMaterial.jsx";
import TextField from '@mui/material/TextField';

const ModalCambiarEstado = forwardRef((props, ref) => {

	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [idCiclo, setIdCiclo] = useState(0);
	const [idEstado, setIdEstado] = useState(0);
	const [fechaActualizacion, setFechaActualizacion] = useState('');
	const [estados, setEstados] = useState([]);

	const [errors, setErrors] = useState(null);
	const schema = yup.object().shape({
		id_estado: yup.number()
			.integer("Debe seleccionar un estado")
			.min(1, "Debe seleccionar un estado")
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Debe seleccionar un estado"),
		fecha_actualizacion: yup.string().required('Debe seleccionar una fecha')
	})

	//To call outside
	useImperativeHandle(ref, () => ({
		show: showHandler,
		setIdCiclo: (idCiclo) => setIdCiclo(idCiclo)
	}));

	useEffect(() => {
		getEstados();
	}, []);


	const getEstados = () => {
		Api.getAll('acopios/catalogos/recoleccion_acopios_estatus').then(response => {
		  let estados = response.data; 
		  setEstados(estados.map(function (estado) {return {label : estado.nombre,value : estado.id}}))
		})
		.catch(e => {
		  // eslint-disable-next-line no-console
		});
	  }  

	const showHandler = () => {
		setShow(true);
		setErrors(null);
	}

	const closeHandler = () => {
		setShow(false);
	}

	const validateHandler = () => {

		var data = {
			id_estado: idEstado,
			fecha_actualizacion: fechaActualizacion
		}
		var that = this;
		schema.validate(data, { abortEarly: false })
			.then(function (valid) {
				//Datos validos
				const data = {
					id_estado: idEstado,
					fecha_actualizacion: fechaActualizacion,
					entregas: props.entregas
				}


				Api.Acopio.create('cambiar_estado', data).then(response => {
					if (response.data.success) {
						setBlocking(false);
						setShow(false);
						publish('CambioEstado::onSave', { ...response.data.data, id_ciclo: idCiclo });
					} else {
						let err = response.data.data;
						setErrors(err);
					}
				})
					.catch(e => {
						setBlocking(false);
					});

			})
			.catch(function (err) {
				let errors = err.inner.reduce(function (map, obj) {
					map[obj.path] = obj.message;
					return map;
				}, {});
				setErrors(errors);
			});

	}




	return (
		<div className="modal" style={{ display: (show) ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
			<div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
				<div className="modal-content" style={{ height: '100%' }}>
					<div className="modal-header" style={{ flex: 'inherit' }}>
						<h4 className="modal-title">{props.title}</h4>
						<button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
							<i className="fa fa-times"></i>
						</button>
					</div>

					<div className="modal-body">

						<div className="row">
							<div className="col-12 mt-3">
								<TextField type="date" name="fecha_actualizacion" error={errors?.fecha_actualizacion} label="Fecha actualización" className="form-control" value={fechaActualizacion} onChange={(event) => { setFechaActualizacion(event.target.value) }} InputLabelProps={{ shrink: true }} />
							</div>
							<div className="col-12 mt-4"></div>
						</div>

						<div className="row my-4">
							<div className="col-12">
								<FormSelectMaterial id="estado" error={errors?.id_estado} label={'Estado'} value={idEstado} onChange={setIdEstado} options={estados} showAll={true} showAllLabel='Elige estado' />
							</div>
							<div className="col-12">
								<span>Selecciona una opción</span>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						{blocking && (
							<div className="spinner-border text-warning" role="status">
								<span className="sr-only">Guardando...</span>
							</div>
						)}
						{!blocking && (
							<>
								<button onClick={() => closeHandler()} type="button" className="btn btn-outline-secondary" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-times"></i> Cancelar
								</button>
								<button onClick={() => validateHandler()} type="button" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px 20px 20px 20px' }}>
									<i className="fa fa-save"></i> Guardar
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);

});

export default ModalCambiarEstado;