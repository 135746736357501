import React, { useState, useEffect } from "react";
import env from "../../../environment";
import Api from '../../../api/global.service';
import BlockUi from "react-block-ui";
import { getUsaSic } from '../../content/coop-config';

const CuentasResumenTotales = props => {
  const [data, setData] = useState([]);
  const [blocking, setBlocking] = useState(true);
  // const [usaSic] = useState(getUsaSic());

  useEffect(() => {
    if(props.id_ciclo > 0){getData();}
  }, [props.id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/productores/totales', {
      filter: [{ column: 'id_ciclo', operator: '=', value: props.id_ciclo }],
    }).then(response => {
      setData(response.data);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  }

  return (
    <BlockUi
      tag="div"
      message=""
      blocking={blocking}
      renderChildren={true}
    >
      <div className="col-xl-12 col-md-12">
        <div className="row card-category-dashboard" style={{ height: Number(props.usaSic) === 1 ? 167 : 167 * 2 }}>
          <div className="col-xl-12 col-md-12">
            <span className="dashboard-total-parcelas-has">{data[0]?.total_productores}</span>
            <span className="dashboard-titulo-parcelas-has">Total productores</span>
          </div>
        </div>
        {props.usaSic === 1 && (
          <>
            <div className="row card-category-dashboard" style={{ height: 167 }}>
              <div className="col-xl-12 col-md-12">
                <span className="dashboard-total-parcelas-has">{data[0]?.total_productores_padron}</span>
                <span className="dashboard-titulo-parcelas-has">Total productores en padrón</span>
              </div>
            </div>
          </>
        )}
      </div>
    </BlockUi>
  );
};

export default CuentasResumenTotales;
