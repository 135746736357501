import React, { useState, useEffect } from 'react';

const SelectOption = ({ data, setFilter, style, className = '', defaultValue = -1 }) => {
  const [value, setValue] = useState(-1);

  useEffect(() => {
    setValue(Number(defaultValue));
  }, [defaultValue]);

  const handlerChange = value => {
    let obj = data.filter(function(item) {
      return item.value == value;
    });

    setValue(value);
    setFilter({ value: value, target: obj[0] });
  };

  return (
    <select
      className={'mr-4' + className}
      value={value}
      onChange={e => {
        handlerChange(parseInt(e.target.value));
      }}
      style={{
        width: '200px',
        borderRadius: '5px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        padding: '8px',
        borderColor: '#DADADA',
        backgroundColor: 'none',
        ...style,
      }}>
      <option key={-1} value={-1} disabled>
        Seleccione una opción
      </option>
      {data.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectOption;
