import React, { useState, useEffect } from 'react';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';

const FormSelectApi = ({ url, id, label, name, error, keyArray, onChange, register, labelArray, filter = false, column = false, value = false, placeholder = false }) => {
  const fontSize = 12;
  const [items, setItems] = useState([]);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    filter ? 
    Api.search(url, {
      filter: [{ column: column, operator: '=', value: value }],
    })
      .then(response => {
        setBlocking(false);
        setItems(response.data);
      })
      .catch(function (error) {
        setBlocking(false);
      }) :
      Api.getAll(url).then(response => {
        setBlocking(false);
        setItems(response.data);
      }).catch(function (error) {
        setBlocking(false);
      });
  }, [url, value]);

  return (
    <>

        <BlockUi tag="div" blocking={blocking}>
        <div className="input-group">
        <label style = {{marginLeft: '19px'}} htmlFor={id}>{label}</label>

        <select
           {...register(id)}
            id={id}
            name={name}
            className={`form-control ${error ? '_is-invalid' : ''}`}
            onChange={onChange}
            style={{
              borderRadius: '5px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              padding: '8px',
              borderColor: '#DADADA',
            }}
            >
            <option value={0}>{placeholder ||  '--- SELECCIONE ---'}</option>

            {items.map((item, index) => (
              <option key={index} value={item[keyArray]}>
                {item[labelArray]}
              </option>
            ))}
          </select>
          </div>
        </BlockUi>
      {error && <div className="invalid-feedback">{error.message}</div>}

    </>
  );
};
export default FormSelectApi;
