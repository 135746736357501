import React from 'react';
import LinkDownload from '../link/link-download';

class CardBasic extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="card-basic" style={{ ...this.props.style, height: this.props.height }}>
        <h1 className="font-lato-normal-600">{this.props.value}</h1>
        <h6 className="font-lato-normal-400">{this.props.label}</h6>
        {this.props.link && (
          <div className="d-flex justify-content-center">
            <LinkDownload
              isZip={this.props.isZip}
              url={this.props.link}
              label={this.props.isZip ? 'Descargar .ZIP' : 'Descargar .Csv'}></LinkDownload>
          </div>
        )}
      </div>
    );
  }
}

export default CardBasic;
