import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/content/auth.jsx';

const SelectCertificacion = ({ setFilter }) => {
  const auth = useAuth();
  const data = [
    { value: 'certimex', label: 'Certimex' },
    { value: 'mayacert', label: 'Mayacert' },
  ];
  const [value, setValue] = useState(0);

  useEffect(() => {
    var v = auth.getCertification();
    setValue(v);
    setFilter(v);
  }, []);

  return (
    <div style={{ width: '180px', height: '38px' }}>
      <select
        value={value}
        onChange={e => {
          setValue(e.target.value || 0);
          setFilter(e.target.value || 0);
        }}
        style={{
          width: '215px',
          borderRadius: '5px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          padding: '8px',
          borderColor: '#DADADA',
        }}>
        {data.map((option, i) => (
          <option key={i} value={option.value}>
            CERTIFICACION: {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectCertificacion;
