import React, { useState, forwardRef, useImperativeHandle } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ModalGeneral from './modal-general';
import TextForm from '../forms/text-form';
import NumberForm from '../forms/number-form';
import DateForm from '../forms/date-form';
import NameForm from '../forms/name-form';
import SelectForm from '../forms/select-form';
import LocationForm from '../forms/location-form';
import Subtitle1 from './subtitle-1';
import Subtitle2 from './subtitle-2';

const ModalPadron = (_, ref) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(null);
  const [formType, setFormType] = useState(null);

  useImperativeHandle(ref, () => ({
    editText: editText,
    editNumber: editNumber,
    editDate: editDate,
    editName: editName,
    editSelect: editSelect,
    editSelectLocalidad: editSelectLocalidad,
  }));

  const updateTitle = currentData => {
    let titleD = null;
    if (currentData.nombreParcela) {
      titleD = <Subtitle2 name={currentData.nombreProductor} parcela={currentData.nombreParcela} />;
      setTitle(titleD);
    } else {
      titleD = <Subtitle1 name={currentData.nombreProductor} />;
      setTitle(titleD);
    }
  };

  const initialSelect = (type, currentData) => {
    setFormType(type);
    setShow(true);
    setData(currentData);
    updateTitle(currentData);
  };

  const editNumber = currentData => initialSelect('number', currentData);
  const editText = currentData => initialSelect('text', currentData);
  const editDate = currentData => initialSelect('date', currentData);
  const editName = currentData => initialSelect('name', currentData);
  const editSelect = currentData => initialSelect('select', currentData);
  const editSelectLocalidad = currentData => initialSelect('localidad', currentData);

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    setShow(false);
  };

  const renderForm = () => {
    switch (formType) {
      case 'text':
        return (
          <TextForm
            initialValue={data?.oldValue}
            columnLabel={data?.columnName}
            idCuenta={data?.id_cuenta}
            column={data?.accessor}
            idForm={data?.id_sic_informacion_general}
            onClose={() => {
              closeHandler();
              data && typeof data.onClose === 'function' && data.onClose(false);
            }}
            showModal={show}
            maxChar={data?.max}
            idParcela={data?.id_parcela}
            setData={data?.setData}
          />
        );
      case 'name':
        return (
          <NameForm
            columnLabel={data?.columnName}
            nombresOriginal={data?.nombres}
            apellidoPaterno={data?.apellido_paterno}
            apellidoMaterno={data?.apellido_materno}
            idCuenta={data?.id_cuenta}
            column={data?.accessor}
            idForm={data?.id_sic_informacion_general}
            onClose={() => closeHandler()}
            showModal={show}
            maxChar={data?.max}
            setData={data?.setData}
          />
        );
      case 'number':
        return (
          <NumberForm
            initialValue={data?.oldValue}
            columnLabel={data?.columnName}
            idCuenta={data?.id_cuenta}
            idParcela={data?.id_parcela}
            column={data?.accessor}
            idCiclo={data?.id_ciclo}
            idForm={data?.id_sic_informacion_general}
            onClose={() => {
              closeHandler();
              data && typeof data.onClose === 'function' && data.onClose(false);
            }}
            showModal={show}
            min={data?.min}
            isIngeger={data?.isIngeger}
            setData={data?.setData}
          />
        );
      case 'date':
        return (
          <DateForm
            initialValue={data?.oldValue}
            columnLabel={data?.columnName}
            idCuenta={data?.id_cuenta}
            idParcela={data?.id_parcela}
            column={data?.accessor}
            idForm={data?.id_sic_informacion_general}
            onClose={() => closeHandler()}
            showModal={show}
            setData={data?.setData}
            // min={data?.min}
          />
        );

      case 'select':
        return (
          <SelectForm
            initialValue={data?.oldValue}
            columnLabel={data?.columnName}
            idCuenta={data?.id_cuenta}
            idParcela={data?.id_parcela}
            catalog={data?.catalog}
            keyLabel={data?.keyLabel}
            keyId={data?.keyId}
            column={data?.accessor}
            idCiclo={data?.id_ciclo}
            idForm={data?.id_sic_informacion_general}
            onClose={() => closeHandler()}
            showModal={show}
            setData={data?.setData}
          />
        );
      case 'localidad':
        return (
          <LocationForm
            idLocalidad={data?.id_localidad}
            idMunicipio={data?.id_municipio}
            columnLabel={data?.columnName}
            idCuenta={data?.id_cuenta}
            column={data?.accessor}
            onClose={() => closeHandler()}
            showModal={show}
            setData={data?.setData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalGeneral
      show={show}
      preTitle={title}
      onSave={validateHandler}
      loading={false}
      withFooter={false}
      onClose={() => closeHandler()}>
      {data && renderForm()}
    </ModalGeneral>
  );
};

export default forwardRef(ModalPadron);
