import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import { Link, useParams } from 'react-router-dom';
import LinkDownload from '../../components/link/link-download';
import { PanelCollapse } from '../../components/panel/panel-collapse.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import LabelDate from '../../components/label/label-date.jsx';
import env from './../../environment.js';
import Moment from 'moment';
// import BackButton from '../../components/link/back-button.jsx';
import NoPagination from '../../components/pagination/noPagination.jsx';
import generalCatchErrorsModal from '../../components/modal/modal-error';
import { useAuth } from '../../components/content/auth';
import CardProductor from '../../components/card/card-productor';
import { useCoopConfig } from '../../components/content/coop-config.jsx';
import transformWord from '../../utils/transformWord.js';
import useEtiquetas from '../../hooks/useEtiquetas.jsx';

const CuentaProfile = () => {
  const coopConfig = useCoopConfig();
  const { uuid } = useParams();
  const auth = useAuth();
  const [cuenta, setCuenta] = useState({});
  const cicloSirio = localStorage.getItem('Sirio-ciclo_nombre');
  const [blocking, setBlocking] = useState(false);
  const [dataParcelas, setDataParcelas] = useState([]);
  const [blockingParcelas, setBlockingParcelas] = useState(false);
  const [dataAcopios, setDataAcopios] = useState([]);
  const [blockingAcopios, setBlockingAcopios] = useState(false);
  const [dataFormatos, setDataFormatos] = useState([]);
  const [blockingFormatos, setBlockingFormatos] = useState(false);
  const [dataEvaluaciones, setDataEvaluaciones] = useState([]);
  const [blockingEvaluaciones, setBlockingEvaluaciones] = useState(false);
  const bottomHeight = '100%';
  const [fotoURL, setFotoURL] = useState(null);
  const { etiquetas } = useEtiquetas();

  const columnsParcelas = React.useMemo(() => {
    let columns = [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 65,
      },
      {
        Header: 'NOMBRE DE ' + transformWord(etiquetas?.parcela, ['singular', 'uppercase']),
        accessor: 'nombre',
        sortable: true,
        width: 'auto',
        Cell: ({ value, row }) => {
          if (row.original.baja_parcela != null) {
            return (
              <>
                <p>
                  <s>{value} </s>
                </p>
                <p>
                  (Razón de baja: {row.original.baja_parcela} el {Moment(row.original.baja_parcela_fecha).format('yyyy-MM-DD')} )
                </p>
              </>
            );
          } else {
            return <p>{value}</p>;
          }
        },
      },
      {
        Header: 'SUPERFICIE (HA)',
        accessor: 'superficie',
        sortable: true,
        width: 'auto',
        Cell: ({ value }) => {
          return <LabelNumber value={value} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'N° DE ' + transformWord(etiquetas?.planta, ['singular', 'uppercase']),
        accessor: 'plantas',
        sortable: true,
        width: 'auto',
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
    ];

    if (coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE) {
      // café columns
      columns.push(
        {
          Header: 'VARIEDADES',
          accessor: 'variedades_str',
          sortable: true,
        },
        {
          Header: 'SOMBRA',
          accessor: 'sombra',
          sortable: true,
        }
      );
    } else if (coopConfig.config?.cooperativa?.producto?.id === env.productos.MIEL) {
      // miel columns
      columns.push({
        Header: 'ESTIMACIÓN DE COSECHA',
        accessor: 'estimacion_cosecha',
        sortable: true,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      });
      columns.push({
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        width: 'auto',
        Cell: ({ value }) => {
          return <p className="text-center">{value}</p>;
        },
      });
    }

    if (
      coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE ||
      coopConfig.config?.cooperativa?.producto?.id === env.productos.CACAO
    ) {
      // map geo
      columns.push({
        Header: 'CROQUIS',
        accessor: 'croquis',
        sortable: true,
        minWidth: 60,
        Cell: ({ row }) => {
          return (
            <Link to={{ pathname: `/admin/parcelas/perfil/print/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
              <img src={env.images.icon.pdf} className="pr-1" /> <span>Ver croquis</span>
            </Link>
          );
        },
      });
    }

    return columns;
  }, [coopConfig, etiquetas]);

  const columnsAcopios = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id_acopio',
        sortable: false,
        width: 65,
      },
      {
        Header: 'FECHA DE ENTREGA',
        accessor: 'fecha_acopio',
        sortable: true,
        minWidth: 80,
        Cell: ({ value }) => {
          return <LabelDate value={value}></LabelDate>;
        },
      },
      {
        Header: 'PESO NETO (KGS)',
        accessor: 'peso_neto',
        sortable: true,
        minWidth: 50,
        Cell: ({ value }) => {
          return <LabelNumber value={value} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'PRECIO A PRODUCTOR ($/KG)',
        accessor: 'precio_por_kg',
        sortable: true,
        minWidth: 50,
        Cell: ({ value }) => {
          return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'IMPORTE TOTAL ($)',
        accessor: 'importe_total',
        sortable: true,
        minWidth: 50,
        Cell: ({ value }) => {
          return <LabelNumber value={value} currency={true} digits={2}></LabelNumber>;
        },
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <p style={{ margin: 0 }}>{value}</p>;
        },
      },
    ],
    []
  );

  const columnsFormatos = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: false,
        width: 65,
      },
      {
        Header: 'FECHA DE LEVANTAMIENTO',
        accessor: 'fecha',
        sortable: true,
      },
      {
        Header: 'TIPO DE FORMATO',
        accessor: 'tipo',
        sortable: true,
      },
      {
        Header: 'DESCARGAR PDF',
        accessor: 'pdf',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <LinkDownload
              url={`pdf/${row.original.reporte}/${row.original.uuid}`}
              label="Descargar"
              icon={env.images.icon.pdf}></LinkDownload>
          );
        },
      },
      {
        Header: 'DESCARGAR EXCEL',
        accessor: 'xls',
        sortable: true,
        Cell: ({ row }) => {
          return (
            <LinkDownload
              url={`xls/${row.original.reporte}/${row.original.uuid}`}
              label="Descargar"
              icon={env.images.icon.xls}></LinkDownload>
          );
        },
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <p style={{ margin: 0 }}>{value}</p>;
        },
      },
    ],
    []
  );

  const columnsEvaluaciones = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: '_no',
        sortable: false,
        width: 45,
        Cell: ({ value }) => {
          return <LabelNumber value={value}></LabelNumber>;
        },
      },
      {
        Header: 'CONDICIÓN',
        accessor: 'condicion_str',
        sortable: true,
      },
      // {
      //   Header: 'CONDICIONES TOTALES',
      //   accessor: 'cond_total',
      //   sortable: true,
      //   Cell: ({ value }) => {
      //     return <LabelNumber value={value}></LabelNumber>;
      //   },
      // },
      // {
      //   Header: 'CONCIDICIONES CUMPLIDAS',
      //   accessor: 'cond_cumplidas',
      //   sortable: true,
      //   Cell: ({ value }) => {
      //     return <LabelNumber value={value}></LabelNumber>;
      //   },
      // },
      {
        Header: 'FECHA DE AUTORIZACIÓN',
        accessor: 'fecha_aut',
        sortable: true,
      },
      {
        Header: 'AUTORIZÓ',
        accessor: 'user_auth',
        sortable: true,
      },
      {
        Header: 'NIVEL DE RIESGO',
        accessor: 'nivel_riesgo',
        sortable: true,
      },
      {
        Header: 'VER EVALUACIÓN',
        accessor: 'ver',
        sortable: true,
        Cell: ({ row }) => (
          <Link to={{ pathname: `/admin/comite/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
            <img src={env.images.icon.xls} className="pr-1" /> <span>Ver</span>
          </Link>
        ),
      },
      {
        Header: 'EDITAR',
        accessor: 'editar',
        sortable: true,
        Cell: ({ row }) =>
          row.original.ciclo === cicloSirio ? (
            <>
              <Link to={{ pathname: `/admin/comites-editar/${row.original.uuid}` }} className="m-r-2" style={{ color: '#789D16' }}>
                <span>Editar</span>
              </Link>
            </>
          ) : null,
      },
      {
        Header: 'CICLO',
        accessor: 'ciclo',
        sortable: true,
        minWidth: 60,
        Cell: ({ value }) => {
          return <p style={{ margin: 0 }}>{value}</p>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (uuid != null) {
      setBlocking(true);
      setBlockingParcelas(true);
      setBlockingEvaluaciones(true);
      setBlockingFormatos(true);
      if (env.appEnv !== 'prod') {
        setBlockingAcopios(true);
      }
      Api.get('cuentas', uuid)
        .then(response => {
          response.data = response.data.map(function(item) {
            return {
              ...item,
              _fecha_ingreso: item.fecha_ingreso != null ? item.fecha_ingreso.substr(0, 4) : null,
            };
          });

          setCuenta(response.data[0]);
          setBlocking(false);

          Api.get('parcelas_por_productor', uuid)
            .then(response => {
              response.data = response.data.map(function(item, i) {
                return {
                  ...item,
                  _no: i + 1,
                };
              });
              setDataParcelas(response.data);
              const fotoURL = response.data?.filter(p => p.newest_foto !== null)?.[0].newest_foto;
              setFotoURL(fotoURL);
              setBlockingParcelas(false);
            })
            .catch(_ => {
              setBlockingParcelas(false);
            });

          if (auth.hasPermission('ACOPIOS_DASHBOARD_VER')) {
            Api.Acopio.search('entregas_por_productor', {
              filter: [{ column: 'productor_id', operator: '=', value: response.data[0].id }],
              sort: [{ column: 'fecha_acopio', sort: 'desc' }],
            })
              .then(response => {
                response.data = response.data.map(function(item, i) {
                  return {
                    ...item,
                    _no: i + 1,
                  };
                });
                setDataAcopios(response.data);
                setBlockingAcopios(false);
              })
              .catch(e => {
                // eslint-disable-next-line no-console
                // console.log('e', e);
                setBlockingAcopios(false);
              });
          }

          Api.search('tx_formatos', {
            filter: [{ column: 'id_cuenta', operator: '=', value: response.data[0].id }],
          })
            .then(response => {
              response.data = response.data.map(function(item, i) {
                return {
                  ...item,
                  _no: i + 1,
                };
              });
              setDataFormatos(response.data);
              setBlockingFormatos(false);
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              // console.log('e', e);
              setBlockingFormatos(false);
            });
          Api.search(`comite_evaluaciones`, {
            filter: [{ column: 'id_cuenta', operator: '=', value: response.data[0].id }],
          })
            .then(response => {
              const uniqueData = response.data.reduce((acc, item) => {
                if (!acc.some(existingItem => existingItem.id === item.id)) {
                  acc.push({ ...item, _no: acc.length + 1 });
                }
                return acc;
              }, []);
              setDataEvaluaciones(uniqueData);
              setBlockingEvaluaciones(false);
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              // console.log('e', e);
              setBlockingEvaluaciones(false);
            });
        })
        .catch(e => {
          if (e.name !== 'AbortError') {
            generalCatchErrorsModal(e.response?.status, '/admin/cuentas/list');
            setBlocking(false);
            setBlockingParcelas(false);
            setBlockingAcopios(false);
            setBlockingFormatos(false);
            setBlockingEvaluaciones(false);
          }
        });
    }
    return () => abortController.abort();
  }, [uuid]);

  const handleGoTo = () => {
    window.location.href = '/admin/cuentas/list';
  };

  return (
    <>
      <div>
        <Breadcrumb>
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/admin/cuentas">Listado productores</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/cuentas/list">Listado productores</Link>
            </li>
            <li className="breadcrumb-item active">Perfil productor</li>
          </ol>
          <button onClick={handleGoTo} type="button" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px' }}>
            <i className="fa fa-arrow-left mr-2"></i> {'Volver al listado'}
          </button>
        </Breadcrumb>
        {/* Top heading profile */}
        <CardProductor
          blocking={blocking}
          cuenta={cuenta}
          fotoURL={fotoURL}
          parcelas={dataParcelas
            .reduce((acc, p) => (!p.baja_parcela ? ++acc : acc), 0)
            .toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          hectareas={dataParcelas
            .reduce((acc, p) => (!p.baja_parcela && !isNaN(parseFloat(p.superficie)) ? acc + parseFloat(p.superficie) : acc), 0)
            .toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          plantas={dataParcelas
            .reduce((acc, p) => (!p.baja_parcela && !isNaN(parseInt(p.plantas)) ? acc + parseInt(p.plantas) : acc), 0)
            .toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          dataParcelas={dataParcelas}
          etiquetas={etiquetas}
          producto={coopConfig.config?.cooperativa?.producto?.id}
        />
        {/* body, table sections */}
        <div className="row pb-4">
          <div className="col-xl-12">
            <CardContainer height={bottomHeight}>
              <div className="row">
                <div className="col-xl-12">
                  <PanelCollapse title={etiquetas.parcela == 'apiario' ? 'APIARIOS' : 'PARCELAS'}>
                    <NoPagination blocking={blockingParcelas} columns={columnsParcelas} data={dataParcelas} sticky={false}></NoPagination>
                  </PanelCollapse>
                </div>
                {auth.hasPermission('ACOPIOS_DASHBOARD_VER') && (
                  <div className="col-xl-12">
                    <PanelCollapse title="ACOPIO">
                      <NoPagination blocking={blockingAcopios} columns={columnsAcopios} data={dataAcopios} sticky={false}></NoPagination>
                    </PanelCollapse>
                  </div>
                )}
                {coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE && (
                  <div className="col-xl-12">
                    <PanelCollapse title="FORMATOS">
                      <NoPagination blocking={blockingFormatos} columns={columnsFormatos} data={dataFormatos} sticky={false}></NoPagination>
                    </PanelCollapse>
                  </div>
                )}
              </div>
            </CardContainer>
          </div>
        </div>
        {coopConfig.config?.cooperativa?.producto?.id === env.productos.CAFE && (
          <div className="row pb-4">
            <div className="col-xl-12">
              <CardContainer height={bottomHeight}>
                <div className="row">
                  <div className="col-xl-12">
                    <PanelCollapse title="COMITÉ DE EVALUACIÓN">
                      <NoPagination
                        blocking={blockingEvaluaciones}
                        columns={columnsEvaluaciones}
                        data={dataEvaluaciones}
                        sticky={false}></NoPagination>
                      <Link to={{ pathname: `/admin/cuentas/comite/${uuid}` }}>
                        <button type="button" className="btn btn-outline-secondary btn-sirio mt-4" style={{ borderRadius: '20px' }}>
                          <i className="fa fa-plus mr-2"></i> Añadir nueva evaluación
                        </button>
                      </Link>
                    </PanelCollapse>
                  </div>
                </div>
              </CardContainer>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CuentaProfile;
