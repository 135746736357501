import React from "react";
import { useFieldArray } from "react-hook-form";
import {ReactHookFormSelect} from "../../nuup/componentes/Forms/ReactHookFormSelect.jsx";
import {ReactHookFormInput} from "../../nuup/componentes/Forms/ReactHookFormInput.jsx";

const CalidadAnalisisFisicoZarandasDefectos = ({
  nestIndex,
  type,
  control,
  register,
  setValue,
  getValues,
  errors,
}) => {
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: `zarandas[${nestIndex}].${type}`,
  });

  const eliminaDefecto = (index) => {
    let id_calidad_analisis_fisicos_defecto = getValues().zarandas?.[nestIndex]?.[type]?.[index]?.id_calidad_analisis_fisicos_defecto;
    if(id_calidad_analisis_fisicos_defecto > 0){
      setValue(`zarandas[${nestIndex}].${type}.[${index}].eliminar`, 1);
    }else{
      remove(index);
    }
  }

  return (
    <>
      {fields.map((item, k) => {
        return (
          <div style={{display : getValues().zarandas?.[nestIndex]?.[type]?.[k]?.eliminar === 1 ? 'none' : 'block'}}>
          <div className="row mt-1">
            <div className="col-lg-12 mt-2">

              <span style={{fontSize : 14,fontWeight : 'bold'}}>
                {"Defectos " +
                  (type === "defectos_primarios" ? "primarios" : "secundarios")}
              </span>
            </div>

            <div className="col-xs-6 col-xl-5 col-xxl-4 mt-2">
              <ReactHookFormSelect
                placeholder="Tipo de mancha / defecto"
                label="Tipo de mancha / defecto"
                options={[]}
                id={`zarandas[${nestIndex}].${type}.[${k}].id_tipo_mancha`}
                name={`zarandas[${nestIndex}].${type}.[${k}].id_tipo_mancha`}
                error={errors.zarandas?.[nestIndex]?.[type]?.[k]?.id_tipo_mancha ? true : false}
                url={
                  "calidad/catalogos/tipo_manchas/" +
                  (type === "defectos_primarios" ? 1 : 2)
                }
                keyArray="id"
                labelArray="nombre"
                value={getValues().zarandas?.[nestIndex]?.[type]?.[k]?.id_tipo_mancha}
                apiSearch={false} 
                control={control}
              />
              {errors?.zarandas?.[nestIndex]?.[type]?.[k]?.id_tipo_mancha && (
                <div className="invalid-feedback">
                  {errors?.zarandas?.[nestIndex]?.[type]?.[k]?.id_tipo_mancha.message}
                </div>
              )}
            </div>

            <div className="col-xs-3 col-xl-3 col-xxl-3">
              <ReactHookFormInput
                type="number"
                id={`zarandas.${nestIndex}.${type}.${k}.no_granos`}
                name={`zarandas.${nestIndex}.${type}.${k}.no_granos`}
                error={errors.zarandas?.[nestIndex]?.[type]?.[k]?.no_granos}
                label="Valor (granos)"
                value={getValues().zarandas?.[nestIndex]?.[type]?.[k]?.no_granos}
                control={control}
              />
            </div>
            <div className="col-xs-3 col-xl-3 col-xxl-3">
              <ReactHookFormInput
                type="number"
                id={`zarandas.${nestIndex}.${type}.${k}.gramos`}
                name={`zarandas.${nestIndex}.${type}.${k}.gramos`}
                error={errors.zarandas?.[nestIndex]?.[type]?.[k]?.gramos}
                label="Gramos"
                value={getValues().zarandas?.[nestIndex]?.[type]?.[k]?.gramos}
                control={control}
              />
            </div>

            {k > 0 && (
              <div className="col-xs-1 col-xl-1 col-xxl-1">
                <button
                  className="btn btn-outline-danger mx-1"
                  type="button"
                  onClick={() => eliminaDefecto(k)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            )}
          </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-lg-6">
          <button
            type="button" 
            className="btn btn-outline-dark mt-3"
            onClick={() =>
              append({
                id_calidad_analisis_fisicos_defecto: 0,
                id_tipo_mancha: "",
                no_granos: "",
                gramos: "",
              })
            }
          >
            <i className="fa fa-plus"></i>
            {" Agregar"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CalidadAnalisisFisicoZarandasDefectos;
