import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ModalGeneral from './modal-general';
import ComiteForm from '../forms/comite-form';

const ModalComite = (_, ref) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  useImperativeHandle(ref, () => ({
    editText: editText,
  }));

  const editText = currentData => {
    setShow(true);
    setData(currentData);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const validateHandler = () => {
    setShow(false);
  };

  const title = (
    <p
      style={{
        fontWeight: 'bold',
        marginBottom: 0,
        fontSize: '17px',
      }}>
      {data?.condicion_str} en el ciclo {data?.ciclo}
    </p>
  );

  return (
    <ModalGeneral
      show={show}
      preTitle={title}
      onSave={validateHandler}
      loading={false}
      withFooter={false}
      onClose={() => closeHandler()}>
      {data && (
        <ComiteForm
          observCondicion={data?.observ_condicion}
          idDetalle={data?.id_detalle}
          plazo={data?.plazo}
          verifCumplido={data?.verif_cumplido}
          verifFecha={data?.verif_fecha}
          onClose={() => closeHandler()}
          showModal={show}
        />
      )}
    </ModalGeneral>
  );
};

export default forwardRef(ModalComite);
