import * as React from "react"

class BgSvg extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		
		return (
			<svg width="885" height="1117" viewBox="0 0 885 1117" fill="none" xmlns="http://www.w3.org/2000/svg" style={this.props.style}>
        <mask id="mask0_41621_26967" maskUnits="userSpaceOnUse" x="-3" y="0" width="888" height="1117">
          <rect x="-3" width="888" height="1117" fill="#fff"/>
        </mask>
        <g mask="url(#mask0_41621_26967)">
          <path d="M56.1374 866.678C328.027 806.25 1041.75 956.014 1364.63 1038.45L1429.37 1046.95L1406.52 1259.51C1088.12 1231.85 372.283 1114.56 56.1374 866.678Z" fill="#507736"/>          
          <path d="M1017.2 1008.45C1202.92 1006.36 1311.01 1053.34 1341.84 1077.09L1317.92 1276.02L-179.45 1202.32L-197.832 948.655C-210.261 915.277 -206.2 840.818 -90.525 810C25.1502 779.182 260.999 842.513 364.464 878.03C399.9 892.646 493.303 930.711 583.425 966.04C696.078 1010.2 785.047 1011.07 1017.2 1008.45Z" fill="#9EC640"/>
          <path d="M433.668 982.499C172.854 907.423 -27.5051 965.345 -95.0829 1003.69L-117 1149C311.297 1145.97 1165.7 1129.63 1156.93 1088.45C1145.97 1036.99 759.685 1076.35 433.668 982.499Z" fill="#C7AE34"/>
        </g>
      </svg>
		);
	}
}

export default BgSvg;