import React, { useState, useEffect } from 'react';
import Api from '../../api/global.service';
import BlockUi from 'react-block-ui';
import { useAuth } from '../../components/content/auth.jsx';

const SelectCentroAcopio = ({ className, setFilter }) => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [value, setValue] = useState(-1);
  const [blocking, setBlocking] = useState(false);

  useEffect(() => {
    setBlocking(true);

    Api.search('centros_acopios', {
      filter: [{ column: 'id_cooperativa', operator: '=', value: auth.user.id_cooperativa }],
    })
      .then(response => {
        setData(response.data);
        handlerChange(-1, response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  }, []);

  const handlerChange = (index, data) => {
    if (index == -1) {
      setValue(index);
      setFilter({ index: index, target: null });
    } else {
      let obj = data[index];
      setValue(index);
      setFilter({ index: index, target: obj });
    }
  };

  return (
    <div style={{ width: '300px', height: '38px' }} className={className}>
      <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
        <select
          value={value}
          onChange={e => {
            handlerChange(parseInt(e.target.value), data);
          }}
          style={{
            width: '300px',
            borderRadius: '5px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            padding: '8px',
            borderColor: '#DADADA',
          }}>
          <option value="-1">CENTRO DE ACOPIO: TODOS</option>
          {data.map((option, index) => (
            <option key={index} value={index}>
              CENTRO DE ACOPIO: {option.id} - {option.nombre}
            </option>
          ))}
        </select>
      </BlockUi>
    </div>
  );
};

export default SelectCentroAcopio;
