/**
 * Update the general and filtered data locally to avoid rendering
 * @param {any} newData
 * @param {function} setData
 * @param {number} rowIndex
 * @param {function} setOriginalData
 * @param {number} id_cuenta
 */
const updateLocalData = (newData, setData, rowIndex, setOriginalData = null, id_cuenta) => {
  setData(prevData => {
    return prevData.map((item, ind) => {
      if (ind === rowIndex) return { ...item, ...newData, updated: Object.keys(newData) };
      return item;
    });
  });
  setOriginalData &&
    setOriginalData(prevData => {
      return prevData.map(item => {
        if (item.id_cuenta === id_cuenta) return { ...item, ...newData, updated: Object.keys(newData) };
        return item;
      });
    });
};

export default updateLocalData;
