import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';
import Chip from '@mui/material/Chip';

export default function FormSelectMaterialApi({
  error = false,
  label = '',
  placeholder = '--- SELECCIONE ---',
  id,
  options = [],
  onChange,
  register,
  showAllLabel = 'TODOS',
  showAll = false,
  multiple = false,
  keyArray = 'id',
  labelArray = 'name',
  url,
  filter = [],
  sort = [],
  apiSearch = true,
  ...inputProps
}) {
  const height = 44;
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [blocking, setBlocking] = useState(true);
  //const defaultOption = { value: 0, label: placeholder, isDisabled: true };
  const defaultOption = {  };

  useEffect(() => {
    let params = {
      filter: filter ? filter : [],
      sort: sort ? sort : [],
    };

    if(apiSearch === true){
      Api.search(url, params).then(response => {
        setBlocking(false);
        let options = response.data.map(function(item) {
          return { value: item[keyArray], label: item[labelArray] };
        });
        setItems([defaultOption, ...options]);
      });
    }else{
      Api.getAll(url, params).then(response => {
        setBlocking(false);
        let options = response.data.map(function(item) {
          return { value: item[keyArray], label: item[labelArray] };
        });
        setItems([defaultOption, ...options]);
      });
    }


    // .catch(e => {});
  }, [url]);

  return (
    <BlockUi tag="div" blocking={blocking}>
    <FormControl fullWidth>
        <InputLabel id={id + '-label'}>{label}</InputLabel>
        <Select
          labelId={id + '-label'}
          id={id}
          multiple={multiple}
          value={inputProps.value}
          defaultValue={inputProps.value}
          onChange={onChange}
          error={error}
          style={{ height, minWidth : 100 }}
          label={label}
        >
          {items.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
      {error && <div className="invalid-feedback">{error}</div>}

    </BlockUi>
  );
}