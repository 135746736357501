import React, { useState, useEffect } from "react";
import Api from "../../../api/global.service";

const CardProductorHuertas = ({ idCuenta }) => {
  const [dataParcelas, setDataParcelas] = useState([]);
  
  useEffect(() => {
    Api.get("parcelas_por_productor_ciclo", idCuenta)
      .then((response) => {
        response.data = response.data.map(function(item, i) {
          return {
            ...item,
            _no: i + 1,
          };
        });
        setDataParcelas(response.data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        // console.log('e', e);
      });
  }, [idCuenta]);
  return (
    <>
      {dataParcelas.map((parcela, index) => {
        return (
          <div className="col-md-3 col-lg-3 col-xxl-3">
            <div className="card-basic">
              <div className="" style={{ textAlign: "left" }}>
                <h5>
                  Parcela {parcela._no}: {parcela.superficie} ha
                </h5>
                <h3>{parcela.nombre}</h3>
                <p>Variedades: {parcela.variedades_str}</p>
                {parcela.altitud > 0 && (<p>Altitud: {parcela.altitud}</p>)}
                <p>Plantas: {parcela.plantas}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CardProductorHuertas;
