import React, { useState, useEffect } from 'react';
import env from '../../../environment';
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';

const ParcelasResumenTotales = ({ id_ciclo }) => {
  const [dataAll, setDataAll] = useState(0);
  const [dataGeo, setDataGeo] = useState(0);
  const [dataFicha, setDataFicha] = useState(0);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    if (id_ciclo > 0) {
      getData();
    }
  }, [id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboard/totales/parcelas/geos', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    }).then(response => {
      setDataAll(response.data[0]?.total_parcelas);
      setDataGeo(response.data[0]?.total_geo);
      setBlocking(false);
    });
    Api.search('dashboards/parcelas/totales', {
      filter: [{ column: 'id_ciclo', operator: '=', value: id_ciclo }],
    }).then(response => {
      if (!isNaN(response.data[0]?.parcelas_totales)) {
        setDataFicha(response.data[0]?.parcelas_totales);
      }
      setBlocking(false);
    });
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
      <div
        className="row card-graph mb-3 bg-white"
        style={{
          height: 106,
          borderRadius: '20px',
        }}>
        <div className="col-xl-12 col-md-12">
          <div className="row p-10 mt-2">
            <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
              <img src={env.images.card.p} className="pt-2" />
            </div>
            <div className="col-xl-9 col-md-9 p-1">
              <div className="font-lato-normal-400" style={{ fontSize: 14, paddingHorizontal: 0 }}>
                Total de Parcelas
              </div>
              <div className="font-lato-normal-600" style={{ fontSize: 32 }}>
                {dataAll.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row card-graph mb-3 bg-white"
        style={{
          height: 106,
          borderRadius: '20px',
        }}>
        <div className="col-xl-12 col-md-12">
          <div className="row p-10 mt-2">
            <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
              <img src={env.images.card.pd} className="pt-2" />
            </div>
            <div className="col-xl-9 col-md-9 p-1">
              <div className="font-lato-normal-400" style={{ fontSize: 14, paddingHorizontal: 0 }}>
                Parcelas geolocalizadas
              </div>
              <div className="font-lato-normal-600" style={{ fontSize: 32 }}>
                {dataGeo.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row card-graph mb-3 bg-white"
        style={{
          height: 106,
          borderRadius: '20px',
        }}>
        <div className="col-xl-12 col-md-12">
          <div className="row p-10 mt-2">
            <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
              <img src={env.images.icon.map} className="pt-2" />
            </div>
            <div className="col-xl-9 col-md-9 p-1">
              <div className="font-lato-normal-400" style={{ fontSize: 14, paddingHorizontal: 0 }}>
                Parcelas con ficha
              </div>
              <div className="font-lato-normal-600" style={{ fontSize: 32 }}>
                {dataFicha.toLocaleString('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default ParcelasResumenTotales;
