import React, { useState, useEffect, createRef } from "react";
import { useAuth } from "../../../components/content/auth.jsx";
import BlockUi from "react-block-ui";
import { subscribe, unsubscribe } from "../../../components/events";

const EntregasXProductorBuscador = (props) => {
  const auth = useAuth();
  const [blocking, setBlocking] = useState(true);
  const dialog = createRef();
  const [cuenta, setCuenta] = useState(null);


  useEffect(() => {
    subscribe("Entrega::Cuenta", (e) => handleCuenta(e));
    return () => {
      unsubscribe("Entrega::onReturn");
      unsubscribe("Entrega::Cuenta");
    };
  }, []);

  const handleCuenta = (event) => {
    setBlocking(false);
    setCuenta(event.detail);
  }

  return (
    <div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6">
        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <input
            name="productor"
            placeholder={cuenta?.productor_nombre}
            className="form-input form-control"
          />
              </BlockUi>
        </div>
    </div>
  );
};

export default EntregasXProductorBuscador;
