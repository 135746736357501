import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../../../api/global.service.js';
import 'react-datepicker/dist/react-datepicker.css';
import formatNumber from '../../../../utils/formatNumber.js';

import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';

import BlockUi from 'react-block-ui';
import env from '../../../../environment.js';

const ModalInfoLote = forwardRef((props, ref) => {
  const COLORS_DON = ['#FFEED9', '#BC8268'];

  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [idLote, setIdLote] = useState(0);
  const [dataGrafica, setDataGrafica] = useState([]);
  const [data, setData] = useState([]);

  const [errors, setErrors] = useState(null);

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdLote: id_lote => setIdLote(id_lote),
  }));

  useEffect(() => {
    setData([]);
    setDataGrafica([]);
    if (idLote > 0) {
      getData();
    }
  }, [idLote]);

  const showHandler = () => {
    setShow(true);
    setErrors(null);
  };

  const closeHandler = () => {
    setShow(false);
  };

  const getData = () => {
    setBlocking(true);
    Api.Acopio.get('lotes_info/' + idLote)
      .then(response => {
        setBlocking(false);
        setShow(true);
        if (typeof response.data[0] !== 'undefined') {
          setData(response.data[0]);
          setDataGrafica([
            { name: 'Totales', value: 100 },
            {
              name: 'Por entregar',
              value: (Number(response.data[0].lote_kgs_entregados) / Number(response.data[0].lote_kgs_totales)) * 100,
            },
          ]);
        } else {
          let err = response.data.data;
          setErrors(err);
        }
      })
      .catch(e => {
        setShow(false);
        setBlocking(false);
      });
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <strong>{'>>'}</strong>
            </button>
            <div className="modal-title">
              {data.lote_kgs_totales > 0 && (
                <>
                  <span className="lotes-kgs-total-label">{'Kgs totales de lote'}</span>{' '}
                  <span className="lotes-kgs-total-valor">{formatNumber(data?.lote_kgs_totales)}</span>
                </>
              )}
            </div>
          </div>
          <div className="modal-body">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              {data.lote_kgs_totales && (
                <ResponsiveContainer width="100%" height={'100%'}>
                  <>
                    {data.lote_kgs_totales > 0 && (
                      <div className="row">
                        <div className="col-6 offset-2">
                          <PieChart width={250} height={250}>
                            <Pie
                              data={dataGrafica}
                              cx={100}
                              cy={125}
                              innerRadius={60}
                              outerRadius={90}
                              fill="#8884D8"
                              paddingAngle={1}
                              dataKey="value">
                              {dataGrafica.map((entry, index) => (
                                <React.Fragment key={`cell-${index}`}>
                                  <Cell fill={COLORS_DON[index % COLORS_DON.length]} />
                                  <Label
                                    style={{ fontSize: 18 }}
                                    value={parseFloat(dataGrafica[1]?.value).toFixed(2) + '%'}
                                    position="center"
                                  />
                                </React.Fragment>
                              ))}
                            </Pie>
                          </PieChart>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-1 offset-3">
                        <img style={{ paddingTop: 20 }} src={env.images.card.circulo_BC8268} />
                      </div>

                      <div className="col-6">
                        <p className="lotes-kgs-entregados-valor">{formatNumber(data?.lote_kgs_entregados)}</p>
                        <p className="lotes-kgs-entregados-label">kgs entregados</p>
                      </div>
                    </div>

                    {data.lote_kgs_totales > 0 && (
                      <div className="row">
                        <div className="col-1 offset-3">
                          <img style={{ paddingTop: 20 }} src={env.images.card.circulo_FFEED9} />
                        </div>
                        <div className="col-6">
                          <p className="lotes-kgs-entregados-valor">{formatNumber(data?.lote_kgs_por_entregar)}</p>
                          <p className="lotes-kgs-entregados-label">kgs por entregar</p>
                        </div>
                      </div>
                    )}

                    {/* <hr />
								<div className='row'>
									<div className='col-6 pl-5'>
										<p className='lotes-kgs-entregados-valor'>{formatNumber(data?.lote_kgs_hoy)}</p>
										<p className='lotes-kgs-entregados-label'>kgs entregados hoy</p>
									</div>
									<div className='col-6'>
										<p className='lotes-kgs-entregados-valor'>{formatNumber(data?.lote_kgs_semana)}</p>
										<p className='lotes-kgs-entregados-label'>kgs entregados esta semana</p>
									</div>
								</div> */}
                  </>
                </ResponsiveContainer>
              )}
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalInfoLote;
