import React from 'react';
import Api from '../../api/global.service';
import { Link } from 'react-router-dom';

class LinkDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color || '#789D16',
      blocking: false,
    };
  }

  handleDownload(event) {
    this.setState({
      blocking: true,
    });

    event.preventDefault();
    // console.log('this.props.url!!!!', this.props.url);
    Api.download(this.props.url)
      .then(response => {
        let fileName;
        let url;
        if (this.props.isZip) {
          fileName = response.headers['content-disposition'].split(`'`)[2];
          url = window.URL.createObjectURL(new Blob([response.data], { type: 'octet/stream' }));
        } else {
          fileName = response.headers['content-disposition'].split('filename=')[1];
          url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!

        this.setState({
          blocking: false,
        });
      })
      .catch(e => {
        this.setState({
          blocking: false,
        });
      });
  }

  render() {
    return (
      <>
        {this.state.blocking && (
          <div className="d-flex justify-content-start">
            <div
              className="spinner-border text-warning mr-2"
              role="status"
              aria-hidden="true"
              style={{ height: 24, width: 24 }}></div>
            <span className="mt-1">Descargando ...</span>
          </div>
        )}

        {!this.state.blocking && (
          <Link to="#" onClick={e => this.handleDownload(e)} className="m-r-2" style={{ color: this.state.color }}>
            <img src={this.props.icon} className="pr-1" /> <span>{this.props.label}</span>
          </Link>
        )}
      </>
    );
  }
}

export default LinkDownload;
