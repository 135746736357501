import * as yup from 'yup';

  export const stateObject = {
    /* Global */
    'SET_CUENTA': 'cuenta',
    'SET_BLOCKING': 'blocking',
    'SET_BLOCKING_SAVE': 'blockingSave',
    'SET_VIEW': 'view',
    'SET_FECHA_AUT': 'fechaAut',
    'SET_DATA_CAT':'dataCat',
    'SET_NIVEL_RIESGO':'nivelRiesgo',
    'SET_NIVELES_RIESGO_DATA':'nivelesRiesgoData',
      /* 1.- Aprobado Sin Condiciones */
    'SET_APROB_SC_OBSERVACIONES': 'aprobSCObservaciones',
      /* 2.- Aprobado Con Condiciones */
    'SET_IS_NEW':'isNew',
    'SET_APROB_CC_LIST':'aprobCCList',
    'SET_CONDITION_CONDICION':'conditionCondicion',
    'SET_CONDITION_PLAZO':'conditionPlazo',
    'SET_CONDITION_VERIF_CUMPLIDO':'conditionVerifCumplido',
    'SET_CONDITION_VERIF_FECHA':'conditionVerifFecha',
      /* 3.- Sancionado */
    'SET_SANC_CAT_NUEVA':'sancCatNueva',
    'SET_SANC_CAT_ANTERIOR':'sancCatAnterior',
    'SET_SANC_FECHA_INICIO':'sancFechaInicio',
    'SET_SANC_FECHA_TERMINO':'sancFechaTermino',
    'SET_SANC_OBSERVACIONES':'sancObservaciones',
    'SET_ERRORS':'errors',
    'SET_ERRORS1':'errors1',
    'SET_ERRORS2':'errors2',
    'SET_ERRORS3':'errors3',
    'SET_ERRORS_CONDICION':'errorsCondicion',
    'SET_FIRMA':'firma',
  };

  export const initialState = {
    /* Global */
  cuenta: {},
  blocking: false,
  blockingSave: false,
  view: -1,
  fechaAut: null,
  dataCat: [],
  nivelRiesgo: null,
  nivelesRiesgoData: [],
      /* 1.- Aprobado Sin Condiciones */
  aprobSCObservaciones: '',
      /* 2.- Aprobado Con Condiciones */
  isNew: false,
  aprobCCList: [],
  conditionCondicion: '',
  conditionPlazo: null,
  conditionVerifCumplido: { value: -1, label: '' },
  conditionVerifFecha: null,
      /* 3.- Sancionado */
  sancCatNueva: null,
  sancCatAnterior: null,
  sancFechaInicio: '',
  sancFechaTermino: '',
  sancObservaciones: '',
      /* 4.- Errores */
  errors: {
    condicion: null,
    fecha_aut: null,
    firma_imagen: null,
    id_nivel_riesgo: null,
  },
  errors1: null,
  errors2: null,
  errors3: null,
  errorsCondicion: null,
  firma: null,
};

export const catCertimex = [
    { value: 7, label: 'Sin Asignar', color: '#777771' },
    { value: 1, label: 'Transición 0', color: '#D87748' },
    { value: 2, label: 'Transición 1', color: '#FFB94C' },
    { value: 3, label: 'Transición 2', color: '#C7AE34' },
    { value: 4, label: 'Orgánico', color: '#4A6700' },
  ]

 export const catNoCertimex = [
    { value: 7, label: 'Sin Asignar', color: '#777771' },
    { value: 2, label: 'Transición 1', color: '#FFB94C' },
    { value: 3, label: 'Transición 2', color: '#C7AE34' },
    { value: 5, label: 'Transición 3', color: '#FFDDB0' },
    { value: 6, label: 'Convencional', color: '#8e44ad' },
    { value: 4, label: 'Orgánico', color: '#4A6700' },
  ];

  export const dataS3 = [
    { value: 1, label: 'Aprobado sin condiciones' },
    { value: 2, label: 'Aprobado con condiciones' },
    { value: 3, label: 'Sancionado' },
  ];

  export const schemaGlobal = yup.object().shape({
    // cond_total: yup.number().min(0, 'Debe seleccionar una opción'),
    // cond_cumplidas: yup.number().min(0, 'Debe seleccionar una opción'),
    condicion: yup.number().positive('Debe seleccionar una opción'),
    fecha_aut: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    firma_imagen: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe adjuntar su firma'),
    id_nivel_riesgo: yup
      .number()
      .required('Debe seleccionar un nivel de riesgo'),
    sanc_cat_nueva: yup
      .number()
      .required('Debe seleccionar una categoría'),
  });

  export const schemaCondicion = yup.object().shape({
    condicion: yup.string().required('Describa la condición'),
    plazo: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .required('Debe seleccionar una fecha'),
    verif_cumplido: yup.number().nullable(),
    verif_fecha: yup
      .string()
      .transform(v => (v === null ? '' : v.toString()))
      .nullable(),
  });