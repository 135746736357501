
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

const ModalPadronAltasBajas = forwardRef((props, ref) => {
	const padrones = [
		{
		'id' : 'confirmado',
		'label' : props.type + ' en la cooperativa'
		},
		{
			'id' : 'organico',
			'label' : props.type + ' en padrón orgánico'
		}/*
		{
			'id' : 'cj',
			'label' : props.type + ' en padrón CJ'
		},
		{
			'id' : 'og',
			'label' : props.type + ' en padrón OG'
		},
		{
			'id' : 'ra',
			'label' : props.type + ' en padrón RA'
		}*/
	];
	const [show, setShow] = useState(false);
	const [blocking, setBlocking] = useState(false);
	const [padronesSeleccionados, setPadronesSeleccionados] = useState([]);

	const [errors, setErrors] = useState(null);

	//To call outside
	useImperativeHandle(ref, () => ({
		open: openHandler,
		close: closeHandler
	}));


	const openHandler = () => {
		if(props.type === 'Alta' && padronesSeleccionados.length == 0){setPadronesSeleccionados(['confirmado','organico']);}
		setShow(true);
		setErrors(null);
	}

	const closeHandler = () => {
		setPadronesSeleccionados([]);
		setShow(false);		
	}

	const validateHandler = () => {
		if(padronesSeleccionados.length > 0){
			props.onConfirm(padronesSeleccionados);
		}
	}

	const handleCheck = (event) => {
		var updatedList = [...padronesSeleccionados];
		if (event.target.checked) {
		  updatedList = [...padronesSeleccionados, event.target.value];
		} else {
		  updatedList.splice(padronesSeleccionados.indexOf(event.target.value), 1);
		}
		setPadronesSeleccionados(updatedList);
	  };

	  const isChecked = (item) => padronesSeleccionados.includes(item.id) ? true : false;


	return (												
		<div className="modal" style={{display: (show) ? 'block': 'none', backgroundColor: 'rgba(0,0,0,0.2)'}}>
			<div className="modal-dialog" style={{top: 50, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 100px)'}}>
				<div className="modal-content" style={{height: '100%'}}>
					<div className="modal-header" style={{flex: 'inherit'}}>
						<h4 className="modal-title">{props.title}</h4>
						<button onClick={() => closeHandler()} type="button" className="btn" style={{borderRadius: '20px 20px 20px 20px'}}>
							<i className="fa fa-times"></i>
						</button>
					</div>
					<div className="modal-body">
					<h5>{'¿Cómo quieres dar de alta a estos productores?'}</h5>
						{padrones.map((item, index) => (
							<div style={{padding : 10}} key={index}>
								<input
									value={item.id} type="checkbox" 
									style={{width : 15, height : 15, accentColor : '#374E00'}} 
									checked={isChecked(item)} 
									onChange={handleCheck} 
									//disabled={props.type == 'Alta' && item.id == 'confirmado'}
								/>
								<span style={{paddingLeft: 8,paddingTop : 0,fontWeight : 'bold'}}>{item.label}</span>
							</div>
						))}
						{ blocking && (
							<div className="spinner-border text-warning" role="status">
								<span className="sr-only">Guardando...</span>
							</div>
						)}
						{ !blocking && (
							<>
								<button disabled={padronesSeleccionados.length == 0} onClick={() => validateHandler()} type="button" className="btn btn-block btn-sirio" style={{borderRadius: '20px 20px 20px 20px'}}>
									Guardar
								</button>
							</>
						)}					
					</div>
				</div>
			</div>
		</div>
	);
});

export default ModalPadronAltasBajas;