import React, {
  useState,
  useRef,
  useCallback,
} from "react";
import { useAuth } from "../../components/content/auth.jsx";
import { useHistory } from "react-router-dom";
import { useCoopConfig } from "../../components/content/coop-config.jsx";
import { useReactToPrint } from "react-to-print";
import GraficaFisico from "./components/grafica-fisico.jsx";

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media print {
    .pagebreak {
      clear: both;
      page-break-after: always !important;
    }

    tr  {
      border: 2px solid #000 !important;
      padding: 5px 10px !important;
    }

    td, th  {
      font-size: 10px !important;
      padding: 5px 10px !important;
    }

  }
`;

const CalidadAnalisisSensorialInfoZarandasDefectos = ({data}) => {
  const MSG_REQUIRED = "Información obligatoria";
  const queryParameters = new URLSearchParams(window.location.search);
  const history = useHistory();
  const auth = useAuth();
  const coopConfig = useCoopConfig();
  const [blocking, setBlocking] = useState(false);
  const componentRef = useRef(null);
  const [nameToPrint, setNameToPrint] = useState("AnalisisFisico");


  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: nameToPrint,
    //onAfterPrint: useCallback(() => history.goBack(), [history]),
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: pageStyle,
  });


  return (
    <div ref={componentRef}>
      <div className="row mt-2">
        <div className="col-md-12 col-xs-12 col-xl-12">
          <h5>{"Defectos"}</h5>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-6 col-xs-6 col-xl-6">
          <div className="row">
            <div className="col-xs-4 col-xl-4">
              <span>
                {"Zaranda: "}
                <span style={{ textDecoration: "underline" }}>
                  {data.zaranda.nombre}
                </span>
              </span>
            </div>

            <div className="col-xs-4 col-xl-4">
              <span>
                {"Total primarios: "}
                <span style={{ textDecoration: "underline" }}>{data?.defectos_primarios?.length}</span>
              </span>
            </div>
            <div className="col-xs-4 col-xl-4">
              <span>
                {"Total secundarios: "}
                <span style={{ textDecoration: "underline" }}>{data?.defectos_secundarios?.length}</span>
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xs-6 col-xl-6">
          <GraficaFisico data={data} />
        </div>
      </div>
    </div>
  );
};

export default CalidadAnalisisSensorialInfoZarandasDefectos;
