import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";
import env from "../../../environment";
import Api from '../../../api/global.service';
import mapChartData from '../../../utils/mapChartData.js';
import renderCustomizedLabel from '../../../utils/renderCustomizedLabel.js';
import BlockUi from "react-block-ui";

const CuentasResumenEdades = (props) => {
  const [dataGrafica, setDataGrafica] = useState([]);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    if(props.id_ciclo > 0){getData();}
  }, [props.id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/productores/edades', {
      filter: [{ column: 'id_ciclo', operator: '=', value: props.id_ciclo }]
    }).then(response => {
      let chartData = mapChartData(response.data,'rango_edad','total');
      setDataGrafica(chartData);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  }

  return (

    <BlockUi
    tag="div"
    message=""
    blocking={blocking}
    renderChildren={true}
    >

    <div className="row" style={{ marginTop: 15 }}>
      <div className="col-xl-4 col-md-4 offset-1">
      {dataGrafica.map((entry, index) => (
          <div className="row card-graph">
          <div className="col-xl-12 col-md-12">
              <div className="row card-dashboard" style={{ height: (100 * (dataGrafica.length / 100)) }}>
                <div className="col-xl-4 col-md-4">
                  <img src={env.images.card[entry.icon]} className="pt-2" />
                </div>
                <div className="col-xl-8 col-md-8">
                  <h1 style={{fontSize : 20}}>{entry.value}</h1>
                  <h6 style={{fontSize : 14}}>{entry.name}</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-xl-6 col-md-6 offset-1">
        <PieChart width={250} height={250}>
          <Pie
            data={dataGrafica}
            cx={95}
            cy={95}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            isAnimationActive={false}
          >
            {dataGrafica.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
            />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
    </div>
    </BlockUi>
  );
};

export default CuentasResumenEdades;
