import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useAuth } from "./../../content/auth.jsx";
import { useHistory } from "react-router-dom";
import env from "./../../../environment.js";

class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.logout = this.logout.bind(this);
		this.state = {
			dropdownOpen: false,
		};
	}

	logout(){
		this.props.auth.logout()
			.then(response => {
				this.props.history.push("/login")
		  })
			.catch(errors => {
	  
		  });
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
  
	render() {
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
				<DropdownToggle tag="a">
					<img src={(this.props.auth.user?.foto == '') ? env.images.user_default : this.props.auth.user?.foto} alt="" /> 
					<span className="d-none d-md-inline font-open-normal-400">{this.props.auth.user?.nombres}</span> <b className="caret"></b>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
					<DropdownItem onClick={this.logout}>Cerrar sesión</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default (props) => { 
	const auth = useAuth();
	const history = useHistory();
	return <DropdownProfile auth={auth} history={history} {...props}/>;
};
