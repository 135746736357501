import React, { useState,useEffect } from 'react';
import env from '../../../environment';
import Api from "../../../api/global.service";

const CardLocalidad = ({ idLocalidad }) => {
  let id_localidad = (idLocalidad.charAt(0) === ',') ? idLocalidad.substring(1) : idLocalidad;
  const [totalProductores, setTotalProductores] = useState("");
  const [variedadesParcelas, setVariedadesParcelas] = useState("");
  const [altitudParcelas, setAltitudParcelas] = useState("");
  

    const infoRegionProductoresTotales = () => {
      Api.getAll("calidad/info/region/productores/totales/" + id_localidad).then((response) => {
        if(typeof response.data[0] !== 'undefined') {
          if (response.data[0].total !== null) {  
            setTotalProductores(response.data[0].total);
          }else{
            setTotalProductores("");
          }
        }
      })
    }

    const infoRegionParcelasVariedades = () => {
      Api.getAll("calidad/info/region/parcelas/variedades/" + id_localidad).then((response) => {
        if(typeof response.data[0] !== 'undefined') {
          if (response.data[0].variedades !== null) {  
            setVariedadesParcelas(response.data[0].variedades);
          }else{
            setVariedadesParcelas("");
          }
        }
      })
    }
   
    const infoRegionParcelasAltitud = () => {
      Api.getAll("calidad/info/region/parcelas/altitud/" + id_localidad).then((response) => {
        if(typeof response.data[0] !== 'undefined') {
          if (response.data[0].altitud !== null) {  
            setAltitudParcelas(response.data[0].altitud);
          }else{
            setAltitudParcelas("");
          }
        }
      })
    }


  useEffect(() => {
    setTotalProductores("");
    setVariedadesParcelas("");
    setAltitudParcelas("");
    if(idLocalidad.length > 0){
      infoRegionProductoresTotales();
      infoRegionParcelasVariedades();
      infoRegionParcelasAltitud();
    }
  }, [idLocalidad]);

  return (
    <div className="row mt-5">
      {totalProductores >= 0 && (
      <div className="col-xl-4 col-lg-4 col-md-4  col-xxl-3">
        <div className="card-basic" style={{backgroundColor : '#FAFAFA',height : 125}}>
          <div className='text-right' style={{paddingRight : 20}}><img src={env.images.icon.account_circle} className='text-right' style={{width : 30,position : 'absolute'}} /></div>
          <h1 className="font-lato-normal-600" style={{textAlign : 'left'}}>{'Productores'}</h1>
          <h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Entregas de ' + totalProductores + ' productores en total'}</h6>
        </div>
      </div>
      )}
    {(variedadesParcelas.length > 0 || altitudParcelas > 0) && (
    <div className="col-xl-4 col-lg-4 col-md-4  col-xxl-3">
      <div className="card-basic" style={{backgroundColor : '#FAFAFA',height : 125}}>
        <div className='text-right' style={{paddingRight : 20}}><img src={env.images.icon.pin_organico} className='text-right' style={{width : 40,position : 'absolute'}} /></div>
        <h1 className="font-lato-normal-600" style={{textAlign : 'left'}}>{'Parcelas'}</h1>
        {variedadesParcelas.length > 0 && (<h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Variedades: ' + variedadesParcelas}</h6>)}
        {altitudParcelas > 0 && (<h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Altura promedio: ' + altitudParcelas + ' msnm'}</h6>)};
      </div>
    </div>
    )}
  </div>
  );
};

export default CardLocalidad;
