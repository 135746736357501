import { useEffect, useState } from 'react';
import env from '../environment';
import { useCoopConfig } from '../components/content/coop-config';

const useEtiquetas = () => {
  const coopConfig = useCoopConfig();
  const [etiquetas, setEtiquetas] = useState({ planta: '', parcela: '' });

  const getEtiquetas = async () => {
    // const etiquetaParcela = coopConfig.config?.parcelas_etiqueta;
    const etiquetaParcela = await coopConfig.etiquetaParcelas();
    setEtiquetas({
      parcela: etiquetaParcela,
      planta: coopConfig.config?.cooperativa?.producto?.id === env.productos.MIEL ? 'colmena' : 'planta',
    });
  };

  // Reacting to the refresh
  useEffect(() => {
    getEtiquetas();
  }, [coopConfig]);

  return {
    etiquetas,
  };
};

export default useEtiquetas;
