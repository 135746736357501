import * as React from "react"


class LineSvg extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {		
		return (
			<svg width='173' height='4' style={this.props.style} viewBox="0 0 173 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      	<rect width='173' height='4'	fill='#4A6700'/>
    	</svg>
		);
	}

}

export default LineSvg;