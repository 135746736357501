export const changeCropLabelByCoop = (text,id_cooperativa) => {
    let newLabel = text;
    if(Number(id_cooperativa) === 11){
        newLabel = newLabel.replace("Café", "Cacao");
        newLabel = newLabel.replace("café", "cacao");
    }
    return newLabel;
};
  
export default changeCropLabelByCoop;
  