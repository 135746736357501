import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../components/content/auth.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import CardContainer from '../../components/card/card-container.jsx';
import Api from '../../api/global.service';
import { deleteUserByUUID } from '../../utils/deleteUserByUUID.js';
import env from '../../environment.js';

import NoPagination from '../../components/pagination/noPagination.jsx';
import SearchColumn from '../../components/search/search-column.jsx';

const UsuariosList = () => {
  const auth = useAuth();

  const [name] = useState('Usuarios');
  const [table] = useState('users');
  const [url] = useState('/admin/usuarios');

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [blocking, setBlocking] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ACCIONES',
        accessor: 'uuid',
        Cell: cell => (
          <div className="with-btn text-nowrap">
            {auth.hasPermission('USUARIOS_EDITAR') && (
              <Link to={{ pathname: `${url}/edit/${cell.row.original.uuid}` }} className="m-r-2">
                <button className="btn btn-sm btn-primary m-r-2">
                  <i className="fa fa-pen"></i> Editar
                </button>
              </Link>
            )}
            {auth.hasPermission('USUARIOS_ELIMINAR') && (
              <button onClick={ ()=> handleDelete(cell.row.original.uuid)} className="btn btn-sm btn-danger m-r-2">
                <i className="fa fa-trash"></i> Eliminar
              </button>
            )}
          </div>
        ),
      },
      {
        Header: 'NOMBRE',
        accessor: 'nombre',
        sortable: true,
        Cell: cell => (
          <div style={{ width: '200px' }}>
            {/* <img src={cell.row.original.foto} alt="" style={{width: '30px', height: '30px', marginRight: '6px', borderRadius: '30px'}} /> */}
            <img
              src={cell.row.original.foto == '' ? env.images.user_default : cell.row.original.foto}
              alt=""
              style={{ width: '30px', height: '30px', marginRight: '6px', borderRadius: '30px' }}
            />
            {/* <Avatar.Text size={24} label="XD" /> */}
            <span>{cell.value}</span>
          </div>
        ),
      },
      {
        Header: 'E-MAIL',
        accessor: 'email',
        sortable: true,
      },
      {
        Header: 'TELEFONO',
        accessor: 'username',
        sortable: true,
        Cell: cell => {
          return (
            <p className="text-right" style={{ margin: 0 }}>
              {cell.value}
            </p>
          );
        },
      },
      {
        Header: 'ROL',
        accessor: 'rol',
        sortable: true,
      },
      {
        Header: 'COOPERATIVA',
        accessor: 'cooperativa',
        sortable: true,
      },
      // {
      // 	Header: 'PERMISOS',
      // 	accessor: 'permisos',
      // 	sortable: true
      // },
      {
        Header: 'FECHA DE CREACIÓN',
        accessor: 'created_at',
        sortable: true,
        Cell: cell => {
          return (
            <p className="text-right" style={{ margin: 0 }}>
              {cell.value}
            </p>
          );
        },
      },
      {
        Header: 'FECHA DE ACTUALIZACIÓN',
        accessor: 'updated_at',
        sortable: true,
        Cell: cell => (
            <span style={{  margin: 0, width: '100px', minWidth: '100px', whiteSpace: 'nowrap' }}>{cell.value}</span>
        ),
      },
    ],
    []
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'nombre', label: 'NOMBRE' },
      { value: 2, target: 'email', label: 'E-MAIL' },
      { value: 3, target: 'username', label: 'TELEFONO' },
      { value: 4, target: 'rol', label: 'ROL' },
      { value: 5, target: 'cooperativa', label: 'COOPERATIVA' },
      { value: 6, target: 'created_at', label: 'FECHA DE CREACIÓN' },
      { value: 7, target: 'updated_at', label: 'FECHA DE ACTUALIZACIÓN' },
    ],
    []
  );

  const handleDelete = useCallback(uuid => {
    deleteUserByUUID(uuid);
  }, []);

  useEffect(() => {
    setBlocking(true);
    Api.getAll(table)
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        setBlocking(false);
      })
      .catch(e => {
        setBlocking(false);
      });
  }, []);

  const setDataFilter = data => {
    setData(data);
  };

  return (
    <div>
      {auth.hasAccess('USUARIOS') && (
        <>
          <Breadcrumb>
            <ol className="breadcrumb float-xl-right">
              <li className="breadcrumb-item active">{name}</li>
            </ol>
          </Breadcrumb>

          <CardContainer height={'100%'}>
            <div className="row">
              <div className="col-10">
                <SearchColumn
                  className={'text-nowrap'}
                  data={originalData}
                  columns={searchColumns}
                  setDataFilter={evt => {
                    setDataFilter(evt);
                  }}></SearchColumn>
              </div>
              <div className="col-2 text-right">
                {auth.hasPermission('USUARIOS_NUEVO') && (
                  <Link to={{ pathname: `${url}/create` }} className="m-r-2">
                    <button className="btn btn-sm btn-gray m-r-2">
                      <i className="fa fa-plus"></i> Nuevo
                    </button>
                  </Link>
                )}
              </div>
            </div>

            {auth.hasPermission('USUARIOS_VER') && (
              <NoPagination blocking={blocking} columns={columns} data={data}></NoPagination>
            )}
          </CardContainer>
        </>
      )}
    </div>
  );
};

export default UsuariosList;
