import React, { useState, useEffect } from 'react';
import SelectColumn from '../select/select-column';

const SearchColumn = ({ data, columns, setDataFilter, clearSearchItem }) => {
  const [column, setColumn] = useState({});
  const [search, setSearch] = useState('');
  const [compareColumns, setCompareColumns] = useState({});

  useEffect(() => {
    //Filtrar todas las columnas para comparar los datos
    let c = columns.filter(item => {
      return item.target !== '';
    });
    setCompareColumns(c);
  }, []);

  useEffect(() => {
    clearSearch();
  }, [clearSearchItem]);

  const replaceDiacritics = str => {
    var diacritics = [
      { char: 'a', base: /[\340-\346]/g },
      { char: 'e', base: /[\350-\353]/g },
      { char: 'i', base: /[\354-\357]/g },
      { char: 'o', base: /[\362-\370]/g },
      { char: 'u', base: /[\371-\374]/g },
    ];

    diacritics.forEach(function(letter) {
      str = str.replace(letter.base, letter.char);
    });

    return str;
  };

  const matchString = (str, match) => {
    let _str = new String(str);
    return replaceDiacritics(_str.toLowerCase()).includes(match);
  };

  const setFilter = value => {
    let filter_data = data.filter(item => {
      let s = replaceDiacritics(value.search.toLowerCase());
      if (s !== '') {
        if (value.target.target === '') {
          let found = compareColumns.reduce(function(f, column) {
            let res = false;
            if (item[column.target] !== null) {
              res = matchString(item[column.target], s);
            }
            return f || res;
          }, false);

          if (found) return item;
          else return null;
        } else {
          //Filtrar por columna
          let res = false;
          if (item[value.target.target] !== null) {
            res = matchString(item[value.target.target], s);
          }
          if (res) return item;
          else return null;
        }
      } else return item;
    });

    setDataFilter(filter_data);
  };

  const handlerChange = s => {
    setSearch(s);
    setFilter({ search: s, target: column });
  };

  const columnFilter = event => {
    setColumn(event.target);
    setFilter({ search: search, target: event.target });
  };

  const clearSearch = () => {
    setSearch('');
    setFilter({ search: '', target: column });
  };

  return (
    <div className="row mb-2">
      <div className="col-md-3 pr-0">
        <SelectColumn
          className="select-left-radius"
          columns={columns}
          selectClasses="noborder-right-radius"
          setFilter={evt => {
            columnFilter(evt);
          }}></SelectColumn>
      </div>
      <div className="col-md-5 col-md-offset-4 pl-0">
        <div className="input-group noborder-left-radius">
          <span className="p-5">
            <i className="fa fa-search text-gray"></i>
          </span>
          <input
            type="text"
            className="form-control"
            value={search}
            placeholder="Búsqueda"
            onChange={e => {
              handlerChange(e.target.value);
            }}
          />
          {search !== '' && (
            <span
              className="p-5"
              onClick={() => {
                clearSearch();
              }}>
              <i className="fa fa-times text-gray"></i>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchColumn;
