import React, { useEffect,useState  } from "react";
import { useFieldArray } from "react-hook-form";
import TextField from '@mui/material/TextField';
const height = 44;

const EntregasXProductorBultos = ({ nestIndex, control, register, errors, unidadesEntregas, id_cat_unidades_entregas,values,setValue }) => {
  const [shrink, setShrink] = useState(false);
  const height = 44;


 const getDataBulto = (id_cat_unidades_entregas) => {
  let no_bultos = "";
  let bultosData = [];
  let bultoData = [];
  if (typeof id_cat_unidades_entregas !== 'undefined') {
    if (typeof values.pesadas[nestIndex].bultosData !== 'undefined') {
      bultosData = values.pesadas[nestIndex].bultosData;
      bultoData = bultosData.filter((bulto) => Number(bulto.id_cat_unidades_entregas) === Number(id_cat_unidades_entregas));
      if (typeof bultoData[0] !== 'undefined') {
        no_bultos = bultoData[0].no_bultos;
      }
    }
  }
  return no_bultos;
 };

useEffect(() => {    
    if (typeof values.pesadas[nestIndex].bultosData !== 'undefined') {
      let bultosData = values.pesadas[nestIndex].bultosData;
      if (typeof bultosData[0] !== 'undefined') {
        for (let b = 0; b < bultosData.length; b++) {
          setValue(`pesadas.${nestIndex}.bultos.${bultosData[b].id_cat_unidades_entregas}.no_bultos`, Number(bultosData[b].no_bultos));
        }
      }
    }
}, [values.pesadas[nestIndex]]);

  return (
    <>
      {unidadesEntregas.map((item, k) => {
        return (
            <div key={item.value + '_' + k} className={"col-md-3"} style={{display: id_cat_unidades_entregas.includes(Number(item.value)) ? 'block' : 'none' }}>
              {id_cat_unidades_entregas.includes(Number(item.value)) && 
                <>  
                <TextField
                id={`pesadas.${nestIndex}.bultos.${item.value}.no_bultos`}
                name={`pesadas.${nestIndex}.bultos.${item.value}.no_bultos`}
                label={`Nº de ${item.label.toLowerCase()}`}
                error={errors.pesadas?.[nestIndex]?.bultos?.[item.value]?.no_bultos}
                className={'form-control mt-1'}
                fullWidth={true}
                variant={"outlined"}
                {...register(`pesadas.${nestIndex}.bultos.${item.value}.no_bultos`)}
                onFocus={() => setShrink(true)}
                onChangeCapture={(e) => {setShrink(!!e.target.value)}}
                style={{ height, display : (Number(item.value) === 7) ? 'none' : 'block'}}
                InputLabelProps={{
                  shrink: !!getDataBulto(item.value) || shrink,
                  style: {
                    height,
                  },
                }}
                inputProps={{
                    style: {
                      height,
                      padding: '0 10px',
                    },
                }}
              />
            {errors.pesadas?.[nestIndex]?.bultos?.[item.value]?.no_bultos && (
              <div className="invalid-feedback mt-2">
                {errors.pesadas?.[nestIndex]?.bultos?.[item.value]?.no_bultos.message}
              </div>
            )}
            </>
            }
            </div>
        );
      })}
    </>
  );
};

export default EntregasXProductorBultos;
