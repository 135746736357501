import React, { useState,useEffect } from 'react';
import env from '../../../environment';
import Api from "../../../api/global.service";

const CardLote = ({ idLote }) => {
  const [totalProductores, setTotalProductores] = useState("");
  const [regionesProductores, setRegionesProductores] = useState("");
  const [variedadesParcelas, setVariedadesParcelas] = useState("");
  const [altitudParcelas, setAltitudParcelas] = useState("");


  const infoLoteProductoresTotales = () => {
    Api.getAll("calidad/info/lote/productores/totales/" + idLote).then((response) => {
      if(typeof response.data[0] !== 'undefined') {
        if (response.data[0].total !== null) {  
          setTotalProductores(response.data[0].total);
        }
      }
    })
  }

  const infoLoteProductoresRegiones = () => {
    Api.getAll("calidad/info/lote/productores/regiones/" + idLote).then((response) => {
      if(typeof response.data[0] !== 'undefined') {
        if (response.data[0].localidades !== null) {  
          setRegionesProductores(response.data[0].localidades);
        }
      }
    })
  }

  const infoLoteParcelasVariedades = () => {
    Api.getAll("calidad/info/lote/parcelas/variedades/" + idLote).then((response) => {
      if(typeof response.data[0] !== 'undefined') {
        if (response.data[0].variedades !== null) {  
          setVariedadesParcelas(response.data[0].variedades);
        }
      }
    })
  }

  const infoLoteParcelasAltitud = () => {
    Api.getAll("calidad/info/lote/parcelas/altitud/" + idLote).then((response) => {
      if(typeof response.data[0] !== 'undefined') {
        if (response.data[0].altitud !== null) {  
          setAltitudParcelas(response.data[0].altitud);
        }
      }
    })
  }


  useEffect(() => {
    setTotalProductores("");
    setRegionesProductores("");
    setVariedadesParcelas("");
    setAltitudParcelas("");

    if(idLote > 0){
      infoLoteProductoresTotales();
      infoLoteProductoresRegiones();
      infoLoteParcelasVariedades();
      infoLoteParcelasAltitud();
    }
  }, [idLote]);

  return (
    <div className="row mt-5">
      <div className="col-xl-4 col-lg-4 col-md-4  col-xxl-3">
        <div className="card-basic" style={{backgroundColor : '#fafafa'}}>
          <div className='text-right' style={{paddingRight : 20}}><img src={env.images.icon.account_circle} className='text-right' style={{width : 30,position : 'absolute'}} /></div>
          <h1 className="font-lato-normal-600" style={{textAlign : 'left'}}>{'Productores'}</h1>
          <h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Entregas de ' + totalProductores + ' productores en total'}</h6>
          <h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Pertenecen a las regiones de: ' + regionesProductores}</h6>

        
        </div>
      </div>
    {(variedadesParcelas.length > 0 || altitudParcelas > 0) && (
    <div className="col-xl-4 col-lg-4 col-md-4  col-xxl-3">
      <div className="card-basic" style={{backgroundColor : '#fafafa'}}>
        <div className='text-right' style={{paddingRight : 20}}><img src={env.images.icon.pin_organico} className='text-right' style={{width : 40,position : 'absolute'}} /></div>
        <h1 className="font-lato-normal-600" style={{textAlign : 'left'}}>{'Parcelas'}</h1>
        {variedadesParcelas.length > 0 && (<h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Variedades: ' + variedadesParcelas}</h6>)}
        {altitudParcelas > 0 && (<h6 className="font-lato-normal-400" style={{textAlign : 'left'}}>{'Altura promedio: ' + altitudParcelas + ' msnm'}</h6>)}
      </div>
    </div>
    )}

  </div>
  );
};

export default CardLote;
