import React, { useState, useEffect } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

import { useTheme } from "@mui/material/styles";
import Api from '../../../api/global.service';
import BlockUi from 'react-block-ui';

export const ReactHookFormSelect  = ({
  name,
  control,
  error = false,
  label = '',
  placeholder = '--- SELECCIONE ---',
  id,
  options = [],
  register,
  showAllLabel = 'TODOS',
  showAll = false,
  multiple = false,
  keyArray = 'id',
  labelArray = 'name',
  url,
  filter = [],
  sort = [],
  apiSearch = true,
  ...inputProps
}) => {
    const height = 44;
    const theme = useTheme();
    const [items, setItems] = useState([]);
    const [blocking, setBlocking] = useState(true);
    const defaultOption = { value: 0, label: placeholder, isDisabled: true };
  
  const generateSingleOptions = () => {
    return items.map((item,index) => {
      return (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      );
    });
  };


  useEffect(() => {
    let params = {
      filter: filter ? filter : [],
      sort: sort ? sort : [],
    };

    if(apiSearch === true){
      Api.search(url, params).then(response => {
        setBlocking(false);
        let options = response.data.map(function(item) {
          return { value: item[keyArray], label: item[labelArray] };
        });
        setItems([defaultOption, ...options]);
      });
    }else{
      Api.getAll(url, params).then(response => {
        setBlocking(false);
        let options = response.data.map(function(item) {
          return { value: item[keyArray], label: item[labelArray] };
        });
        setItems([defaultOption, ...options]);
      });
    }


    // .catch(e => {});
  }, [url]);

  return (
    <FormControl fullWidth>
        <InputLabel id={id + '-label'}>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select labelId={id + '-label'} label={label} multiple={multiple} onChange={onChange} value={value} style={{ height, minWidth : 100 }} error={error}>
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};