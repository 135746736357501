import Swal from 'sweetalert2';

/**
 * check for 403, 404 errors and show modal
 * @param {number} status http status
 * @param {string} returnUrl url return string
 */
const generalCatchErrorsModal = (status, returnUrl) => {
  if (status === 403) {
    Swal.fire({
      icon: 'error',
      title: 'Acceso no autorizado',
      text: 'No tienes permiso para acceder a esta información',
    }).then(() => {
      window.location = returnUrl;
    });
  } else if (status === 404) {
    Swal.fire({
      icon: 'error',
      title: 'No encontrado',
      text: 'No se ha encontrado la información solicitada',
    }).then(() => {
      window.location = returnUrl;
    });
  }
};

export default generalCatchErrorsModal;
