import React from 'react';
import IconMarker from '../icon/icon-marker';
import LabelSimple from '../label/label-simple';

class Marker extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			visible: false
		};

		this.myLabel = React.createRef();
	}

	componentDidMount () {
		if(this.myLabel.current != null){
			this.setState({offset: -(this.myLabel.current.children[0].clientWidth) / 2})
		}
	}
	
	render() {		
		return (
			<div lat={this.props?.lat} lng={this.props?.lng}>
				<IconMarker color={this.props.color} onClick={() => {this.setState((value) => ({visible: !value.visible}));}}>
					{this.props?.label != null && (
							<div style={{marginTop: -80, marginLeft: this.state.offset}} ref={this.myLabel}>
								<LabelSimple color="#000045" bgColor="#FFFFFF" label={this.props?.label}></LabelSimple>
							</div>
						)
					}							
				</IconMarker>
			</div>
		);
	}

}

export default Marker;