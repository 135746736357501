import React, { Component } from 'react';
import {
    Collapse,
    NavbarToggler,
    Nav,
} from 'reactstrap';
import { PageSettings } from '../../../config/page-settings';
import SidebarNav from '../../sidebar/sidebar-nav';

class DropdownMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({toggleMobileTopMenu, pageMobileTopMenuCollapsed}) => (
                    <React.Fragment>
                        <Collapse isOpen={!pageMobileTopMenuCollapsed}>
                            <Nav navbar>
                                <SidebarNav className="font-lato-normal-500" />
                            </Nav>
                        </Collapse>
                    </React.Fragment>
                )}
            </PageSettings.Consumer>
        );
    }
}

export default DropdownMobile;