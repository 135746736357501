import React from 'react';

class LabelDate extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {		

		let value = (this.props.value != null) ? this.props.value.substr(0,10) : ''		
		
		return (
			<p className='text-left' style={{margin: 0}}>
				{value}
			</p>
		);
	}
	
}

export default LabelDate;