import React from "react";
import { useFieldArray } from "react-hook-form";
import {ReactHookFormSelect} from "../../nuup/componentes/Forms/ReactHookFormSelect.jsx";
import {ReactHookFormInput} from "../../nuup/componentes/Forms/ReactHookFormInput.jsx";
import CalidadAnalisisFisicoZarandasDefectos from "./calidad-analisis-fisico-zarandas-defectos.js";

const CalidadAnalisisFisicoZarandas = ({ control, register, setValue, getValues,errors }) => {

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "zarandas",
  });

  const agregaZaranda = () => {
    append({
      id_calidad_analisis_fisicos_zaranda: 0,
      id_zaranda: 0,
      valor: "",
      eliminar: 0,
      defectos_primarios: [
        {
        id_calidad_analisis_fisicos_defecto: 0,
        id_tipo_mancha: "",
        no_granos: "",
        gramos: "",
        eliminar: 0
        }
      ],
      defectos_secundarios: [
        {
          id_calidad_analisis_fisicos_defecto: 0,
          id_tipo_mancha: "",
          no_granos: "",
          gramos: "",
          eliminar: 0
        }
      ]
    });
  };


  const eliminaZaranda = (index) => {
    let id_calidad_analisis_fisicos_zaranda = getValues().zarandas?.[index]?.id_calidad_analisis_fisicos_zaranda;
    if(id_calidad_analisis_fisicos_zaranda > 0){
      setValue(`zarandas[${index}].eliminar`, 1);
    }else{
      remove(index);
    }
  }


  return (
    <div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <span style={{fontSize : 14,fontWeight : 'bold'}}>{"Zarandas"}</span>
          </div>
        </div>

        {fields.map((zaranda, index) => (
        <div key={index} style={{display : getValues().zarandas?.[index]?.eliminar === 1 ? 'none' : 'block'}}>
        {fields.length > 1 && (<h5 className="mt-1 mb-3">{'Zaranda: ' + (index + 1)}</h5>)}
        <div className={'row mt-' + (index === 0 ? '3' : '5')}>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-5">
                <ReactHookFormSelect
                  placeholder="Elige zaranda"
                  label="Elige zaranda"
                  id={`zarandas[${index}].id_zaranda`}
                  name={`zarandas[${index}].id_zaranda`}
                  error={errors.zarandas?.[index]?.id_zaranda ? true : false}
                  url={"calidad/catalogos/zarandas"}
                  keyArray="id"
                  labelArray="nombre"
                  value={getValues().zarandas?.[index]?.id_zaranda}
                  control={control}
                  apiSearch={false}
                />
                {errors.zarandas?.[index]?.id_zaranda && (
                  <div className="invalid-feedback">
                    {errors.zarandas?.[index]?.id_zaranda.message}
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <ReactHookFormInput
                  type="number"
                  id={`zarandas[${index}].valor`}
                  name={`zarandas[${index}].valor`}
                  error={errors.zarandas?.[index]?.valor}
                  label="Valor (grs.)"
                  control={control}
                  value={getValues().zarandas[index].valor}
                />
              </div>
              <div className="col-lg-2"></div>
            </div>
            <div className="row">
            {index === 0 && (
              <div className="col-xs-6 col-xl-3 col-xxl-2">
                <button
                  className="btn btn-outline-dark mt-3"
                  type="button"
                  onClick={() => agregaZaranda()}
                >
                  <i className="fa fa-plus"></i>
                  {" Agregar"}
                </button>
              </div>
            )}
              {index > 0 && (
              <div className="col-xs-6 col-xl-3 col-xxl-2">
                <button
                  className="btn btn-outline-danger mt-3"
                  type="button"
                  onClick={() => eliminaZaranda(index)}
                >
                  {"Eliminar"}
                </button>
              </div>
              )}

            </div>
          </div>

          <div className="col-lg-6" key={index}>
            <div className="row">
              <div className="col-lg-12">
              <CalidadAnalisisFisicoZarandasDefectos nestIndex={index} type={'defectos_primarios'} {...{ control, register, getValues, setValue, errors }}/>
              </div>

              <div className="col-lg-12">
              <CalidadAnalisisFisicoZarandasDefectos nestIndex={index} type={'defectos_secundarios'} {...{ control, register, getValues, setValue, errors }}/>
              </div>

            </div>


          </div>
        </div>
        </div>
        ))}

    </div>
  );
};

export default CalidadAnalisisFisicoZarandas;
