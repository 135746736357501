
import './half-donut.css'
import React from 'react';

// Si quieres mejorarlo, visita: https://webdesign.tutsplus.com/tutorials/how-to-build-a-css-only-semi-circle-donut-chart--cms-26997 

class HalfDonut extends React.Component {
	constructor(props) {
		super(props);
	}
	
	render() {		
		var width = this.props.width || 350;
		var height = width / 2;
		return (
			<ul className={`chart-skills chart-skills-${ width }`} style={{width: width, height: height, before: {
				content: '',
				width: 'inherit',
				height: 'inherit',
				border: '65px solid rgba(211, 211, 211, .3)',
				borderBottom: 'none',
				borderTopLeftRadius: height,
				borderTopRightRadius: height}}}>				
				<li style={{ borderColor: this.props.color, transform: `rotate(${(this.props.value * 180) / 100}deg)`, borderBottomLeftRadius: height, borderBottomRightRadius: height }}></li>  
			</ul>
		);
	}
}

export default HalfDonut;