const COLOR_0 = "#4A6700";
const COLOR_1 = "#FFB94C";
const COLOR_2 = "#BC8268";
const COLOR_3 = "#D87748";
const COLOR_4 = "#A46B00";
const COLOR_5 = "#E49D2A";
const COLOR_NA = "#9B9B9B";

const COLORS = [COLOR_0,COLOR_1,COLOR_2,COLOR_3,COLOR_4,COLOR_5];


export const getCategoriasOtrasActividades = () => {
    return [
        {
          key : 'autoconsumo',
          label : 'Autoconsumo',
          color : COLOR_0
        },
        {
          key : 'otros_cultivos',
          label : 'Otros cultivos',
          color : COLOR_1
        },
        {
          key : 'potrero',
          label : 'Potrero',
          color : COLOR_2
        },
        {
          key : 'monte_bosque',
          label : 'Monte / Bosque',
          color : COLOR_3
        },
        {

          key : 'acahual',
          label : 'Acahual',
          color : COLOR_4
        },
        {
          key : 'area_reserva',
          label : 'Área de reserva',
          color : COLOR_5
        },
        {
          key : 'ninguno',
          label : 'Ninguno',
          color : COLOR_NA
        },
      ]
  
    
}

const mapChartData = (data,keyName,keyValue,keyColor = '') => {
    let chartData = [];
     for (let d = 0; d < data.length; d++) {
        const element = data[d];
        if (typeof element[keyName] !== 'undefined' && typeof element[keyValue] !== 'undefined') {
            chartData.push({
                name : element[keyName],
                value : Number(element[keyValue]),
                color : getNAColor(element,keyName,getColor(element,d,keyColor),'color'),
                icon : getNAColor(element,keyName,getColor(element,d,keyColor),'icon')
            });
        }
     }
     return sort(chartData,['value']);
};

const getNAColor = (data,keyName,color,tipo = 'color') => {
    let arrNa = ['NA','N/A','Sin Asignar','Ninguno'];
    let newColor = color;
    if (typeof data[keyName] !== 'undefined') {
        if(arrNa.includes(data[keyName])){
            newColor = (tipo == 'color') ? COLOR_NA : 'circulo_' + COLOR_NA.replace("#", "");
        }else{
            newColor = (tipo == 'color') ? color : 'circulo_' + color.replace("#", "");
        }
    }
    return newColor;
}

const getColor = (data,index,keyColor) => {
    let color = '';
    if (typeof data[keyColor] !== 'undefined') {
        color = data[keyColor];
    }else{
        if (typeof COLORS[index] !== 'undefined') {
            color = COLORS[index];
        }
    }
    return color;
}

function sort(a, by) {
    return a.map(function (el, i) {
        return {index: i,value: by.reduce(function (obj, property) { return obj[property]; }, el)};
    }).sort(function (a, b) {
        return b.value - a.value;
    }).map(function (el) {
        return a[el.index];
    });
}

  
export default mapChartData;
  