import React, { useState, createRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../components/card/card-container.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import LinkDownload from '../../components/link/link-download';
import LabelCategory from '../../components/label/label-category.jsx';
import LabelNumber from '../../components/label/label-number.jsx';
import Api from '../../api/global.service';
import { useAuth } from '../../components/content/auth.jsx';
import env from '../../environment.js';
import NoPagination from '../../components/pagination/noPagination.jsx';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import SearchColumn from '../../components/search/search-column.jsx';
import ModalPadron from '../../components/modal/modal-padron.jsx';
import EditLink from '../../components/link/edit-link.jsx';
import ModalParcelas from '../../components/modal/modal-parcelas.jsx';
import updateLocalData from '../../utils/updateLocalData.js';
import { useCoopConfig } from '../../components/content/coop-config.jsx';

const PadronCompletoList = () => {
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ciclo, setCiclo] = useState({});
  const [blocking, setBlocking] = useState(true);
  const [edit, setEdit] = useState(null);
  const [editNumber, setEditNumber] = useState(null);
  const [editDate, setEditDate] = useState(null);
  const [editName, setEditName] = useState(null);
  const [editSelect, setEditSelect] = useState(null);
  const [editSelectLocalidad, setEditSelectLocalidad] = useState(null);
  const [showParcelas, setShowParcelas] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [parcelasSelectedData, setParcelasSelectedData] = useState({
    id_cuenta: null,
    nombreProductor: null,
  });
  const [unitConversions, setUnitConversions] = useState({});
  const coopConfig = useCoopConfig();

  const dialog = createRef();
  const onClickEdit = (event, data) => {
    event.preventDefault();
    setEdit(data);
  };

  const onClickEditNumber = (event, data) => {
    event.preventDefault();
    setEditNumber(data);
  };

  const onClickEditDate = (event, data) => {
    event.preventDefault();
    setEditDate(data);
  };

  const onClickEditName = (event, data) => {
    event.preventDefault();
    setEditName(data);
  };

  const onClickEditSelect = (event, data) => {
    event.preventDefault();
    setEditSelect(data);
  };

  const onClickEditSelectLocalidad = (event, data) => {
    event.preventDefault();
    setEditSelectLocalidad(data);
  };

  useEffect(() => {
    if (edit !== null) {
      dialog.current.editText(edit);
    }
  }, [edit]);

  useEffect(() => {
    if (editNumber !== null) {
      dialog.current.editNumber(editNumber);
    }
  }, [editNumber]);

  useEffect(() => {
    if (editName !== null) {
      dialog.current.editName(editName);
    }
  }, [editName]);

  useEffect(() => {
    if (editSelect !== null) {
      dialog.current.editSelect(editSelect);
    }
  }, [editSelect]);

  useEffect(() => {
    if (editSelectLocalidad !== null) {
      dialog.current.editSelectLocalidad(editSelectLocalidad);
    }
  }, [editSelectLocalidad]);

  useEffect(() => {
    if (editDate !== null) {
      dialog.current.editDate(editDate);
    }
  }, [editDate]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'INFORMACIÓN DE PRODUCTOR',
        columns: [
          {
            Header: '-',
            columns: [
              {
                Header: 'CLAVE',
                accessor: 'productor_clave_tx',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'text',
                    accessor: 'productor_clave_tx',
                    oldValue: value,
                    columnName: 'CLAVE',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    setData: newValue =>
                      updateLocalData(
                        { productor_clave_tx: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEdit(e, editData)}
                      column="productor_clave_tx"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'CÓDIGO',
                accessor: 'productor_codigo_tx',
                sortable: true,
                minWidth: 50,
                show: !!+coopConfig.config?.usa_codigo_tx,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'text',
                    accessor: 'productor_codigo_tx',
                    oldValue: value,
                    columnName: 'CÓDIGO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    setData: newValue =>
                      updateLocalData(
                        { productor_codigo_tx: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEdit(e, editData)}
                      column="productor_codigo_tx"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'LOCALIDAD',
                accessor: 'localidad',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 'localidad',
                    id_localidad: row.original.id_localidad,
                    id_municipio: row.original.id_municipio,
                    columnName: 'LOCALIDAD / MPIO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    setData: newValue =>
                      updateLocalData(
                        {
                          localidad: newValue.localidad,
                          id_localidad: newValue.id_localidad,
                          municipio: newValue.municipio,
                          id_municipio: newValue.id_municipio,
                        },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelectLocalidad(e, editData)}
                      column="localidad"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'MUNICIPIO',
                accessor: 'municipio',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 'localidad',
                    id_localidad: row.original.id_localidad,
                    id_municipio: row.original.id_municipio,
                    columnName: 'LOCALIDAD / MPIO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    setData: newValue =>
                      updateLocalData(
                        {
                          localidad: newValue.localidad,
                          id_localidad: newValue.id_localidad,
                          municipio: newValue.municipio,
                          id_municipio: newValue.id_municipio,
                        },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelectLocalidad(e, editData)}
                      column="municipio"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'REGIÓN',
                accessor: 'region',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 'region',
                    oldValue: row.original.id_cuenta_region,
                    columnName: 'REGIÓN',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_ciclo: row.original.id_ciclo,
                    catalog: 'sic/regiones',
                    setData: newValue =>
                      updateLocalData(
                        { region: newValue.label, id_cuenta_region: newValue.id },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelect(e, editData)}
                      column="region"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'NOMBRE PRODUCTOR',
                accessor: 'productor_nombre',
                sortable: true,
                minWidth: 220,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'name',
                    accessor: 'productor_nombre',
                    columnName: 'NOMBRE PRODUCTOR',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    nombres: row.original.nombres,
                    apellido_paterno: row.original.apellido_paterno,
                    apellido_materno: row.original.apellido_materno,
                    setData: newValue =>
                      updateLocalData(
                        {
                          productor_nombre: newValue.fullname,
                          nombres: newValue.nombres,
                          apellido_paterno: newValue.apellido_paterno,
                          apellido_materno: newValue.apellido_materno,
                        },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };

                  if (row.original.productor_uuid !== null) {
                    const updatedClass =
                      Array.isArray(row.original.updated) && row.original.updated.includes('productor_nombre')
                        ? 'updated'
                        : '';
                    return (
                      <div
                        className={updatedClass}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Link
                          to={{ pathname: `/admin/cuentas/perfil/${row.original.productor_uuid}` }}
                          className="m-r-2"
                          style={{ color: '#789D16' }}>
                          <span>{value}</span>
                        </Link>
                        <div className="edit_hover_class" style={{ minWidth: '45px', paddingLeft: '15px' }}>
                          <EditLink onClickEdit={e => onClickEditName(e, editData)} />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <EditLink
                        onClickEdit={e => onClickEditName(e, editData)}
                        column="productor_nombre"
                        updated={row.original.updated}>
                        <p style={{ margin: 0 }}>{value}</p>
                      </EditLink>
                    );
                  }
                },
              },
              {
                Header: 'CURP',
                accessor: 'curp',
                sortable: true,
                minWidth: 190,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'text',
                    accessor: 'curp',
                    oldValue: value,
                    columnName: 'CURP',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData({ curp: newValue }, setData, row.index, setOriginalData, row.original.id_cuenta),
                  };
                  return (
                    <EditLink onClickEdit={e => onClickEdit(e, editData)} column="curp" updated={row.original.updated}>
                      {value}
                    </EditLink>
                  );
                },
              },
              {
                Header: 'FECHA DE NACIMIENTO',
                accessor: 'productor_fecha_nacimiento',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'date',
                    accessor: 'productor_fecha_nacimiento',
                    oldValue: value,
                    columnName: 'FECHA DE NACIMIENTO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { productor_fecha_nacimiento: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink onClickEdit={e => onClickEditDate(e, editData)} column="productor_fecha_nacimiento" updated={row.original.updated}>
                      {value}
                    </EditLink>
                  );
                },
              },
              {
                Header: 'GÉNERO',
                accessor: 'productor_genero',
                sortable: true,
                minWidth: 190,
                Cell: ({ row, value }) => {

                  const editData = {
                    type: 'select',
                    accessor: 'productor_genero',
                    oldValue: row.original.productor_genero,
                    columnName: 'GÉNERO',
                    nombreProductor: row.original.productor_nombre,
                    // nombreParcela: row.original.parcelas?.[4]?.nombre,
                    id_cuenta: row.original.id_cuenta,
                    catalog: 'admin/sic/catalog/generos',
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { productor_genero: newValue.label, productor_genero_id: newValue.id },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };


                  return (
                    <EditLink onClickEdit={e => onClickEditSelect(e, editData)} column="productor_genero" updated={row.original.updated}>
                      {value}
                    </EditLink>
                  );
                },
              },          
              {
                Header: 'NÚMERO DE CELULAR',
                accessor: 'contacto_celular',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'text',
                    accessor: 'contacto_celular',
                    oldValue: value,
                    columnName: 'NÚMERO DE CELULAR',                          
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    max: 25,
                    setData: newValue =>
                      updateLocalData(
                        { contacto_celular: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEdit(e, editData)}
                      column="contacto_celular"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'CORREO ELECTRÓNICO',
                accessor: 'contacto_correo',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'text',
                    accessor: 'contacto_correo',
                    oldValue: value,
                    columnName: 'CORREO ELECTRÓNICO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { contacto_correo: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEdit(e, editData)}
                      column="contacto_correo"
                      updated={row.original.updated}>
                      {value}
                    </EditLink>
                  );
                },
              },
              {
                Header: 'CATEGORÍA CICLO ACTUAL',
                accessor: 'cuenta_categoria',
                sortable: true,
                minWidth: 90,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 'cuenta_categoria',
                    oldValue: row.original.id_cuenta_categoria,
                    columnName: 'CATEGORÍA CICLO ACTUAL',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_ciclo: row.original.id_ciclo,
                    catalog: 'categorias',
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        {
                          cuenta_categoria: newValue.label,
                          id_cuenta_categoria: newValue.id,
                          cuenta_categoria_color: '',
                        },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  if (value !== null) {
                    return (
                      <EditLink
                        onClickEdit={e => onClickEditSelect(e, editData)}
                        column="cuenta_categoria"
                        updated={row.original.updated}>
                        <LabelCategory label={value} color={row.original.cuenta_categoria_color} />
                      </EditLink>
                    );
                  }
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelect(e, editData)}
                      column="cuenta_categoria"
                      updated={row.original.updated}>
                      {value}
                    </EditLink>
                  );
                },
              },
              {
                Header: 'CATEGORÍA CICLO PASADO',
                accessor: 'categoria_anterior',
                sortable: true,
                minWidth: 50,
                 Cell: ({ row }) => {
                  if (row.original.cuenta_categoria_anterior !== null && row.original.cuenta_categoria_anterior !== undefined) {
                    const categoriaAnterior = row.original.cuenta_categoria_anterior;
                    const colorCategoriaAnterior = row.original.cuenta_categoria_color_anterior;
                    return <LabelCategory label={categoriaAnterior} color={colorCategoriaAnterior} />;
                  }
                  return null;
                },
              },
              {
                Header: 'FECHA INGRESO',
                accessor: 'productor_fecha_ingreso',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'date',
                    accessor: 'productor_fecha_ingreso',
                    oldValue: value,
                    columnName: 'FECHA INGRESO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { productor_fecha_ingreso: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditDate(e, editData)}
                      column="productor_fecha_ingreso"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'FECHA INGRESO TRANSICIÓN',
                accessor: 'productor_fecha_transicion',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'date',
                    accessor: 'productor_fecha_transicion',
                    oldValue: value,
                    columnName: 'FECHA INGRESO TRANSICIÓN',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { productor_fecha_transicion: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditDate(e, editData)}
                      column="productor_fecha_transicion"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
            ],
          },
        ],
      },
      {
        Header: 'INFORMACIÓN PROGRAMA ORGÁNICO',
        columns: [
          {
            Header: '--',
            columns: [
              {
                Header: 'PARCELAS',
                accessor: 'cuenta_parcelas',
                sortable: true,
                minWidth: 70,
                Cell: ({ value, row }) => {
                  return (
                    <button
                      type="button"
                      className="btn btn-sm btn-sirio"
                      style={{ borderRadius: '10px 10px 10px 10px' }}
                      onClick={() => {
                        setShowParcelas(true);
                        setParcelasSelectedData({
                          id_cuenta: row.original.id_cuenta,
                          nombreProductor: row.original.productor_nombre,
                        });
                        setSelectedRow(row.index);
                      }}>
                      <i className="fa fa-list"></i> {value} parcelas
                    </button>
                  );
                },
              },
              {
                Header: 'FECHA APLICACIÓN PROD. PROHIBIDOS',
                accessor: 's3_cafeorganico_otrans_fechaprohibidos',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'date',
                    accessor: 's3_cafeorganico_otrans_fechaprohibidos',
                    oldValue: value,
                    columnName: 'FECHA APLICACIÓN PROD. PROHIBIDOS',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s3_cafeorganico_otrans_fechaprohibidos: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditDate(e, editData)}
                      column="s3_cafeorganico_otrans_fechaprohibidos"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'PROD. PROHIBIDOS UTILIZADOS',
                accessor: 's3_cafeorganico_otrans_insumosprohibidos',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'text',
                    accessor: 's3_cafeorganico_otrans_insumosprohibidos',
                    oldValue: value,
                    columnName: 'PROD. PROHIBIDOS UTILIZADOS',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s3_cafeorganico_otrans_insumosprohibidos: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEdit(e, editData)}
                      column="s3_cafeorganico_otrans_insumosprohibidos"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'NIVEL DE RIESGO',
                accessor: 'nivel_riesgo',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  if (!value) {
                    return '';
                  }
                  const editData = {
                    type: 'select',
                    accessor: 'nivel_riesgo',
                    oldValue: row.original.nivel_riesgo_id,
                    columnName: 'NIVEL DE RIESGO',
                    nombreProductor: row.original.productor_nombre,
                    // nombreParcela: row.original.parcelas?.[4]?.nombre,
                    id_cuenta: row.original.id_cuenta,
                    catalog: 'admin/sic/catalog/niveles_riesgo',
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { nivel_riesgo: newValue.label, nivel_riesgo_id: newValue.id },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelect(e, editData)}
                      column="nivel_riesgo"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'EVALUACIÓN COMITÉ',
                accessor: 'condicion_str',
                sortable: true,
                minWidth: 50,
              },
              {
                Header: 'CONDICIONES DE EVALUACIÓN',
                accessor: 'condicion_detalle',
                sortable: true,
                minWidth: 290,
              },
              {
                Header: 'HAS TOTALES DE TERRENO',
                accessor: 'calculada',
                Cell: ({ row }) => {
                  let c = row.original;

                  const t =
                    parseFloat(c.cuenta_superficie_total) +
                    parseFloat(c.s2_autoconsumo_superficie) +
                    parseFloat(c.s2_otrocultivo_superficie) +
                    parseFloat(c.s2_potrero_superficie) +
                    parseFloat(c.s2_bosque_superficie) +
                    parseFloat(c.s2_acahual_superficie) +
                    parseFloat(c.s2_reserva_superficie);

                  const updatedClass =
                    Array.isArray(row.original.updated) && row.original.updated.includes('cuenta_superficie_total')
                      ? 'updated'
                      : '';
                  return (
                    <div className={updatedClass}>
                      <LabelNumber value={t} digits={2} />
                    </div>
                  );
                },
                sortable: true,
                minWidth: 50,
              },

              {
                Header: 'HAS CAFÉ ORGÁNICO',
                accessor: 'cuenta_superficie_total',
                sortable: true,
                minWidth: 50,
                Cell: ({ value, row }) => {
                  const updatedClass =
                    Array.isArray(row.original.updated) && row.original.updated.includes('cuenta_superficie_total')
                      ? 'updated'
                      : '';
                  return (
                    <div className={updatedClass}>
                      <LabelNumber value={value} digits={2} />
                    </div>
                  );
                },
              },
              {
                Header: 'TIPO DE TENENCIA',
                accessor: 'tenencia_tierra',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 'tenencia_tierra',
                    oldValue: row.original.tenencia_tierra_id,
                    columnName: 'TIPO DE TENENCIA',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    catalog: 'admin/sic/catalog/tenencia',
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelect(e, editData)}
                      column="tenencia_tierra"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'DOCUMENTO DE PROPIEDAD',
                accessor: 'tenencia_papeles_sino',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 'tenencia_papeles_sino',
                    oldValue: row.original.tenencia_papeles_sino_id,
                    columnName: 'DOCUMENTO DE PROPIEDAD',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    catalog: 'admin/sic/catalog/si_no',
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { tenencia_papeles_sino: newValue.label, tenencia_papeles_sino_id: newValue.id },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelect(e, editData)}
                      column="tenencia_papeles_sino"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'ESTIMACIÓN DE COSECHA TOTAL ' + unitConversions?.sum_tx_estimacion_cosecha_label,
                accessor: 'tx_estimacion_cosecha_inspector_total',
                sortable: true,
                minWidth: 50,
                width: 150,
                show: auth.user.id_cooperativa != 3,
                Cell: ({ value, row }) => {
                  const updatedClass =
                    Array.isArray(row.original.updated) &&
                    row.original.updated.includes('tx_estimacion_cosecha_inspector_total')
                      ? 'updated'
                      : '';
                  return (
                    <div className={updatedClass}>
                      <LabelNumber value={value} digits={2} />
                    </div>
                  );
                },
              },
              {
                Header: 'ESTIMACIÓN DE ENTREGA DE COSECHA TOTAL (PRODUCTOR) ' + unitConversions?.sum_tx_estimacion_cosecha_label,
                accessor: 'tx_estimacion_cosecha_productor_total',
                sortable: true,
                minWidth: 50,
                Cell: ({ value, row }) => {
                  const updatedClass =
                    Array.isArray(row.original.updated) &&
                    row.original.updated.includes('tx_estimacion_cosecha_productor_total')
                      ? 'updated'
                      : '';
                  return (
                    <div className={updatedClass}>
                      <LabelNumber value={value} digits={2} />
                    </div>
                  );
                },
              },
              {
                Header: '# PLANTAS TOTALES CAFÉ',
                accessor: 'tx_no_plantas_totales',
                sortable: true,
                minWidth: 50,
                Cell: ({ value, row }) => {
                  const updatedClass =
                    Array.isArray(row.original.updated) && row.original.updated.includes('tx_no_plantas_totales')
                      ? 'updated'
                      : '';
                  return (
                    <div className={updatedClass}>
                      <LabelNumber value={value} />
                    </div>
                  );
                },
              },
              {
                Header: '# PLANTAS PRODUCTIVAS TOTALES',
                accessor: 'plantas_productivas_totales',
                sortable: true,
                minWidth: 50,
                // eslint-disable-next-line eqeqeq
                show: auth.user.id_cooperativa != 17,
                Cell: ({ value }) => {
                  return <LabelNumber value={value}></LabelNumber>;
                },
              },
              {
                Header: '# PLANTAS IMPRODUCTIVAS TOTALES',
                accessor: 'plantas_improductivas_totales',
                sortable: true,
                minWidth: 50,
                // eslint-disable-next-line eqeqeq
                show: auth.user.id_cooperativa != 17,
                Cell: ({ value }) => {
                  return <LabelNumber value={value}></LabelNumber>;
                },
              },
              {
                Header: 'FECHA INSPECCIÓN INTERNA',
                accessor: 's3_inspeccion_fecha',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    ...row.original,
                    type: 'date',
                    accessor: 's3_inspeccion_fecha',
                    oldValue: value,
                    columnName: 'FECHA INSPECCIÓN INTERNA',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s3_inspeccion_fecha: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditDate(e, editData)}
                      column="s3_inspeccion_fecha"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'NOMBRE INSPECTOR INTERNO',
                accessor: 's1_lista_inspector_str',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'select',
                    accessor: 's1_lista_inspector_str',
                    oldValue: row.original.s1_lista_inspector,
                    columnName: 'NOMBRE INSPECTOR INTERNO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    catalog: 'admin/sic/inspectores',
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s1_lista_inspector_str: newValue.label, s1_lista_inspector: newValue.id },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditSelect(e, editData)}
                      column="s1_lista_inspector_str"
                      updated={row.original.updated}>
                      <p style={{ margin: 0 }}>{value}</p>
                    </EditLink>
                  );
                },
              },
            ],
          },
        ],
      },
      {
        Header: 'SUPERFICIE CULTIVOS NO CAFÉ (HAS.)',
        columns: [
          {
            Header: '-- -',
            columns: [
              {
                Header: 'AUTOCONSUMO',
                accessor: 's2_autoconsumo_superficie',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'number',
                    accessor: 's2_autoconsumo_superficie',
                    oldValue: value,
                    columnName: 'AUTOCONSUMO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s2_autoconsumo_superficie: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditNumber(e, editData)}
                      column="s2_autoconsumo_superficie"
                      updated={row.original.updated}>
                      <LabelNumber value={value} digits={2}></LabelNumber>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'OTROS CULTIVOS',
                accessor: 's2_otrocultivo_superficie',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'number',
                    accessor: 's2_otrocultivo_superficie',
                    oldValue: value,
                    columnName: 'OTROS CULTIVOS',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s2_otrocultivo_superficie: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditNumber(e, editData)}
                      column="s2_otrocultivo_superficie"
                      updated={row.original.updated}>
                      <LabelNumber value={value} digits={2}></LabelNumber>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'POTRERO',
                accessor: 's2_potrero_superficie',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'number',
                    accessor: 's2_potrero_superficie',
                    oldValue: value,
                    columnName: 'POTRERO',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s2_potrero_superficie: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditNumber(e, editData)}
                      column="s2_potrero_superficie"
                      updated={row.original.updated}>
                      <LabelNumber value={value} digits={2}></LabelNumber>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'BOSQUE',
                accessor: 's2_bosque_superficie',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'number',
                    accessor: 's2_bosque_superficie',
                    oldValue: value,
                    columnName: 'BOSQUE',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s2_bosque_superficie: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditNumber(e, editData)}
                      column="s2_bosque_superficie"
                      updated={row.original.updated}>
                      <LabelNumber value={value} digits={2}></LabelNumber>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'ACAHUAL',
                accessor: 's2_acahual_superficie',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'number',
                    accessor: 's2_acahual_superficie',
                    oldValue: value,
                    columnName: 'ACAHUAL',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s2_acahual_superficie: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditNumber(e, editData)}
                      column="s2_acahual_superficie"
                      updated={row.original.updated}>
                      <LabelNumber value={value} digits={2}></LabelNumber>
                    </EditLink>
                  );
                },
              },
              {
                Header: 'RESERVA',
                accessor: 's2_reserva_superficie',
                sortable: true,
                minWidth: 50,
                Cell: ({ row, value }) => {
                  const editData = {
                    type: 'number',
                    accessor: 's2_reserva_superficie',
                    oldValue: value,
                    columnName: 'RESERVA',
                    nombreProductor: row.original.productor_nombre,
                    id_cuenta: row.original.id_cuenta,
                    id_sic_informacion_general: row.original.id_sic_informacion_general,
                    setData: newValue =>
                      updateLocalData(
                        { s2_reserva_superficie: newValue },
                        setData,
                        row.index,
                        setOriginalData,
                        row.original.id_cuenta
                      ),
                  };
                  return (
                    <EditLink
                      onClickEdit={e => onClickEditNumber(e, editData)}
                      column="s2_reserva_superficie"
                      updated={row.original.updated}>
                      <LabelNumber value={value} digits={2}></LabelNumber>
                    </EditLink>
                  );
                },
              },
            ],
          },
        ],
      },
    ],
    [unitConversions]
  );

  const searchColumns = React.useMemo(
    () => [
      { value: 0, target: '', label: 'TODO' },
      { value: 1, target: 'productor_clave_tx', label: 'CLAVE' },
      { value: 2, target: 'productor_codigo_tx', label: 'CÓDIGO' },
      { value: 3, target: 'localidad', label: 'LOCALIDAD' },
      { value: 4, target: 'municipio', label: 'MUNICIPIO' },
      { value: 5, target: 'region', label: 'REGIÓN' },
      { value: 6, target: 'productor_nombre', label: 'NOMBRE PRODUCTOR' },
      { value: 7, target: 'curp', label: 'CURP' },
      { value: 8, target: 'contacto_celular', label: 'NÚMERO DE CELULAR' },
      { value: 9, target: 'contacto_correo', label: 'CORREO ELECTRÓNICO' },
      { value: 10, target: 'cuenta_categoria', label: 'CATEGORÍA CICLO ACTUAL' },
      { value: 11, target: 'categoria_anterior', label: 'CATEGORÍA CICLO PASADO' },
      { value: 12, target: 'productor_fecha_ingreso', label: 'FECHA INGRESO' },
      { value: 13, target: 'productor_fecha_transicion', label: 'FECHA INGRESO TRANSICIÓN' },
      { value: 14, target: 'cuenta_parcelas', label: 'NUMERO DE PARCELAS' },
      { value: 15, target: 's3_cafeorganico_otrans_fechaprohibidos', label: 'FECHA APLICACIÓN PROD. PROHIBIDOS' },
      { value: 16, target: 's3_cafeorganico_otrans_insumosprohibidos', label: 'PROD. PROHIBIDOS UTILIZADOS' },
      { value: 17, target: 'nivel_riesgo', label: 'NIVEL DE RIESGO' },
      { value: 18, target: 'condicion_str', label: 'EVALUACIÓN COMITÉ' },
      { value: 19, target: 'condicion_detalle', label: 'CONDICIONES DE EVALUACIÓN' },
      { value: 20, target: 'calculada', label: 'HAS TOTALES DE TERRENO' },
      { value: 21, target: 'cuenta_superficie_total', label: 'HA CAFÉ ORGÁNICO' },
      { value: 22, target: 'tenencia_tierra', label: 'TIPO DE TENENCIA' },
      { value: 23, target: 'tenencia_papeles_sino', label: 'DOCUMENTO DE PROPIEDAD' },
      {
        value: 24,
        target: 'tx_estimacion_cosecha_inspector_total',
        label: 'ESTIMACIÓN DE COSECHA TOTAL ' + unitConversions?.sum_tx_estimacion_cosecha_label,
      },
      {
        value: 25,
        target: 'tx_estimacion_cosecha_productor_total',
        label: 'ESTIMACIÓN DE ENTREGA DE COSECHA TOTAL (PRODUCTOR) ' + unitConversions?.sum_tx_estimacion_cosecha_label,
      },
      { value: 26, target: 'tx_no_plantas_totales', label: '# PLANTAS TOTALES CAFÉ' },
      { value: 27, target: 'plantas_productivas_totales', label: '# PLANTAS PRODUCTIVAS TOTALES' },
      { value: 28, target: 'plantas_improductivas_totales', label: '# PLANTAS IMPRODUCTIVAS TOTALES' },
      { value: 29, target: 's3_inspeccion_fecha', label: 'FECHA INSPECCIÓN INTERNA' },
      { value: 30, target: 's1_lista_inspector_str', label: 'NOMBRE INSPECTOR INTERNO' },
      { value: 31, target: 's2_autoconsumo_superficie', label: 'AUTOCONSUMO' },
      { value: 32, target: 's2_otrocultivo_superficie', label: 'OTROS CULTIVOS' },
      { value: 33, target: 's2_potrero_superficie', label: 'POTRERO' },
      { value: 34, target: 's2_bosque_superficie', label: 'BOSQUE' },
      { value: 35, target: 's2_acahual_superficie', label: 'ACAHUAL' },
      { value: 36, target: 's2_reserva_superficie', label: 'RESERVA' },
    ],
    [auth]
  );

  const unitConversionsLabels = data => {
    if (data) {
      const d = data[0]; // applied to headers for all columns
      setUnitConversions({
        sum_tx_estimacion_cosecha_label: d.sum_tx_estimacion_cosecha_label ? d.sum_tx_estimacion_cosecha_label.toUpperCase() : 'KGS',
      });
    }
  };

  const setFilter = value => {
    setCiclo(value.target);

    setBlocking(true);
    Api.search('tx_padron_completo', {
      filter: [{ column: 'id_ciclo', operator: '=', value: value.target.id }],
    })
      .then(response => {
        setData(response.data);
        setOriginalData(response.data);
        unitConversionsLabels(response.data);
        setBlocking(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error:', e);
        setBlocking(false);
      });
  };

  const setDataFilter = data => {
    setData(
      data.map(it => {
        return { ...it, updated: null };
      })
    );
  };

  /**
   * Get Headers background colors
   * alternate first row colors,
   * then alternate second row columns for parcelas only, ie after row 3
   * @param {number} row row number
   * @param {number} column column number
   * @returns {string} color
   */
  const getBackgroundColor = (row, column) => {
    if (row === 0) {
      if (column % 2 === 0) return '#5F5F59';
      return '#91918A';
    }
    if (row === 1) {
      // TODO: check this change: it renders the header with the same color in any case
      if (column > 2 && column % 2 === 0) return 'rgb(247, 239, 231)';
      return 'rgb(247, 239, 231)';
    }
    return 'rgb(247, 239, 231)';
  };

  /**
   *  Get Header font colors, first row white, rest dark
   * @param {number} row row number
   * @returns {string} color
   */
  const getColor = row => (row === 0 ? 'white' : 'rgb(95, 95, 89)');

  const closeModalParcelas = () => {
    setShowParcelas(false);
    setParcelasSelectedData({
      id_cuenta: null,
      nombreProductor: null,
    });
  };

  return (
    <div>
      {/* modals area */}
      <ModalParcelas
        id_cuenta={parcelasSelectedData.id_cuenta}
        nombreProductor={parcelasSelectedData.nombreProductor}
        id_ciclo={ciclo?.id}
        show={showParcelas}
        onClickEdit={onClickEdit}
        onClickEditNumber={onClickEditNumber}
        handleClose={closeModalParcelas}
        updateParentData={setData}
        updateParentDataOriginal={setOriginalData}
        parentRow={selectedRow}
      />
      <ModalPadron ref={dialog} />
      {/* header control and filters */}
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/padron">Padrón</Link>
          </li>
          <li className="breadcrumb-item active">Padrón completo</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>
      {/* Table list */}
      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-10">
            <SearchColumn
              data={originalData}
              columns={searchColumns}
              setDataFilter={evt => {
                setDataFilter(evt);
              }}
            />
          </div>
          <div className="col-2">
            {ciclo?.uuid && auth.hasPermission('PADRON_COMPLETO_EXPORTAR') && (
              <div className="d-flex justify-content-end">
                <LinkDownload
                  url={`xls/padron_completo/${ciclo?.id}`}
                  label="Descargar Excel"
                  icon={env.images.icon.xls}></LinkDownload>
              </div>
            )}
          </div>
        </div>

        <NoPagination
          blocking={blocking}
          columns={columns}
          data={data}
          fnBackgroundColor={getBackgroundColor}
          sticky={false}
          fnColor={getColor}></NoPagination>
      </CardContainer>
    </div>
  );
};

export default PadronCompletoList;
