import React, { useState, useEffect } from 'react';
import Api from '../../../../api/global.service';
import mapChartData from '../../../../utils/mapChartData.js';
import renderCustomizedLabel from '../../../../utils/renderCustomizedLabel.js';
import BlockUi from 'react-block-ui';

import {
	PieChart,
	Pie,
	Cell,
	Label,
  Tooltip
} from 'recharts';

const LotesGraficaEstatus = ({ data }) => {

  const [dataGrafica, setDataGrafica] = useState([]);
  const [blocking, setBlocking] = useState(true);

  const RADIAN = Math.PI / 180;

  useEffect(() => {
      getData();
  }, [data]);

  const getData = () => {
    setBlocking(true);
    let en_bodega = data.filter(d => Number(d.id_estatus_entrega) === 1).reduce((sum, d) => sum + Number(d.peso_neto), 0);
    let rechazado = data.filter(d => Number(d.id_estatus_entrega) === 2).reduce((sum, d) => sum + Number(d.peso_neto), 0);
    let en_centro_acopio = data.filter(d => Number(d.id_estatus_entrega) === 3).reduce((sum, d) => sum + Number(d.peso_neto), 0);
    let sin_asignar = data.filter(d => d.id_estatus_entrega === null).reduce((sum, d) => sum + Number(d.peso_neto), 0);
    setDataGrafica([
      { name: 'En bodega', value: en_bodega, color: '#FFB94C' },
      { name: 'En centro de acopio', value: en_centro_acopio, color: '#A46B00' },
      { name: 'Rechazados', value: rechazado, color: '#BA1B1B' },
      { name: 'Sin Asignar', value: sin_asignar, color: '#9B9B9B' },
    ]);
    setBlocking(false);
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>

      <div className="row" style={{ marginBottom: 10 }}>
      <div className="col-md-3 col-lg-3 col-xs-6">
        <PieChart width={250} height={250}>
          <Pie
            data={dataGrafica.filter(d => d.value > 0)}
            cx={100}
            cy={125}
            fill="#8884D8"
            label={renderCustomizedLabel}
            labelLine={false}
            dataKey="value">
            {dataGrafica.filter(d => d.value > 0).map((entry, index) => (
              <React.Fragment key={`cell-${index}`}>
                <Cell fill={entry.color} />
              </React.Fragment>
            ))}
                      <Label />

          </Pie>
          <Tooltip />
        </PieChart>
      </div>
      <div className="col-md-3 col-lg-3 col-xs-6">
        <div className='row' style={{marginTop : '25%'}}>
          <div className="col-md-12 col-lg-12 col-xs-12" >
              {dataGrafica.filter(d => d.value > 0).map(function (dg) {
                return(
                  <>
                    <i className='fa fa-square' style={{color : dg.color}}></i>
                      <span className='acopio-lote-total ml-3'>{dg.value.toFixed(2)}</span>
                      <p className='acopio-lote-desc ml-4'>{'kgs ' + dg.name.toLowerCase() }</p>
                </>
              )})}
          </div>
        </div>
      </div>
      </div>
    </BlockUi>
  );
};

export default LotesGraficaEstatus;
