import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Api from '../../api/global.service';
import Swal from 'sweetalert2';
import generalSuccessModal from '../modal/modal-success';
import { useHistory } from 'react-router-dom';
// import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import ExampleCustomInput from '../modal/input';
import Moment from 'moment';
import { es } from 'date-fns/locale';

const DateForm = ({
  initialValue,
  columnLabel,
  column,
  idCuenta,
  onClose,
  showModal,
  idForm,
  setData,
  // min = 0,
  idParcela,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    // register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      dateColumn: initialValue ? new Date(Date.parse(initialValue + 'T12:00:00')) : null,
    },
  });

  useEffect(() => {
    reset({
      dateColumn: initialValue ? new Date(Date.parse(initialValue + 'T12:00:00')) : null,
    });
  }, [showModal]);

  const onSubmit = values => {
    setLoading(true);
    const formatedDate = Moment(values.dateColumn).format('yyyy-MM-DD');
    const payload = {
      id_cuenta: idCuenta,
      new_value: formatedDate,
      column: column,
      id_sic_informacion_general: idForm,
      id_parcela: idParcela,
    };

    Api.create(`admin/sic/padron_edit/${column}`, payload)
      .then(response => {
        const { success, message, data } = response.data;
        const finish = () => {
          if (setData) {
            setData(formatedDate);
          } else {
            history.go(0);
          }
          onClose();
          setLoading(false);
        };
        generalSuccessModal(success, `${columnLabel} actualizado con éxito`, finish, message, data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        const err = error.response.data;
        const errorMessage = err.errors['new_value'] !== undefined ? err.errors['new_value'] : err.errors.message;
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage,
        });
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className="modal-body">
        <div className="row my-4">
          <h4
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            {columnLabel}
          </h4>
          <div className="col-12">
            <Controller
              name="dateColumn"
              control={control}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Elige una fecha"
                  onChange={date => field.onChange(date)}
                  selected={field.value}
                  wrapperClassName="w-100"
                  customInput={<ExampleCustomInput />}
                  dateFormat={'dd/MMM/yyyy'}
                  locale={es}
                />
              )}
            />
            {errors?.dateColumn && <div className="invalid-feedback">{errors?.dateColumn}</div>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loading && (
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Guardando...</span>
          </div>
        )}
        {!loading && (
          <>
            <button onClick={onClose} type="button" className="btn btn-outline-secondary" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i> Cancelar
            </button>
            <button type="submit" className="btn btn-outline-secondary btn-sirio" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-save"></i> Guardar
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default DateForm;
