import React, { PureComponent, useEffect,useState } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';


const configGrafica = [
  {
    subject: 'Fragancia / aroma',
    column: 'fragancia_aroma',
    valor: 0,
  },
  {
    subject: 'Sabor',
    column: 'sabor',
    valor: 0,
  },
  {
    subject: 'Sabor residual',
    column: 'sabor_residual',
    valor: 0,
  },
  {
    subject: 'Acidez',
    column: 'acidez',
    valor: 0,
  },
  {
    subject: 'Cuerpo',
    column: 'cuerpo',
    valor: 0,
  },
  {
    subject: 'Balance',
    column: 'balance',
    valor: 0,
  },
  {
    subject: 'Uniformidad',
    column: 'uniformidad',
    valor: 0,
  },
  {
    subject: 'Taza limpia',
    column: 'taza_limpia',
    valor: 0,
  },
  {
    subject: 'Dulzor',
    column: 'dulzor',
    valor: 0,
  },
  {
    subject: 'Catador',
    column: 'catador',
    valor: 0,
  }
];

const GraficaSensorial = ({data}) => {
  const [dataGrafica, setDataGrafica] = useState(configGrafica);
  const [puntaje, setPuntaje] = useState(0);
  useEffect(() => {
    let _configGrafica = configGrafica;
    let puntaje = 0;
    for (let c = 0; c < _configGrafica.length; c++) {
      if (typeof data[_configGrafica[c].column] !== 'undefined') {
        _configGrafica[c].valor = Number(data[_configGrafica[c].column]);
        puntaje += Number(data[_configGrafica[c].column]);
        console.log(Number(data[_configGrafica[c].column]));
      }
    }
    setPuntaje(puntaje);
    setDataGrafica(_configGrafica);
  },[data]);

    return (
      <>
        {puntaje > 0 && (<div className='col-md-6 offset-1' style={{padding : 10,backgroundColor : '#FFEED9'}}>Puntaje: {puntaje}</div>)}
        <RadarChart width={(window.innerWidth / 3)} height={(window.innerWidth / 3)}  cx="50%" cy="50%" outerRadius="75%" data={dataGrafica}>
          <PolarGrid  />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={90} domain={[0, 10]}/>
          <Radar max={10} min={6} animationDuration={2000} dot={true} name="Análisis sensorial" dataKey="valor" stroke="#C7AE34" fill="#C7AE34" fillOpacity={0.6} />
        </RadarChart>
      </>
    );
}

export default GraficaSensorial;