import React from 'react';
import { Colors } from '../../constants';

const CheckboxList = ({ name, value, handleCheck, checked }) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <input
      className="form-check-input"
      name={name}
      type="checkbox"
      style={{ width: 20, height: 20, accentColor: Colors.primary_600 }}
      value={value}
      onChange={handleCheck}
      checked={checked}
    />
  );
};

export default CheckboxList;
