import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Api from '../../api/global.service';
import Swal from 'sweetalert2';
import generalSuccessModal from '../modal/modal-success';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

const LocationForm = ({ idLocalidad, idMunicipio, columnLabel, column, idCuenta, onClose, showModal, setData }) => {
  const [loading, setLoading] = useState(false);
  const [loadingLocalidades, setLoadingLocalidades] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      id_localidad: idLocalidad,
      id_municipio: idMunicipio,
    },
  });

  const { id_localidad, id_municipio } = watch();

  useEffect(() => {
    setLoading(true);
    setInitialLoad(true);
    reset({
      id_localidad: idLocalidad,
      id_municipio: idMunicipio,
    });

    setLoadingLocalidades(true);
    Api.getAll('municipios')
      .then(response => {
        const opts = response.data.map(item => {
          return { value: item['id'], label: item['nombre'] };
        });
        setMunicipios(opts);
        // Set initial value
        // eslint-disable-next-line eqeqeq
        setValue(
          'id_municipio',
          opts.find(item => item.value == idMunicipio)
        );
        setLoading(false);
        Api.search('localidades', {
          filter: [{ column: 'id_municipio', operator: '=', value: idMunicipio }],
          sort: [{ column: 'nombre', sort: 'asc' }],
        }).then(response => {
          const opts = response.data.map(item => {
            return { value: item['id'], label: item['nombre'] };
          });
          setLocalidades(opts);
          // Set initial value
          // eslint-disable-next-line eqeqeq
          setValue(
            'id_localidad',
            opts.find(item => item.value == idLocalidad)
          );
          setLoadingLocalidades(false);
          setInitialLoad(false);
        });
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('error: ', e);
        setLoading(false);
      });
  }, [showModal]);

  useEffect(() => {
    if (!initialLoad) {
      setLoadingLocalidades(true);
      Api.search('localidades', {
        filter: [{ column: 'id_municipio', operator: '=', value: id_municipio.value }],
        sort: [{ column: 'nombre', sort: 'asc' }],
      }).then(response => {
        const opts = response.data.map(item => {
          return { value: item['id'], label: item['nombre'] };
        });
        setValue('id_localidad', null);
        setLocalidades(opts);
        setLoadingLocalidades(false);
      });
    }
  }, [id_municipio.value]);

  const onSubmit = values => {
    setLoading(true);
    const payload = {
      id_cuenta: idCuenta,
      id_localidad: values.id_localidad.value,
      id_municipio: values.id_municipio.value,
      column: column,
    };

    Api.create(`admin/sic/padron_edit/${column}`, payload)
      .then(response => {
        const { success, message, data } = response.data;
        const finish = () => {
          if (setData) {
            setData({
              localidad: values.id_localidad.label,
              id_localidad: values.id_localidad.value,
              municipio: values.id_municipio.label,
              id_municipio: values.id_municipio.value,
            });
          } else {
            history.go(0);
          }
          onClose();
          setLoading(false);
        };
        generalSuccessModal(success, `${columnLabel} actualizado con éxito`, finish, message, data);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        const err = error.response.data;
        const errorMessage = err.errors['new_value'] !== undefined ? err.errors['new_value'] : err.errors.message;
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: '',
          text: errorMessage,
        });
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div className="modal-body">
        <div className="row my-4">
          <h4
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            {columnLabel}
          </h4>
          <div className="col-12">
            <h6 style={{ marginTop: '15px' }}>Municipio</h6>
            <Controller
              name="id_municipio"
              control={control}
              defaultValue={idMunicipio}
              isLoading={loading}
              render={({ field }) => (
                <Select
                  {...field}
                  options={municipios}
                  required
                  isSearchable
                  styles={{
                    valueContainer: base => ({
                      ...base,
                      paddingLeft: 38,
                    }),
                    singleValue: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    placeholder: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    fontSize: 18,
                  }}
                />
              )}
            />
            {errors?.id_localidad && <div className="invalid-feedback">{errors?.id_municipio}</div>}
          </div>
          <div className="col-12">
            <h6 style={{ marginTop: '15px' }}>Localidad</h6>
            <Controller
              name="id_localidad"
              control={control}
              defaultValue={idLocalidad}
              render={({ field }) => (
                <Select
                  {...field}
                  options={localidades}
                  isLoading={loadingLocalidades}
                  isDisabled={loadingLocalidades}
                  placeholder="Elige una localidad"
                  required
                  isSearchable
                  styles={{
                    valueContainer: base => ({
                      ...base,
                      paddingLeft: 38,
                    }),
                    singleValue: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    placeholder: base => ({
                      ...base,
                      fontSize: 18,
                    }),
                    fontSize: 18,
                  }}
                />
              )}
            />
            {errors?.id_localidad && <div className="invalid-feedback">{errors?.id_localidad}</div>}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loading && (
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Guardando...</span>
          </div>
        )}
        {(!loading || !loadingLocalidades) && (
          <>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-outline-secondary"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i> Cancelar
            </button>
            <button
              type="submit"
              disabled={!id_localidad || !id_municipio}
              className="btn btn-outline-secondary btn-sirio"
              style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-save"></i> Guardar
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default LocationForm;
