import React from 'react';

class CardColor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var mode = this.props.mode || 'normal';
    var size = this.props.size || '40px';
    var icon = this.props.icon || 'circle';

    return (
      <div className="row card-dashboard" style={{ ...this.props.style, height: this.props.height }}>
        {mode == 'normal' && (
          <>
            <div className="col-xl-3 col-md-3">
              <i className={'fa fa-' + icon + ' pt-2'} style={{ color: this.props.color }}></i>
            </div>
            <div className="col-xl-9 col-md-9">
              <h1 className="font-lato-normal-600 w-100" style={{ fontSize: this.props.size }}>
                {this.props.value}
              </h1>
              <h6 className="font-lato-normal-400 w-100">{this.props.label}</h6>
            </div>
          </>
        )}
        {mode == 'onlyData' && (
          <>
            <div className="col-xl-12 col-md-12">
              <h1 className="font-lato-normal-600 w-100" style={{ fontSize: this.props.size }}>
                {this.props.value}
              </h1>
              <h6 className="font-lato-normal-400 w-100">{this.props.label}</h6>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default CardColor;
