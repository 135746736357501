import usePageTracking from '../../hooks/usePageTracking';
import React from 'react';

export const wihtAnalytics = WC => {
  const Comp = props => {
    usePageTracking();
    return <WC {...props} />;
  };
  Comp.displayName = 'app with analytics';
  return Comp;
};
