import React from 'react';
import { Link } from 'react-router-dom';
import DropdownLanguage from './language.jsx';
import DropdownProfile from './profile.jsx';
import { PageSettings } from '../../../config/page-settings.js';
import env from '../../../environment.js';
import { useAuth } from '../../content/auth.jsx';
import DropdownMobile from './DropdownMobile.jsx';
import { NavbarToggler } from 'reactstrap';

class HeaderMobile extends React.Component {
	constructor(props) {
		super(props);
		this.toggleTopMenu = this.toggleTopMenu.bind(this);
		this.media = window.matchMedia("(min-width: 800px)");
		this.state = {
			collapseDropdown: true,
		};
	}

	toggleTopMenu() {
		this.setState({ collapseDropdown: !this.state.collapseDropdown });
	}

	render() {
		return (
			<PageSettings.Consumer>
				{({toggleMobileRightSidebar, pageHeaderLanguageBar, pageTwoSidebar, toggleMobileTopMenu }) => (
					this.props.auth.user && (
						<div id="header" className="header navbar-default">
						<div className="navbar-header">
							{pageTwoSidebar && (
								<button type="button" className={"navbar-toggle pull-left " + (this.state.collapseDropdown ? "" : "toggler-open")} onClick={toggleMobileRightSidebar}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							<Link to="/" className="navbar-brand">
								<img src={env.images.logo} style={{height: 26, marginTop: "-10px", paddingRight: "10px"}} alt={env.appName}/>
								{!this.media.matches ? "" : `v${env.appVersion} ${(env.appEnv !== 'prod') ? `--${env.appEnv} ${this.props.auth.user?.id_cooperativa}: ${this.props.auth.user?.cooperativa} - ${this.props.auth.user?.certificacion}`: ''}`}
							</Link>
							<DropdownProfile />
							<NavbarToggler onClick={toggleMobileTopMenu} type="button" className=" me-2 navbar-toggle" style={{ background: "rgba(0,0,0,0)", border: "none" }}>
                            	<span className="icon-bar"></span>
                            	<span className="icon-bar"></span>
                            	<span className="icon-bar"></span>
                        </NavbarToggler>
						</div>
						<DropdownMobile type="button" />							

						{pageHeaderLanguageBar &&
						(<ul className="navbar-nav navbar-right">							 
							<DropdownLanguage />
						</ul>
						)}						
					</div>
				)

				)}
			</PageSettings.Consumer>
		)
	}
}

export default (props) => {
	const auth = useAuth();
	return <HeaderMobile auth={auth} {...props}/>;
};
