export const transformWord = (word, types = ['singular']) => {
  for (let t = 0; t < types.length; t++) {
    switch (types[t]) {
      case 'singular':
        word = word + '';
        break;
      case 'plural':
        word = word + 's';
        break;
      case 'capitalize':
        word = word.charAt(0).toUpperCase() + word.slice(1);
        break;
      case 'uppercase':
        word = word.toUpperCase();
        break;
      case 'lowercase':
        word = word.toLowerCase();
        break;
    }
  }

  return word === 'undefined' ? '' : word;
};

export default transformWord;
