const Categorias = {
  TRANSICION_0: 1,
  TRANSICION_1: 2,
  TRANSICION_2: 3,
  ORGANICO: 4,
  TRANSICION_3: 5,
  CONVENCIONAL: 6,
  SIN_ASIGNAR: 7,
};

/**
 * returns the following step of certificacion for mayacert or certimex
 * @param {number} cat categoria de certificacion
 * @param {boolean} isCertimex true if certimex false if mayacert
 * @returns number of categoria de certificacion
 */
const getNextCategoria = (cat, isCertimex = true) => {
  const oldCat = !isNaN(cat) ? parseInt(cat) : cat;
  if (isCertimex) {
    switch (oldCat) {
      case Categorias.TRANSICION_0:
        return Categorias.TRANSICION_1;
      case Categorias.TRANSICION_1:
        return Categorias.TRANSICION_2;
      case Categorias.TRANSICION_2:
        return Categorias.ORGANICO;
      default:
        return oldCat;
    }
  } else {
    // IS MAYACERT
    switch (oldCat) {
      case Categorias.TRANSICION_1:
        return Categorias.TRANSICION_2;
      case Categorias.TRANSICION_2:
        return Categorias.TRANSICION_3;
      case Categorias.TRANSICION_3:
        return Categorias.ORGANICO;
      default:
        return oldCat;
    }
  }
};

export default getNextCategoria;
