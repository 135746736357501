import React from 'react';
import Api from '../../api/global.service';
import './label-risk.css';
import SelectLocalidadRiesgo from '../../components/select/select-localidad-riesgo.jsx';


class LabelRisk extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			id: this.props.id,
			label: this.props.label,
			color: this.props.color,
			isShown: false,
			isEdit: false,
			blocking: false,
		};
	}

	saveData() {

		this.setState({isEdit: false, blocking: true});

		Api.update('localidades', this.props.uuid, {
			id_cat_localidad_riesgo: this.state.id,
		})
			.then(response => {
				let data = response.data[0];
				this.setState({
					id: data.id_cat_localidad_riesgo,
					label: data.cat_localidad_riesgo, 
					color: data.cat_localidad_riesgo_color,
					blocking: false
				});
			}).catch(e => {
				this.setState({					
					blocking: false
				});
			});

	}

	setFilter = (value) => {

		this.setState({
			id: value.target?.value || 1,
		});

	}
	
	render() {		
		return (
			<>
				{!this.state.isEdit && (
					<div className='d-flex d-flex-column' onMouseEnter={() => this.setState({isShown: true})} onMouseLeave={() => this.setState({isShown: false})}>
						<div className="label-risk" style={{backgroundColor: this.state.color + '22'}}>
							<h6 className="font-lato-normal-500" style={{color: this.state.color}}>
								{this.state.label}
							</h6>					
						</div>
						{this.state.isShown && !this.state.blocking && (
							<a onClick={() => this.setState({isEdit: true})} className="mx-2" style={{color: '#789D16', cursor: 'pointer', paddingTop: '2px', position: 'absolute', right: '20px'}}>
								<span><i className='fa fa-pencil-alt'></i></span>
							</a>
						)}
						{this.state.blocking && (
							<div className="mx-2" style={{color: '#789D16', paddingTop: '2px', position: 'absolute', right: '20px'}}>
								<i class="fas fa-spinner fa-spin"></i>
							</div>
						)}
					</div>	
				)}
				{this.state.isEdit && (
					<div className='d-flex d-flex-column'>
						<SelectLocalidadRiesgo selected={this.state.id - 1} setFilter={(evt) => {this.setFilter(evt);}}></SelectLocalidadRiesgo>
						<a onClick={() => this.setState({isEdit: false}) } style={{color: '#789D16', cursor: 'pointer', paddingTop: '2px', position: 'absolute', right: '52px'}}>
							<span><i className='fa fa-times'></i></span>
						</a>
						<a onClick={() => this.saveData() } className="mx-2" style={{color: '#789D16', cursor: 'pointer', paddingTop: '2px', position: 'absolute', right: '20px'}}>
							<span><i className='fa fa-save'></i></span>
						</a>
					</div>
				)}
			</>		
		);
	}
}

export default LabelRisk;